import React, {useEffect, useState} from "react";
import {Form, Card, Col, Row, Divider, Button} from 'antd';
import jsonfile from './group.json';
import {CisUI} from '../../../../util/CISUI';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import IntlMessages from "../../../../util/IntlMessages";
import InputFieldV2 from "../../../../util/InputFieldV2";

const EditGroup = (props) => {

    const userID = props.match.params.id;
    const [form] = Form.useForm();
    const history = useHistory();
    let edit_data = []
    const [userData, setUserData] = useState([]);

    const [state, setState] = useState({
        rows:[{
            id: 0,
            title: ""
        }]
    });

    const handleAddRows = () => {
        let items = {
            id: 0,
            title: ""
        };
        setState({
            rows: [...state.rows, items]
        })
    }

    const handleChange = (id) => e => {
        console.log(id, e.target.value)
        const { name, value } = e.target;
        const list = [...state.rows];
        list[id][name] = value;
        console.log(list)
        setState({
            rows: list
        })
    }

    const handleRowDelete = (i) => {

        if(state.rows.length === 1) {
            CisUI().Notification('warning', 'You can not delete this item.')
        }
        else {
            let id = state.rows[i].id
            console.log(id)
            let new_arr = state.rows.filter((item, index) => index !== i)

            setState({
                rows: new_arr
            })
            if(id > 0){
                axios.delete(Config.apiserver+'task/tododelete/'+id, CisUI.HeaderRequest)
                    .then(res => {
                        if (res.data.status === 1) {
                            CisUI().Notification('success', res.data.msg)
                        } else {
                            CisUI().Notification('error', res.data.msg)
                        }
                    })
                    .catch(error => console.log(error))
            }
        }
    }

    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.edittitle;
    let endpoint = jsonfile.urls.edit+"/"+userID;
    let redirectto = "../../"+jsonfile.urls.list;


    const getData = () => {
        axios.get(Config.apiserver + jsonfile.urls.view + "/" + userID, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res.data.data[0])
                setUserData(res.data.data[0])
                let items = res.data.data[1]
                items.forEach((items, index) => {
                    edit_data.push({
                        id: items.id,
                        title: items.title
                    })
                })

                setState({
                    rows: edit_data
                })
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        getData()
    }, [])



    const onFinish = (values) => {
        // console.log(values);
        let data = {
            ...values,
            items: state.rows
        }
        console.log(data)
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg);
                    history.push(redirectto);
                }
                else {
                    CisUI().Notification('error', res.data.msg);
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    return (
        <Col xl={12} lg={12} md={12} sm={12} xs={12} style={{ margin:"0 auto" }}>
        <Card title={<IntlMessages id={Title}/>} extra={CisUI().addAction(redirectto)}>
            {CisUI().showLoading}

                <Form
                    form={form}
                    {...CisUI().formItemLayout}
                    name="input"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                    initialValues={{ remember: true }}
                >
                    {/*{InputFields(jsonfile.input,userData,isEdit,props)}*/}
                    {InputFieldV2(jsonfile.input,userData,isEdit,props, '', redirectto)}
                    <Row gutter={24}>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Divider orientation="left">
                                Items
                            </Divider>
                        </Col>

                        <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{textAlign : "end"}}>
                            <Button onClick={() => handleAddRows()}>
                                Add item
                            </Button>
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <table id="educationinfo" className="ptable table table-bordered">
                                <thead>
                                <tr>
                                    <th>Action</th>
                                    <th>Title</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    state.rows.map((item, idx) => (
                                        <tr id="addr0" key={idx}>
                                            <td style={{width : '30px'}}>
                                                <a onClick={() => handleRowDelete(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>
                                                {/*<Popconfirm title="Sure to delete?" onConfirm={handleRemoveRow(idx)}>*/}
                                                {/*    <a className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>*/}
                                                {/*</Popconfirm>*/}
                                            </td>
                                            <td>
                                                <input type="text" placeholder="Title" className="form-control" name="title" value={item.title} onChange={handleChange(idx)} required={true}/>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                            <Button type="primary" htmlType="submit">
                                <IntlMessages id={"Submit"} />
                            </Button>
                            {/*<Button type="info" onClick={() => history.goBack()} htmlType="back"><IntlMessages id={"Cancel"} /></Button>*/}
                            {/*<Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Cancel"} /></Button>*/}
                            <Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Back"} /></Button>
                        </Col>
                    </Row>
                </Form>


        </Card>
        </Col>
    );
};

export default EditGroup;