/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from "react";
import axios from 'util/Api'
import {CisUI} from "./CISUI";
import Config from "./config";

const SelectData = (endpoint) => {

    const [companyLists, setCompanyLists] = useState([]);

    useEffect(() => {
        getAllAccounts();
    }, []);

    // const token = localStorage.getItem('token');

    const getAllAccounts = () => {
        axios.get(Config.apiserver+endpoint, CisUI().HeaderRequest)
            .then((res) => {
                // console.log(res.data);
                if(res.data.status === 1) {
                    setCompanyLists(res.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    return companyLists;
}

export default SelectData;
