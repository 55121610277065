import React, {useState} from 'react';
import {Button, Col, Form, Modal, Row} from "antd";
import SelectData from "../../../../util/selectdata";
import {CisUI} from "../../../../util/CISUI";
import Config from "../../../../util/config";
import axios from "axios";

const DataImportModal = ({ visible, isShow, title, roomID }) => {
	const [form] = Form.useForm();
	const [state, setState] = useState({
		rows:[{
			source: "",
			url: ""
		}]
	});
	
	
	let booking_source = SelectData('settings/sources');
	
	
	// const handleOk = () => {
	// 	// setState({ loading: true });
	// 	setTimeout(() => {
	// 		setVisible(false);
	// 	}, 1500);
	// };
	
	// const [show, setIsShow] = useState(visible);
	
	const handleCancel = () => {
		// setIsShow(false);
		isShow(false)
		
		setState({
			rows: [{
				source: "",
				url: ""
			}]
		})
	}
	
	const handleAddRows = () => {
		let items = {
			source: "",
			url:""
		};
		setState({
			rows: [...state.rows, items]
		})
	}
	
	const handleChange = (id) => e => {
		console.log(id, e.target.value)
		const { name, value } = e.target;
		const list = [...state.rows];
		list[id][name] = value;
		console.log(list)
		setState({
			rows: list
		})
	}
	
	const handleRowDelete = (i) => {
		
		// console.log(i)
		if(state.rows.length === 1) {
			CisUI().Notification('warning', 'You can not delete this item.')
		}
		else {
			let new_arr = state.rows.filter((item, index) => index !== i)
			
			setState({
				rows: new_arr
			})
		}
	}
	
	const SendICal = (values) => {
		let data = {
			...values,
			items: state.rows,
			room_id: roomID
		}
		
		console.log(data)
		document.getElementById("loader2").style.display = "block";
		axios.post(Config.apiserver+'importical', data, CisUI().HeaderRequest)
			.then(res =>{
				// console.log(res)
				if(res.data.status === 1) {
					CisUI().Notification('success', res.data.msg)
					isShow(false)
					
					setState({
						rows: [{
							source: "",
							url: ""
						}]
					})
				}
				else {
					CisUI().Notification('error', res.data.msg)
				}
				document.getElementById("loader2").style.display = "none";
			})
			.catch(error => console.log(error))
	}
	
	return(
		<>
			<div id="loader2" className="loading_view" style={{display: 'none'}}>
				<div className="loading_center">
					<div className="loader-table">
						<img style={{background : 'transparent'}} src={Config.loader} />
					</div>
					<h4 style={{textAlign: 'center'}}>Please wait...</h4>
				</div>
			</div>
			
			<Modal
				visible={visible}
				centered
				title={<><span style={{ color: "#1e2b5d"}}>Room Name:  {title}</span></>}
				okText="Submit"
				cancelText="Cancel"
				onCancel={handleCancel}
				onOk={() => {
					form
						.validateFields()
						.then((values) => {
							form.resetFields();
							SendICal(values);
						})
						.catch((info) => {
							console.log('Validate Failed:', info);
						});
				}}
				width={1000}
			>
			
				<Form
					form={form}
					layout="vertical"
					name="form_in_modal"
					initialValues={{
						modifier: 'public',
					}}
				>
					<div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", flexDirection: "row" }}>
						<Col lg={12} md={12} sm={24} xs={24}>
							<h4>Import ICAL</h4>
						</Col>
						<Col lg={12} md={12} sm={24} xs={24} style={{textAlign : "end"}}>
							<Button onClick={() => handleAddRows()}>
								Add field
							</Button>
						</Col>
					</div>
					<table id="educationinfo" className="ptable table table-bordered">
						<thead>
						<tr>
							<th>Action</th>
							<th>Source</th>
							<th>URL</th>
						</tr>
						</thead>
						<tbody>
						{
							state.rows.map((item, idx) => (
								<tr id="addr0" key={idx}>
									<td style={{width : '30px'}}>
										<a onClick={() => handleRowDelete(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>
										{/*<Popconfirm title="Sure to delete?" onConfirm={handleRemoveRow(idx)}>*/}
										{/*    <a className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>*/}
										{/*</Popconfirm>*/}
									</td>
									<td>
										<select name="source" id="source" className="form-control"  onChange={handleChange(idx)} value={item.source} required={true}>
											<option value="">Select An Option</option>
											{
												booking_source.map((source, index) =>
													<option value={source.id}>{source.name}</option>
												)
											}
										</select>
									</td>
									<td>
										<input type="text" placeholder="URL" className="form-control" name="url" value={item.url} onChange={handleChange(idx)} required={true}/>
									</td>
								</tr>
							))
						}
						</tbody>
					</table>
				</Form>
			</Modal>
		</>
	);
}

export default DataImportModal;