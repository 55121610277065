import React, { useState, useEffect } from "react";
import { Form, Card, Collapse } from 'antd';
import jsonfile from './automation.json';
import { useHistory } from "react-router-dom";
import { CisUI } from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import ActionButton from '../../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";

const Panel = Collapse.Panel;

const TemplateList = () => {
    const history = useHistory();
    const [state, setState] = useState({
        reqURL: Config.apiserver + jsonfile.urls.list,
        filtervalue: '',
        subtitle: '',
    });

    useEffect(() => {
        setState(
            {
                reqURL: Config.apiserver + jsonfile.urls.list
            }
        );
    }, []);

    const headers = {
        id: {
            text: <IntlMessages id="Sl NO" />,
            sortable: true,
            filterable: true,
        },
        action: {
            text: <IntlMessages id="Action" />,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => CisUI().GetMessageEventAction(value)

        },
        status: {
            text: <IntlMessages id="Status" />,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getStatusLabel(value)
            )
        },
        actions: {
            text: <IntlMessages id="Actions" />,
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {ActionButton(row.id, jsonfile.urls, jsonfile.urls.list)}
                </>
            ),
        },
    };

    // const orderedHeaders = jsonfile.listView.title;

    const [form] = Form.useForm();

    const onFinish = values => {
        // console.log('Received values of form: ', values);
        const qs = Object.keys(values).map(key => `${key}=${values[key]}`).join('&');
        const qsup = qs.replaceAll("undefined", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.list + "/?" + qsup;
        setState({ reqURL: newURL });
    };

    let searchView = "";
    if (jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header={<IntlMessages id="Advance Search" />} key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {CisUI().listAction("../" + jsonfile.urls.add, "", state.reqURL, "data", "customer_type", jsonfile, state.subtitle, "portrait")}
        </>
    );

    return (
        <>
            {searchView}
            <Card title={<IntlMessages id={jsonfile.listtitle} />} extra={getExtra}>
                {/*{CisUI().listAction(jsonfile.urls.add)}*/}
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    // orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
        </>
    );
};

export default TemplateList;
