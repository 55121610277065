import React from 'react'
import {Card, Tabs} from 'antd'
import "./channel.css"
import BookingCalendar from "./BookingCalendar";


const { TabPane } = Tabs;

const ChannelManager = () => {

	function callback(key) {
    console.log(key);
  }

	return (
		<>
			<Card className='mainCard'>
				<Tabs defaultActiveKey="1" onChange={callback}>

					<TabPane className="tabContentArea" tab={<span className="tabTitle">Calendar</span>} key="2">
						<BookingCalendar />
					</TabPane>

				</Tabs>
			</Card>
		</>
	)
}

export default ChannelManager;
