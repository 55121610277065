import React, {useState, useEffect} from "react";
import {Form, Card, Collapse, Popover, Popconfirm, message} from 'antd';
import jsonfile from './booking.json';
import {Link, useHistory} from "react-router-dom";
import {CisUI, getBookingStatus, getPaymentStatus} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
// import ActionButton from '../../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from "axios";

const Panel = Collapse.Panel;

const BookingHistory = () => {
    const history = useHistory();
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.list,
        filtervalue : '',
        subtitle : '',
    });

    useEffect(() => {
        setState(
            {
                reqURL: Config.apiserver+jsonfile.urls.list
            }
        );
    }, []);

    function confirm(id) {
        message.success('Click on Yes');
        let url = Config.apiserver+ jsonfile.urls.delete+`/${id}`;

        axios.delete(url, CisUI().HeaderRequest)
            .then(res=>{

                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg);
                    window.location.reload();
                }
                else {
                    CisUI().Notification('warning', res.data.msg);
                }
            })
            .catch((error)=>{
                console.log(error)
            })
    }

    function cancel(e) {
        console.log(e);
        message.error("Data is not deleted.");
    }

    const content =  (data) => {
        return (
            <div>
                {data.status === 0 &&
                    <>
                        <p>
                            <Link to={`/booking/payment/${data.id}`}>
                                <button className="btn btn-circle">
                                    <i className="fas fa-dollar-sign"/>
                                </button>
                                <IntlMessages id="Pay"/>
                            </Link>
                        </p>

                        {/*<p>*/}
                        {/*    <Link to={jsonfile.urls.edit}>*/}
                        {/*        <button className="btn btn-primary btn-circle">*/}
                        {/*            <i className="fas fa-link"/>*/}
                        {/*        </button> <IntlMessages id={"Edit"}/>*/}
                        {/*    </Link>*/}
                        {/*</p>*/}
                    </>
                }
                <p>
                    <Link to={`/${jsonfile.urls.details}/${data.id}`}>
                        <button className="btn btn-circle">
                            <i className="fas fa-info"/>
                        </button> <IntlMessages id={"Details"}/>
                    </Link>
                </p>
                <p>
                    <Link to="#">
                        <Popconfirm title="Are you sure you want to delete this?" onConfirm={() => confirm(data.id)} onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No">
                            <button className="btn btn-danger btn-circle">
                                <i className="fas fa-trash"/>
                            </button>
                            <IntlMessages id="Delete"/>
                        </Popconfirm>
                    </Link>
                </p>
            </div>
        )
    }

    const text = <span><IntlMessages id="Actions"/></span>;

    const headers = {
        id: {
            text: <IntlMessages id="Sl NO"/>,
            sortable: true,
            filterable: true,
        },
	    property_name: {
            text: <IntlMessages id="Property Name"/>,
            sortable: true,
            filterable: true,
		    transform: (value, idx, row) => (
				<>
			        <Link to={`/${jsonfile.urls.details}/${row.id}`}>{value}</Link>
				</>
			)
        },
	    room_name: {
		    text: <IntlMessages id="Room Name"/>,
		    sortable: true,
		    filterable: true,
	    },
	    customer_name: {
		    text: <IntlMessages id="Customer Name"/>,
		    sortable: true,
		    filterable: true,
	    },
	    check_in_date: {
		    text: <IntlMessages id="Check In"/>,
		    sortable: true,
		    filterable: true,
	    },
	    check_out_date: {
		    text: <IntlMessages id="Check Out"/>,
		    sortable: true,
		    filterable: true,
	    },
	    nights: {
		    text: <IntlMessages id="Total Nights"/>,
		    sortable: true,
		    filterable: true,
	    },
	    total_discount: {
		    text: <IntlMessages id="Total Discount"/>,
		    sortable: true,
		    filterable: true,
	    },
	    discount_amount: {
		    text: <IntlMessages id="Discount Amount"/>,
		    sortable: true,
		    filterable: true,
	    },
	    total: {
		    text: <IntlMessages id="Total"/>,
		    sortable: true,
		    filterable: true,
	    },
	    grand_total: {
		    text: <IntlMessages id="Grand Total"/>,
		    sortable: true,
		    filterable: true,
	    },
        advance: {
            text: <IntlMessages id="Advance"/>,
            sortable: true,
            filterable: true,
        },
        due: {
            text: <IntlMessages id="Due"/>,
            sortable: true,
            filterable: true,
        },
        payment_status: {
            text: <IntlMessages id="Payment Status"/>,
            sortable: true,
            filterable: true,
            // transform: (value, idx, row) => (getPaymentStatus(value))
        },
        // status: {
        //     text: <IntlMessages id="Status"/>,
        //     sortable: true,
        //     filterable: true,
            // transform: (value, idx, row) => (
            //     getBookingStatus(value)
            // )
        // },
        actions: {
            text: <IntlMessages id="Actions"/>,
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    <Popover placement="leftBottom" title={text} content={content(row)} trigger="click">
                        <button className="btn btn-primary btn-circle"><i className="fas fa-ellipsis-h"></i></button>
                    </Popover>
                </>
            ),
        },
    };

    const orderedHeaders = jsonfile.listView.title;

    const [form] = Form.useForm();

    const onFinish = values => {
        // console.log('Received values of form: ', values);
        const qs = Object.keys(values).map(key => `${key}=${values[key]}`).join('&');
        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.list+"/?"+qsup;
        setState({reqURL: newURL});
    };

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header={<IntlMessages id="Advance Search"/>}  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listAction("../"+jsonfile.urls.add,"", state.reqURL,"data","customer_type",jsonfile,state.subtitle,"portrait")}
        </>
    );

    return (
        <>
            {searchView}
            <Card title={<IntlMessages id={jsonfile.listtitle} />} extra={getExtra}>
                {/*{CisUI().listAction(jsonfile.urls.add)}*/}
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
        </>
    );
};

export default BookingHistory;
