import React, {useState, useEffect} from "react";
import { Form, Input, Card, Collapse} from 'antd';
import jsonfile from './user.json'
import Config from '../../../../util/config'
import {useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI'
import AdvancedSearch from '../../../../util/AdvancedSearch'
import ActionButton from '../../../../util/actionbutton'
import SmartDataTable from 'react-smart-data-table'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import IntlMessages from "util/IntlMessages";

const Panel = Collapse.Panel;

const UserList = () => {
    const value = 0;
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.list,
        filtervalue : '',
        subtitle: ''
    });

    useEffect(() => {
        setState(
            {
                reqURL: Config.apiserver+jsonfile.urls.list
            }
        );
    }, []);

    // for (var a in localStorage) {
    //     console.log(a, ' = ', localStorage[a]);
    // }


    const history = useHistory();
    const headers = {
        // _id: {
        //     text: '#',
        //     invisible: false,
        //     filterable: false,
        //     transform: (value) => `${value++}`,
        // },
        id: {
            text: <IntlMessages id="ID"/>,
            sortable: true,
            filterable: true,
            invisible:true
        },
        photo: {
            text: <IntlMessages id="Photo"/>,
            sortable: false,
            filterable: false,
            invisible: true
        },
        role_name: {
            text: <IntlMessages id="Role"/>,
            sortable: true,
            filterable: true,
        },
        name: {
            text: <IntlMessages id="Full Name"/>,
            sortable: true,
            filterable: true,
        },
        phone: {
            text: <IntlMessages id="Phone"/>,
            sortable: true,
            filterable: true,
        },
        email: {
            text: <IntlMessages id="Email"/>,
            sortable: true,
            filterable: true,
        },
        username: {
            text: <IntlMessages id="Username"/>,
            sortable: true,
            filterable: true,
        },
        city: {
            text: <IntlMessages id="City"/>,
            sortable: true,
            filterable: true,
            // invisible: true
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
               CisUI().getStatusLabel(value)
            )
        },
        actions: {
            text:  <IntlMessages id="Actions"/>,
            sortable: false,
            filterable: false,
            textAlign: "center",
            transform: (value, idx, row) => (
                <>
                    {ActionButton(row.id,jsonfile.urls)}
                </>
            ),
        },
    };

    const orderedHeaders = jsonfile.listView.title

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);
        //let res = RemoteJson("userlists");
        const qs = Object.keys(values).map(key => `${key}=${values[key]}`).join('&');
        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.list+"/?"+qsup;
        setState({reqURL: newURL});
    };


    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header={<IntlMessages id="Advance Search"/>}  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listAction("../"+jsonfile.urls.add, "", state.reqURL, "data", 'user_list', jsonfile, state.subtitle, "landscape")}
        </>
    );

    const getData = () => {
       // return RemoteJson(Config.apiserver+"userlist");
    }

    const token = localStorage.getItem('token');

        return (
            <>
                {searchView}
            <Card title={<IntlMessages id={jsonfile.listtitle}/>} extra={getExtra} >
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filtervalue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    dynamic
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
            </>
        );
};

export default UserList;
