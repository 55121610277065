import React, {useEffect, useState} from "react";
import {Form, Card, Row, Col, Button} from 'antd';
import jsonfile from './page.json'
import {CisUI} from '../../../../util/CISUI'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import IntlMessages from "../../../../util/IntlMessages";
import InputFieldV2 from "../../../../util/InputFieldV2";
import CKEditor from "react-ckeditor-component";

const NewPage = (props) => {
    const [ck, setCK] = useState("");
    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;
    let details = '';

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        details = userData['body'];
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }

    useEffect(() => {
        setCK(details)
    }, [details])


    const [form] = Form.useForm();
    const history = useHistory();

    const handleChange = (evt) => {
        // console.log(evt.editor);
        var newContent = evt.editor.getData();
        setCK(newContent)
    }

    const onFinish = (values) => {
        let data = {
            ...values,
            details: ck
        }
        console.log(data);
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, data)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg);
                    history.push(redirectto);
                }
                else {
                    CisUI().Notification('error', res.data.msg);
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    return (
        <Card title={<IntlMessages id={Title}/>} extra={
                isEdit === 1
                    ? CisUI().addAction(redirectto)
                    : CisUI().addAction(redirectto)
            }>
            {CisUI().showLoading}
            <Form
                // form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                {/*{InputFields(jsonfile.input,userData,isEdit,props)}*/}
                {InputFieldV2(jsonfile.input,userData,isEdit,props, '', redirectto)}
                <Row gutter={24}>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name="details"
                                label="Details"
                            >
                                <CKEditor
                                    activeClass="p10"
                                    content={ck}
                                    events={{
                                        "change": handleChange
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            <IntlMessages id={"Submit"} />
                        </Button>
                        {/*<Button type="info" onClick={() => history.goBack()} htmlType="back"><IntlMessages id={"Cancel"} /></Button>*/}
                        {/*<Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Cancel"} /></Button>*/}
                        <Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Back"} /></Button>
                    </Col>
                </Row>

            </Form>
        </Card>
    );
};

export default NewPage;
