import React, {useState, useEffect} from "react";
import {Form, Col, Input, Card, Collapse, Button, Modal, Table, Popover, Popconfirm, Row} from 'antd';
import jsonfile from './room_info.json';
import {Link, useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import ActionButton from '../../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from "axios";

const Panel = Collapse.Panel;

const RoomInfoList = () => {
    const history = useHistory();
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.list,
        filtervalue : '',
        subtitle : '',
    });

    useEffect(() => {
        setState(
            {
                reqURL: Config.apiserver+jsonfile.urls.list
            }
        );
    }, []);

    const [visible, setVisible] = useState(false);

    const[icalData, setIcalData] = useState([]);


    const showModal = (id) => {
        console.log(id)

        axios.get(Config.apiserver+ 'icallist/'+id, CisUI().HeaderRequest)
            .then(res => {
                console.log(res.data.data);
                if(res.data.status == 1) {
                    setIcalData(res.data.data);
                    setVisible(true)
                }
            })
            .catch(error => console.log(error))
    }

    // useEffect(() => {
    //     showModal()
    // }, [])

    const handleCancel = () => {
        setVisible(false );
    };

    const handleOk = () => {
        // setState({ loading: true });
        setTimeout(() => {
            setVisible(false);
        }, 1500);
    };

    const headers = {
        id: {
            text: <IntlMessages id="Sl NO"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) =>
                <>
                    <Link to={`/${jsonfile.urls.view}/${row.id}`}>
                        {value}
                    </Link>
                </>
        },
        property_name: {
            text: <IntlMessages id="Property Name"/>,
            sortable: true,
            filterable: true,
        },
        type_name: {
            text: <IntlMessages id="Room Type"/>,
            sortable: true,
            filterable: true,
        },
        name: {
            text: <IntlMessages id="Room Name/No."/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) =>
            <>
                <Link to={`/${jsonfile.urls.edit}/${row.id}`}>
                    {value}
                </Link>
            </>
        },
        display_priority: {
            text: <IntlMessages id="Display Priority"/>,
            sortable: true,
            filterable: true
        },
        owner_price: {
            text: <IntlMessages id="Owner Price"/>,
            sortable: true,
            filterable: true,
            transform: (value, id, row) =>
                <>
                    {CisUI().getCurrencyFormated(value)}
                </>
        },
        customer_price: {
            text: <IntlMessages id="Customer Price"/>,
            sortable: true,
            filterable: true,
            transform: (value, id, row) =>
                <>
                    {CisUI().getCurrencyFormated(value)}
                </>
        },
        discount: {
            text: <IntlMessages id="Discount"/>,
            sortable: true,
            filterable: true,
            transform: (value, id, row) =>
                <>
                    {CisUI().getCurrencyFormated(value)}
                </>
        },
        ical: {
            text: <IntlMessages id="ICAL"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) =>
                <>
                    <i className="icon icon-calendar-new" onClick={() => showModal(row.id)}/>
                </>
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getStatusLabel(value)
            )
        },
        actions: {
            text: <IntlMessages id="Actions"/>,
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {ActionButton(row.id,jsonfile.urls,jsonfile.urls.list)}
                </>
            ),
        },
    };

    const orderedHeaders = jsonfile.listView.title;

    const [form] = Form.useForm();

    const onFinish = values => {
        // console.log('Received values of form: ', values);
        const qs = Object.keys(values).map(key => `${key}=${values[key]}`).join('&');
        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.list+"/?"+qsup;
        setState({reqURL: newURL});
    };

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header={<IntlMessages id="Advance Search"/>}  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {CisUI().listAction("../"+jsonfile.urls.add,"", state.reqURL,"data","customer_type",jsonfile,state.subtitle,"portrait")}
        </>
    );

    const content = (
        <div>
            <p>
                <Link to="">
                    <button className="btn btn-primary btn-circle">
                        <i className="fas fa-pencil-alt"/>
                    </button> <IntlMessages id="Edit" />
                </Link>
            </p>
            <p>
                <Link to="#">
                    <Popconfirm title="Are you sure you want to delete this?" onConfirm="" onCancel="" okText="Yes"
                                cancelText="No">
                        <button className="btn btn-danger btn-circle">
                            <i className="fas fa-trash"/>
                        </button> <IntlMessages id="Delete" />
                    </Popconfirm>
                </Link>
            </p>
        </div>
    );

    const dataSource = [];

    let text = <span><IntlMessages id="Actions"/></span>;
    icalData.map((data, index) =>
        dataSource.push({
            key: ++index,
            property_name: data.property_name,
            room_name: data.room_name,
            upload_at: data.upload_at,
            action: <Popover placement="leftBottom" title={text} content={content} trigger="click">
                        <button className="btn btn-primary btn-circle"><i className="fas fa-ellipsis-h"></i></button>
                    </Popover>
        })
    )

    const columns = [
        {
            title: 'Property Name',
            dataIndex: 'property_name',
            key: 'property_name',
        },
        {
            title: 'Room Name',
            dataIndex: 'room_name',
            key: 'room_name',
        },
        {
            title: 'Upload At',
            dataIndex: 'upload_at',
            key: 'upload_at',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
        }
    ];


    return (
        <>
            {searchView}
            <Card title={<IntlMessages id={jsonfile.listtitle} />} extra={getExtra}>
                {/*{CisUI().listAction(jsonfile.urls.add)}*/}
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />

                <Modal
                    visible={visible}
                    title="ICal List"
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={1000}
                    // footer={[
                    //     <Button key="back" onClick={handleCancel}>
                    //         Cancel
                    //     </Button>,
                    //     <Button key="submit" type="primary" onClick={handleOk}>
                    //         Submit
                    //     </Button>,
                    // ]}
                >
                    <Row>
                        <p>Import Lists</p>
                        <Link to="" className="btn btn-text-adjust btn-circle-md" title="Add New">
                            <i className="fas fa-plus"/>
                        </Link>
                    </Row>
                    <Table dataSource={dataSource} columns={columns} />
                </Modal>
            </Card>
        </>
    );
};

export default RoomInfoList;
