import React, {useEffect, useState} from "react";
import {Form, Card, Col, Row, Button, Select, Input, DatePicker, Divider, Checkbox, Radio, Image, Tabs} from 'antd';
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import {useHistory} from "react-router-dom";
import IntlMessages from "../../../../util/IntlMessages";

const {RangePicker} = DatePicker;
const {TextArea} = Input;
const {Option} = Select;

const IncompleteToDos = (props) => {

    let data = props.data
    console.log(data)

    return (
        <>
            <h2>Incomplete ToDos</h2>


                    <Row>
                        {/*<Col lg={12} md={12} sm={24} xs={24}>*/}
                            {/*<h3>Completed ToDos</h3>*/}
                            {
                                data.length > 0 ?

                                    data.map((item, index) =>

                                        <Col lg={12} md={12} sm={24} xs={24}>
                                            <Card>
                                            <Divider orientation={"left"} plain style={{
                                                fontSize: "16px",
                                                color: "#1E2B5D",
                                                margin: "10px 0"
                                            }}>{item.group_name}</Divider>
                                            {
                                                item.assign_todos.map((todo, i) =>

                                                        <p style={{width: "100%"}}>
                                                            {/*<Checkbox*/}
                                                            {/*    // checked={item.status === 1 ? true : false }*/}
                                                            {/*    // onChange={(e) => handleToDo(e, todo.id)}*/}
                                                            {/*    checked={todo.status === 0 ? false : true }*/}
                                                            {/*    onChange={(e) => handleToDoStatus(e, todo.id)}*/}
                                                            {/*    className="custom_checkbox"*/}
                                                            {/*    disabled={true}*/}
                                                            {/*>*/}
                                                            <span style={{color: "red"}}><i
                                                                className="icon icon-circle-o"
                                                                style={{padding: "0 4px 4px 0"}}/>{todo.title}</span>
                                                            {/*</Checkbox>*/}
                                                        </p>
                                                )
                                            }
                                            </Card>
                                        </Col>
                                    )
                                    :

                                    <Col lg={24} md={24} sm={24} xs={24}>
                                    <p>No Data Found.</p>
                                    </Col>
                            }
                        {/*</Col>*/}
                    </Row>

        </>
    );
};

export default IncompleteToDos;