import React, {useEffect, useState} from "react";
import {Form, Card, Col, Row, Button, Select, InputNumber, Input, DatePicker, Popconfirm, message} from 'antd';
import jsonfile from './room_price.json'
import {CisUI} from '../../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../../util/config";
import axios from "../../../../../util/Api";
import {useHistory} from "react-router-dom";
import IntlMessages from "../../../../../util/IntlMessages";


const { Option } = Select;

const NewPrice = (props) => {
    const [form] = Form.useForm();
    const [seasonalPrice, setSeasonalPrice] = useState([
        {
            season_name: '',
            from: undefined,
            to: undefined,
            price: 0.00
        }
    ])

    let months = [
        {"id": 1, "name": "January"},
        {"id": 2, "name": "February"},
        {"id": 3, "name": "March"},
        {"id": 4, "name": "April"},
        {"id": 5, "name": "May"},
        {"id": 6, "name": "June"},
        {"id": 7, "name": "July"},
        {"id": 8, "name": "August"},
        {"id": 9, "name": "September"},
        {"id": 10, "name": "October"},
        {"id": 11, "name": "November"},
        {"id": 12, "name": "December"}
    ]

    const handleAddRows = () => {
        let items = {
            season_name: '',
            from: undefined,
            to: undefined,
            price: 0.00
        };
        setSeasonalPrice([...seasonalPrice, items])
    }

   const handleChange = (e, id, field_name) => {
       console.log(e, id, field_name);
       const list = [...seasonalPrice];
       if(field_name === 'name') {
           const {name, value} = e.target;
           list[id][name] = value;
       }
       else{
           list[id][field_name] =e
       }
       setSeasonalPrice(list)
   }

   const handleRemoveRow = (id) => {
       if (seasonalPrice.length === 1) {
           CisUI().Notification("warning", "You can not delete this item");
       } else {
           let newArr = seasonalPrice.filter((item, index) => index !== id);
           let sub_total = 0.0;
           newArr.map((item, index) => (sub_total += item.amount));

           setSeasonalPrice(newArr);
       }
   }

    const userID = props.rmID;
    console.log(userID)

    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add+"/"+userID;
    let redirectto = "../property/roominfos";

    // console.log(userData);

    const history = useHistory();

    const onFinish = (values) => {
        let price_items = []
        seasonalPrice.map((item, index) => {
            price_items.push({
                season_name: item.season_name,
                from: item.from.format('YYYY-MM-DD'),
                to: item.to.format('YYYY-MM-DD'),
                price: item.price
            });
        })

        let data = {
            ...values,
            items: price_items,
        }
        // console.log(endpoint, data);
        if(userID > 0) {
            document.getElementById("loader").style.display = "block";
            axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
                .then(res => {
                    // console.log(res)
                    if (res.data.status === 1) {
                        CisUI().Notification('success', res.data.msg);
                    } else {
                        CisUI().Notification('error', res.data.msg);
                    }
                    document.getElementById("loader").style.display = "none";
                })
                .catch(errors => {
                    console.log(errors);
                });
        }
        else{
            CisUI().Notification('error', 'Please entry general info first.');
        }
    };

    return (
        <Row gutter={24}>
            <Col lg={4} md={4} sm={4} xs={4}></Col>
            <Col lg={16} md={24} sm={24} xs={24}>
                <Card title={<IntlMessages id={Title}/>}>
                    {CisUI().showLoading}
                    <Form
                        form={form}
                        {...CisUI().formItemLayout}
                        name="input"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                        initialValues={{ remember: true }}
                    >
                        <Row>

                            {/*<Col lg={12} md={12} sm={12} xs={12}>*/}
                            {/*    <Form.Item*/}
                            {/*        name="customer_price"*/}
                            {/*        label="Customer Price"*/}
                            {/*        rules={[{*/}
                            {/*            required: true,*/}
                            {/*            message: "Customer price is required"*/}
                            {/*        }]}*/}
                            {/*    >*/}
                            {/*        <Input className="gx-w-100" placeholder="Customer Price"/>*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}
                            <Col lg={24} md={24} sm={24} xs={24} style={{textAlign : "right"}}>
                                <Button onClick={() => handleAddRows()}>
                                    Add More
                                </Button>
                            </Col>
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <table id="educationinfo" className="ptable table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th>Season Name</th>
                                            <th>From</th>
                                            <th>To</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        seasonalPrice.map((item, idx) => (
                                            <tr id="addr0" key={idx}>
                                                <td style={{width : '30px'}}>
                                                    {/*<a onClick={() => handleRowDelete(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>*/}
                                                    <Popconfirm title="Sure to delete?" onConfirm={() => handleRemoveRow(idx)}>
                                                        <a className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>
                                                    </Popconfirm>
                                                </td>
                                                <td style={{ width: "25%"}}>
                                                    <Input
                                                        placeholder="Season Name"
                                                        className="gx-w-100"
                                                        value={item.name}
                                                        onChange={(e) => handleChange(e, idx, "season_name")} />
                                                </td>
                                                <td style={{ width: "25%"}}>
                                                    {/*<DatePicker*/}
                                                    {/*    placeholder="Ex: YYYY-MM-DD"*/}
                                                    {/*    className="gx-w-100"*/}
                                                    {/*    value={item.from}*/}
                                                    {/*    onChange={(e) => handleChange(e, idx, "from")}*/}
                                                    {/*    required={true}*/}
                                                    {/*/>*/}
                                                    <Select
                                                        // className="gx-w-100"
                                                        showSearch
                                                        placeholder="Select an item"
                                                        style={{width: "100%"}}
                                                        filterOption={(input, option) =>
                                                            option.children
                                                                .toString()
                                                                .toLowerCase()
                                                                .indexOf(input.toString().toLowerCase()) >= 0
                                                        }
                                                        allowClear={true}
                                                        onChange={(e) => handleChange(e, idx, "from")}
                                                        value={item.from}
                                                        loading={months?.length > 0 ? false : true}
                                                    >
                                                        {
                                                            months?.map((item, index) => (
                                                                <Option key={index} value={item?.id}>
                                                                    {item?.name}
                                                                </Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </td>
                                                <td style={{ width: "25%"}}>
                                                {/*    <DatePicker*/}
                                                {/*        placeholder="Ex: YYYY-MM-DD"*/}
                                                {/*        className="gx-w-100"*/}
                                                {/*        value={item.to}*/}
                                                {/*        onChange={(e) => handleChange(e, idx, "to")}*/}
                                                {/*        required={true}*/}
                                                {/*    />*/}
                                                {/*</td>*/}
                                                <Select
                                                    // className="gx-w-100"
                                                    showSearch
                                                    placeholder="Select an item"
                                                    style={{width: "100%"}}
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toString()
                                                            .toLowerCase()
                                                            .indexOf(input.toString().toLowerCase()) >= 0
                                                    }
                                                    allowClear={true}
                                                    onChange={(e) => handleChange(e, idx, "to")}
                                                    value={item.to}
                                                    loading={months?.length > 0 ? false : true}
                                                >
                                                    {
                                                        months?.map((item, index) => (
                                                            <Option key={index} value={item?.id}>
                                                                {item?.name}
                                                            </Option>
                                                        ))
                                                    }
                                                </Select>
                                                </td>
                                                <td style={{ width: "25%"}}>
                                                    <InputNumber
                                                        className="gx-w-100 tranx_input_number"
                                                        value={item.price}
                                                        placeholder="Price"
                                                        onChange={(e) => handleChange(e, idx, "price")}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </Col>

                        </Row>


                        <Row gutter={24}>
                            <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                                <Button type="primary" htmlType="submit">
                                    <IntlMessages id={"Submit"} />
                                </Button>
                                <Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Back"} /></Button>
                            </Col>
                        </Row>

                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

export default NewPrice;
