import React, { useEffect, useState } from 'react'
import {Button, Card, Col, DatePicker, Divider, Form, Input, Modal, Row, Table} from 'antd'
import axios from 'axios'
import {Link, useHistory, useParams} from 'react-router-dom';
import { CisUI } from '../../../../util/CISUI';
import Config from '../../../../util/config'
import jsonfile from "./booking_request.json";
import IntlMessages from "../../../../util/IntlMessages";
import moment from "moment";
import CircularProgress from "../../../../components/CircularProgress";

const BookingReqDetails = () => {
    const [details, setDetails] = useState([])
    const [bookingDetail, setBookingDetail] = useState({
        check_in: "",
        check_out: "",
        room_name: "",
        price: 0.00,
        nights: 0,
        total: 0.00,
        discount: 0.00,
        discount_amount: 0.00,
        after_discount_total: 0.00,
        cleaning_fee: 0.00,
        pet_fee: 0.00,
        pets: 0,
        // grand_total: 0.00
        advance: 0.00
    });
    const [grandTotal, setGrandTotal] = useState(0.00);

    const [isHide, setIsHide] = useState(false);
    let redirectto = "../../"+jsonfile.urls.list;
    const [form] = Form.useForm();
    const[payableAmount, setPayableAmount] = useState(0.00);
    const[disAmnt, setDisAmnt] = useState(0.00);
    const[loader, setLoader] = useState(false);
    const history = useHistory();

    const { id } = useParams();

    console.log(id);

    const bookingReqDetails = (() => {
        setLoader(true)
        axios.get(Config.apiserver + `booking/bookingreqdetails/${id}`, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    setDetails(res.data.booking_info)
                    setBookingDetail({
                        check_in: res.data.booking_info.check_in,
                        check_out: res.data.booking_info.check_out,
                        room_name: res.data.booking_info.room_name,
                        price: res.data.booking_info.price,
                        nights: res.data.booking_info.nights,
                        total: res.data.booking_info.total,
                        discount: res.data.booking_info.discount_percentage,
                        discount_amount: res.data.booking_info.discount_amount,
                        after_discount_total: res.data.booking_info.after_discount_total,
                        cleaning_fee: res.data.booking_info.cleaning_fee,
                        pet_fee: res.data.booking_info.pet_fee,
                        pets: res.data.booking_info.pets !== null ? res.data.booking_info.pets : 0,
                        grand_total: res.data.booking_info.grand_total,
                        advance: res.data.booking_info.advance
                    })

                    form.setFieldsValue({
                        check_in: moment(res.data.booking_info.check_in),
                        check_out: moment(res.data.booking_info.check_out),
                        after_discount_total: res.data.booking_info.after_discount_total,
                        advance_payment: res.data.booking_info.advance
                    })
                    // setGrandTotal(res.data.booking_info.after_discount_total + res.data.booking_info.cleaning_fee + (res.data.booking_info.pet_fee * ))
                    setPayableAmount(res.data.booking_info.grand_total)

                    form.setFieldsValue({
                        price: parseFloat(res.data.booking_info.price).toFixed(2),
                        discount: parseFloat(res.data.booking_info.discount).toFixed(2)
                    })
                }
                else {
                    CisUI().Notification('warning', res.data.msg)
                }
                setLoader(false)
            })
            .catch(error => {
                setLoader(false)
                console.log(error);
            })
    })

    useEffect(() => {
        setGrandTotal(bookingDetail.after_discount_total + bookingDetail.cleaning_fee + (bookingDetail.pet_fee * bookingDetail.pets))
    }, [bookingDetail])

    const reqAccept = (id) => {
        let data = {
            check_in: form.getFieldValue('check_in').format("YYYY-MM-DD"),
            check_out: form.getFieldValue('check_out').format("YYYY-MM-DD"),
            whole_discount: parseFloat(form.getFieldValue('whole_discount')),
            after_discount_total: parseFloat(form.getFieldValue('after_discount_total')),
            dis_amnt: disAmnt,
            payable_amount: payableAmount,
            advance: form.getFieldValue('advance')
        }

        // console.log(data);
        setLoader(true)
        axios.post(Config.apiserver + jsonfile.urls.accept + "/" + id, data,CisUI().HeaderRequest)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg)
                    history.push(redirectto)
                }
                else {
                    CisUI().Notification('warning', res.data.msg)
                }
                setLoader(false)
            })
            .catch(error => {
                setLoader(false)
                console.log(error);
            })
    }

    useEffect(() => {
        bookingReqDetails()
    }, [isHide])

    // useEffect(() => {
    //     reqAccept()
    // }, []);

    const reqDeclined = (id) => {
        setIsHide(true);
    }
    const reqBack = () => {
        history.push("/booking/bookingrequest");
    }

    const calculateDiscountTotal = () => {
        console.log(bookingDetail)
        let afterDiscountTotal = +form.getFieldValue('after_discount_total');
        console.log(afterDiscountTotal);
        let cleaning_fee = bookingDetail.cleaning_fee;
        console.log(cleaning_fee);
        let pet_fee = bookingDetail.pet_fee;
        let discount = form.getFieldValue('whole_discount');

        let total = afterDiscountTotal + cleaning_fee + pet_fee - discount

        setGrandTotal( afterDiscountTotal + cleaning_fee + pet_fee)
        setPayableAmount(total);
    }


    const calculatePrice = () => {
        let grand_total = +grandTotal;
        let discount = form.getFieldValue('whole_discount');
        console.log(+discount)
        // let discount_amount = (grand_total * discount) / 100;
        let total_amount = grand_total - discount
        console.log(total_amount)
        // setDisAmnt(discount_amount)
        setPayableAmount(total_amount)
    }

    const onFinish = () => {
        // console.log(values);
        form
            .validateFields()
            .then(values => {
                axios.post(Config.apiserver + jsonfile.urls.declined + "/" + details.id, values, CisUI().HeaderRequest)
                    .then(res => {
                        console.log(res.data)
                        if(res.data.status === 1) {
                            CisUI().Notification('success', res.data.msg)
                            form.resetFields();
                            setIsHide(false);
                        }
                        else {
                            CisUI().Notification('warning', res.data.msg)
                        }
                    })
                    .catch(error => console.log(error.response.data))
            })
    }

    // let grand_total = bookingDetail.after_discount_total + bookingDetail.cleaning_fee + (bookingDetail.pet_fee * bookingDetail.pets)


    return (
        loader ? <CircularProgress /> :
        <Card title={<IntlMessages id={jsonfile.details} />} extra={CisUI().addAction(redirectto)}>
            <Row>
                <Col span={12}>
                    <Card title="Customer Info" style={{ width: "100%" }}>
                        <div className="gx-media gx-featured-item">
                            <div className="gx-featured-thumb">
                                <img className="gx-rounded-lg gx-width-175" src={details.customer_image != '' ? Config.customer_image+details.customer_image : Config.image_url+'/upload/no_image.png'} alt="..." style={{height:"130px"}}/>
                                {/*<Tag className="gx-rounded-xs gx-bg-orange gx-border-orange gx-text-white">Featured</Tag>*/}
                            </div>
                            <div className="gx-media-body gx-featured-content">
                                <div className="gx-featured-content-left">

                                    {/*<Tag className="gx-rounded-xs" color="#06BB8A">FOR SALE</Tag>*/}
                                    <h3 className="gx-mb-2">Customer Name: {details.customer_name}</h3>

                                    <p className="gx-text-grey gx-mb-1">{details.customer_email}</p>

                                    <div className="ant-row-flex gx-mt-4">
                                        <p className="gx-mr-3 gx-mb-1"><span className="gx-text-grey">Phone:</span> { details.customer_phone }</p>
                                        <p className="gx-mr-3 gx-mb-1"><span className="gx-text-grey">State:</span> { details.customer_state }</p>
                                        <p className="gx-mr-3 gx-mb-1"><span className="gx-text-grey">City:</span> { details.customer_city }</p>
                                    </div>
                                </div>
                                <div className="gx-featured-content-right">
                                    <Link to={`/crm/customeredit/${details.customer_id}`} className="gx-text-primary gx-text-truncate gx-mt-auto gx-mb-0 gx-pointer">Details <i
                                        className={`icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle`}/></Link>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Col>

                <Col span={12}>
                    <Card title="Property Details" style={{ width: "100%" }}>

                        <div className="gx-media gx-featured-item">
                            <div className="gx-featured-thumb">
                                <img className="gx-rounded-lg gx-width-175" src={details.property_image != null ? Config.property_info_image+details.property_image : Config.image_url+'/upload/no_image.png'} alt="..." style={{height:"130px"}}/>
                            </div>
                            <div className="gx-media-body gx-featured-content">
                                <div className="gx-featured-content-left">

                                    <h3 className="gx-mb-2">Property Name: {details.property_name}</h3>

                                    <p className="gx-text-grey gx-mb-1">{details.property_email}</p>

                                    <div className="ant-row-flex gx-mt-4">
                                        <p className="gx-mr-3 gx-mb-1"><span className="gx-text-grey">Phone:</span> { details.property_phone }</p>
                                        <p className="gx-mr-3 gx-mb-1"><span className="gx-text-grey">Website:</span> <a href={ details.property_website } target="_blank">Link</a></p>
                                        <p className="gx-mr-3 gx-mb-1"><span className="gx-text-grey">City:</span> { details.property_city }</p>
                                        <p className="gx-mr-3 gx-mb-1"><span className="gx-text-grey">Area:</span> { details.property_area  }</p>
                                    </div>
                                </div>
                                <div className="gx-featured-content-right">
                                    <Link to={`/property/editpropertyinfo/${details.property_id}`} className="gx-text-primary gx-text-truncate gx-mt-auto gx-mb-0 gx-pointer">Details <i
                                        className={`icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle`}/></Link>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Col>

                <Col span={24}>
                    <Card title="Booking Request Information" style={{ width: "100%" }}>

                        {/*<p style={{ fontSize:"16px"}}>Booking Date: <span style={{ color:"#1e2b5d" }}>{details.check_in} to {details.check_out}</span></p>*/}

                        <div className="gx-flex-row gx-mb-4">
                            <p style={{ fontSize:"16px"}} className="gx-mr-3 gx-mb-1">Nights: <span style={{ color:"#1e2b5d" }}>{details.nights}</span></p>
                            <p style={{ fontSize:"16px"}} className="gx-mr-3 gx-mb-1">No. of pets: <span style={{ color:"#1e2b5d" }}>{details.pets ? details.pets : 0}</span></p>
                            <p style={{ fontSize:"16px"}} className="gx-mr-3 gx-mb-1">Total Guests: <span style={{ color:"#1e2b5d" }}>{details.guest}</span></p>
                        </div>

                        <Form
                            form={form}
                            {...CisUI().formItemLayout}
                            name="input"
                            onFinish={onFinish}
                        >
                        <div className="table-responsive">
                            <table className="table req_table">
                                <thead>
                                    <tr>
                                        <th style={{ width:"40%" }}>Room Name</th>
                                        <th style={{ width:"8%" }}>Check In</th>
                                        <th style={{ width:"8%" }}>Check Out</th>
                                        <th style={{ width:"5%" }}>Price</th>
                                        <th style={{ width:"5%" }}>Nights</th>
                                        <th style={{ width:"6%" }}>Total</th>
                                        <th style={{ width:"5%" }}>Discount (%)</th>
                                        <th style={{ width:"5%" }}>Discount Amount</th>
                                        <th style={{ width:"7%", textAlign: "right" }}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width:"40%" }}>{bookingDetail.room_name}</td>
                                        <td style={{ width:"8%" }}>
                                            {
                                                details.status > 0 ? details.check_in :

                                                    <div className="gx-form-row0" style={{width: "90%"}}>
                                                        <Form.Item
                                                            name="check_in"
                                                            // initialValue={moment(details.check_in)}
                                                        >
                                                            <DatePicker />
                                                        </Form.Item>
                                                    </div>
                                            }
                                        </td>
                                        <td style={{ width:"8%" }}>
                                            {
                                                details.status > 0 ? details.check_out :

                                                    <div className="gx-form-row0" style={{width: "90%"}}>
                                                        <Form.Item
                                                            name="check_out"
                                                            // initialValue={moment(details.check_out)}
                                                        >
                                                            <DatePicker/>
                                                        </Form.Item>
                                                    </div>
                                            }
                                        </td>
                                        <td style={{ width:"5%" }}>{CisUI().getCurrencyFormated(bookingDetail.price)}</td>
                                        <td style={{ width:"5%", textAlign: "center" }}>{bookingDetail.nights}</td>
                                        <td style={{ width:"6%" }}>{CisUI().getCurrencyFormated(bookingDetail.total)}</td>
                                        <td style={{ width:"5%" }}>{CisUI().getCurrencyFormated(bookingDetail.discount)}</td>
                                        <td style={{ width:"5%" }}>{CisUI().getCurrencyFormated(bookingDetail.discount_amount)}</td>
                                        <td style={{ width:"7%",  textAlign: "right" }}>
                                            <div className="gx-form-row0 gx-ml-4" style={{width:"78%"}}>
                                                <Form.Item
                                                    name="after_discount_total"
                                                    // initialValue={bookingDetail.after_discount_total}
                                                >
                                                    <Input style={{ textAlign:"right" }} onChange={calculateDiscountTotal}/>
                                                </Form.Item>
                                            </div>
                                            {/*<span style={{ color:"#1e2b5d", fontWeight: "bold"}}>*/}
                                            {/*    {CisUI().getCurrencyFormated3(bookingDetail.after_discount_total)}*/}
                                            {/*</span>*/}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width:"5%", textAlign:"right" }} colSpan={8}>Cleaning Fee (+)</td>
                                        <td style={{ width:"7%" , textAlign: "right" }}><span style={{ color:"#1e2b5d", fontWeight: "bold"}}>{CisUI().getCurrencyFormated3(bookingDetail.cleaning_fee)}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width:"5%", textAlign: "right" }} colSpan={8}>Pet Fees (+)</td>
                                        <td style={{ width:"7%", textAlign: "right" }} ><span style={{ color:"#1e2b5d", fontWeight: "bold"}}>{CisUI().getCurrencyFormated3(bookingDetail.pet_fee * bookingDetail.pets)}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width:"5%", textAlign: "right" }} colSpan={8}>Grand Total</td>
                                        <td style={{ width:"7%", textAlign: "right" }} ><span style={{ color:"#1e2b5d", fontWeight: "bold"}}>{CisUI().getCurrencyFormated3(grandTotal)}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width:"5%", textAlign: "right" }} colSpan={8}>Whole Discount</td>
                                        <td style={{ width:"7%", textAlign: "right" }}>
                                            {
                                                details.status > 0 ?
                                                    <span style={{ color:"#1e2b5d" }}>{CisUI().getCurrencyFormated3(details.whole_discount)}</span>
                                                    :
                                                    <div className="gx-form-row0 gx-ml-4" style={{width:"78%"}}>
                                                        <Form.Item
                                                            name="whole_discount"
                                                            initialValue={0}
                                                        >
                                                            <Input style={{ textAlign:"right" }} onChange={calculatePrice}/>
                                                        </Form.Item>
                                                    </div>
                                            }
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ width:"5%", textAlign: "right" }} colSpan={8}>Payable Amount</td>
                                        <td style={{ width:"7%", textAlign: "right" }}><span style={{ color:"#1e2b5d", fontWeight: "bold"}}>{CisUI().getCurrencyFormated3(payableAmount.toFixed(2))}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width:"5%", textAlign: "right" }} colSpan={8}>Advance Payment {details.advance_paid === 1 ? <span>(Paid)</span> : <span>(Not Paid)</span>}</td>
                                        <td style={{ width:"7%", textAlign: "right" }} >
                                            {
                                                details.status === 0 ?
                                                    <div className="gx-form-row0 gx-ml-4" style={{width:"78%"}}>
                                                        <Form.Item
                                                            name="advance"
                                                            initialValue={0}
                                                        >
                                                            <Input style={{ textAlign:"right" }}/>
                                                        </Form.Item>
                                                    </div>
                                                    :
                                                    <p><span style={{ color:"#1e2b5d", fontWeight: "bold"}}>{CisUI().getCurrencyFormated3(bookingDetail.advance)}</span></p>
                                            }

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </Form>
                    </Card>
                </Col>


                <Col span={24} style={{ textAlign: "center" }}>
                    {
                        details.status === 0 && (
                            <>
                                <Button type="primary" onClick={() => reqDeclined(details.id)}>Declined</Button>
                                <Button type="primary" onClick={() => reqAccept(details.id)}>Save & Accept</Button>
                            </>
                        )
                    }
                    {/*{*/}
                    {/*    details.status === 1 && details.is_paid === 0 && (*/}
                    {/*        <Button type="primary" onClick={() => reqDeclined(details.id)}>Pay</Button>*/}
                    {/*    )*/}
                    {/*}*/}
                    <Button type="primary" onClick={reqBack}>Back</Button>
                </Col>
            </Row>

            <Modal
                title="Request Declined"
                centered
                visible={isHide}
                // onOk={() => setIsHide(false)}
                onCancel={() => setIsHide(false)}
                onOk={onFinish}
                width={1000}
            >
                <Form
                    form={form}
                    {...CisUI().formItemLayout}
                    name="input"
                    className="ant-advanced-search-form"
                    // onFinish={onFinish}
                    initialValues={{ remember: true }}
                >
                    <Form.Item name="remarks" label="Remarks" rules={[{
                        required: true,
                        message: 'Remarks is required.'
                    }]}>
                        <Input type="textarea" />
                    </Form.Item>

                </Form>
            </Modal>


        </Card>
    )
}

export default BookingReqDetails
