import React, {useEffect, useState} from "react";
import {Form, Card, Col, Select, Row, Button, DatePicker} from 'antd';
import jsonfile from './booking_date.json'
import {CisUI} from '../../../util/CISUI'
import Config from "../../../util/config";
import axios from "../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../util/gerremotedata";
import IntlMessages from "../../../util/IntlMessages";
import SelectData from "../../../util/selectdata";

const {RangePicker} = DatePicker;

const NewBookingDate = (props) => {

    const [state, setState] = useState({
        rows:[]
    });
    const [rooms, setRooms] = useState([]);

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    const handleAddRows = () => {
        let items = {
            date: "",
        };
        setState({
            rows: [...state.rows, items]
        })
    }

    const handleChange = (index) => e => {
        // console.log(index, e.target.value)
        const { name, value } = e.target;
        const list = [...state.rows];
        list[index][name] = value;
        // console.log(list)
        setState({
            rows: list
        })
    }

    const handleRowDelete = (i) => {
        let new_arr = state.rows.filter((item, index) => index !== i)

        setState({
            rows: new_arr
        })
    }

    let properties = SelectData('property/properties');
    let booking_source = SelectData('bookingsource');

    const handlePropertyChange = (id) => {

        document.getElementById("loader").style.display = "block";
        axios.get(Config.apiserver+`property/propertywiserooms/${id}`, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    setRooms(res.data.data)
                }
                else {
                    CisUI().Notification('warning', 'Failed')
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(error => console.log(error.response))
    }

    useEffect(() => {
        handlePropertyChange()
    }, [])

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }


    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {
        const data = {
            ...values,
            // items: state.rows,
           date_range: [values['date_range'][0].format('YYYY-MM-DD'), values['date_range'][1].format('YYYY-MM-DD')],
        }

          axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
              .then(res => {
                  console.log(res.data)
                  if (res.data.status === 1) {
                      CisUI().Notification('success', res.data.msg);
                      history.push(redirectto);
                  } else {
                      CisUI().Notification('error', res.data.msg);
                  }
                  // document.getElementById("loader").style.display = "none";
              })
              .catch(errors => {
                  console.log(errors);
              });
    };

    return (
        <Card title={<IntlMessages id={Title}/>} extra={
                isEdit === 1
                    ? CisUI().addAction(redirectto)
                    : CisUI().addAction(redirectto)
            }>
            {CisUI().showLoading}
            <Form
                // form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                <Row gutter={24}>
                    <Col lg={8} md={8} sm={12} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name="property_id"
                                label="Property/Hotel"
                                rules={[{
                                    required: true,
                                    message: "Property/Hotel is required"
                                }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select an option"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase())}
                                    onChange={handlePropertyChange}
                                >
                                    {
                                        properties.map((item, index) =>
                                            <option value={item.id}>{item.name}</option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                    </Col>

                    <Col lg={8} md={8} sm={12} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name="room_id"
                                label="Room"
                                rules={[{
                                    required: true,
                                    message: "Room is required"
                                }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select an option"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase())}
                                    // onChange={handleChange}
                                    disabled={rooms != '' ? false : true}
                                >
                                    {
                                        rooms.map((item, index) =>
                                            <option value={item.id}>{item.room_name}</option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                    </Col>

                    <Col lg={8} md={8} sm={12} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name="source"
                                label="Booking Source"
                                rules={[{
                                    required: true,
                                    message: "Booking Source is required"
                                }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select an option"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase())}
                                    // onChange={handleChange}
                                >
                                    {
                                        booking_source.map((item, index) =>
                                            <option value={item.id}>{item.name}</option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                    </Col>

	                <Col lg={8} md={8} sm={12} xs={24}>
		                <div className="gx-form-row0">
			                <Form.Item
				                name="date_range"
				                label="Booking Dates"
				                rules={[{
					                required: true,
					                message: "Date is required"
				                }]}
			                >
				                <RangePicker className="gx-w-100"/>
			                </Form.Item>
		                </div>
	                </Col>

                </Row>

                <Row gutter={24} style={{ marginTop:"15px" }}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            <IntlMessages id={"Submit"} />
                        </Button>
                        <Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Back"} /></Button>
                    </Col>
                </Row>

            </Form>
        </Card>
    );
};

export default NewBookingDate;
