/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Alert, Card, notification, Spin, Tag } from "antd";

import { Link } from "react-router-dom";
import 'react-smart-data-table/dist/react-smart-data-table.css'
import moment from "moment";
import Config from './config'
import axios from './Api'

import e2m from '../assets/images/e2m.png'
import agoda from '../assets/images/agoda.png'
import airbnb from '../assets/images/airbnb.png'
import booking_com from '../assets/images/booking_com.png'
import tokeet from '../assets/images/tokeet.png'
import tripadvisor from '../assets/images/tripadvisor.png'
import vrbo from '../assets/images/vrbo.png'
import verified from '../assets/images/verified_account.png'


const CisUI = () => {
    const Loader = Config.loader;
    const sematicUI = {
        change: 'ui labeled secondary icon button',
        changeIcon: 'exchange icon',
        checkbox: 'ui toggle checkbox',
        deleteIcon: 'trash red icon',
        input: 'ui input',
        iconInput: 'ui icon input',
        labeledInput: 'ui right labeled input',
        loader: 'ui active inverted dimmer"',
        message: 'ui message',
        refresh: 'ui labeled primary icon button',
        refreshIcon: 'sync alternate icon',
        rowsIcon: 'numbered list icon',
        searchIcon: 'search icon',
        segment: 'ui segment',
        select: 'ui dropdown',
        table: 'ui compact selectable table',
    }

    const getEndPointID = () => {
        return 0;
    }

    // const divider = <span style={{ display: 'inline-block', margin: '10px' }} />
    // const filterValue = "";

    const IdentityVerified = (name, identity_verified) => {
        let statusLB = "";

        if (identity_verified === 1) {
            statusLB = <span>{name} <sup><img src={verified} style={{ width: "5%" }} /></sup></span>
        }
        else {
            statusLB = <span>{name}</span>
        }
        return statusLB;
    };

    const GetLoginStatus = (status) => {
        let html = "";
        if(status === 1){
            html = <Tag color="#34495e" >Logged In</Tag>
        }
        else{
            html = <Tag color="#750707" >Logged Out</Tag>
        }

        return html;
    }

    const getStatusLabel = (status) => {
        let statusLB = { status };
        // if(status == 'Active') {
        //     statusLB = <Tag color="#34495e">{status}</Tag>
        // }
        // else
        if (status === 1) {
            statusLB = <Tag color="#34495e" >Active</Tag>
        }
        else if (status === 0) {
            statusLB = <Tag color="#750707" >Inactive</Tag>
        }
        else if (status === 2) {
            statusLB = <Tag color="#750707" >Blocked</Tag>
        }
        else {
            statusLB = <Tag color="#750707" >Terminated</Tag>
        }
        return statusLB;
    };

    const GetMessageEventAction = (action) => {

        let event_name = "";

        if (action == 1) {
            event_name = "Send Email Message";
        }
        else if (action == 2) {
            event_name = "Send HTTP Request";
        }
        else if (action == 3) {
            event_name = "Send Push Notification";
        }

        return event_name;
    };

    // const getBookingStatus = (status) => {
    //     let statusLB = { status };

    //     if (status == 1) {
    //         statusLB = <Tag color="#34495e">Running</Tag>
    //     }
    //     else if (status == 0) {
    //         statusLB = <Tag color="#750707">Not Paid</Tag>
    //     }
    //     else if (status == 2) {
    //         statusLB = <Tag color="#750707">Closed</Tag>
    //     }
    //     else {
    //         statusLB = <Tag color="#750707">Terminated</Tag>
    //     }
    //     return statusLB;
    // }

    // const getPaymentStatus = (status) => {
    //     let statusLB = { status };

    //     if (status == 1) {
    //         statusLB = <Tag color="#34495e">Success</Tag>
    //     }
    //     else if (status == 2) {
    //         statusLB = <Tag color="#34495e">Processing</Tag>
    //     }
    //     else if (status == 3) {
    //         statusLB = <Tag color="#34495e">Canceled</Tag>
    //     }
    //     else if (status == 4) {
    //         statusLB = <Tag color="#34495e">Failed</Tag>
    //     }
    //     else if (status == 5) {
    //         statusLB = <Tag color="#34495e">Amount Capturable Updated</Tag>
    //     }
    //     else if (status == 6) {
    //         statusLB = <Tag color="#34495e">Created</Tag>
    //     }
    //     else if (status == 7) {
    //         statusLB = <Tag color="#34495e">Partially Funded</Tag>
    //     }
    //     else if (status == 8) {
    //         statusLB = <Tag color="#34495e">Requires Action</Tag>
    //     }

    //     return statusLB;
    // }

    const getRequestStatus = (status) => {
        let statusLB = "";
        if (status === 0) {
            statusLB = <Tag color="#34495e">Pending</Tag>
        }
        else if (status === 1) {
            statusLB = <Tag color="#308d03">Accepted</Tag>
        }
        else if (status === 2) {
            statusLB = <Tag color="#750707">Declined</Tag>
        }
        else {
            statusLB = <Tag color="#750707">Canceled</Tag>
        }
        return statusLB;
    }

    const getOrderStatus = (status) => {
        let statusLB = { status };
        if (status === 'Active') {
            statusLB = <Tag color="#34495e">{status}</Tag>
        }
        else if (status === 1) {
            statusLB = <Tag color="#34495e" >Approved</Tag>
        }
        else if (status === 11) {
            statusLB = <Tag color="#34495e" >Paid</Tag>
        }
        else if (status === 0) {
            statusLB = <Tag color="#750707" >Pending</Tag>
        }
        else if (status === 3) {
            statusLB = <Tag color="#750707" >Rejected</Tag>
        }
        else {
            statusLB = <Tag color="#750707" >{status}</Tag>
        }
        return statusLB;
    };

    const getTaskStatus = (status) => {
        let statusLB = "";
        if (status === 0) {
            statusLB = <Tag color="#8B9299">Pending</Tag>
        }
        else if (status === 1) {
            statusLB = <Tag color="#1E2B5D">Finished</Tag>
        }
        else if (status === 2) {
            statusLB = <Tag color="#750707">Working</Tag>
        }
        else {
            statusLB = <Tag color="#27BEC4">Reassigned</Tag>
        }
        return statusLB;
    }

    const print = (apiurl, datakey, filename) => {
        // window.print();
        window.open(Config.baseurl + "print.html?url=" + window.btoa(apiurl) + "&filename=" + filename + "&data=" + datakey, "_blank");
    }


    //for excel data
    const exportToExcel = (apiData, fileName) => {
        return ""
    };

    const getAPIData = (APIUrl, dataParameter, filename, fulljson) => {
        axios.get(APIUrl)
            .then((res) => {
                //console.log(res.data);
                if (res.data.status === 1) {
                    const data = res.data[dataParameter];
                    exportToExcel(generateObject(data, fulljson.listView.print), filename);
                    //console.log(JSON.stringify(data));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // function createHeaders(keys) {
    //     var result = [];
    //     for (var i = 0; i < keys.length; i += 1) {
    //         result.push({
    //             id: keys[i],
    //             name: keys[i],
    //             prompt: keys[i],
    //             padding: 0,
    //             fontSize : '10px'
    //         });
    //     }
    //     return result;
    // }

    const generateDatas = (datav, headers) => {
        var result = [];
        datav.map((x, i) => {
            //console.log(JSON.stringify(x));
            //var data = {};
            var data = [];
            for (var f = 0; f < headers.length; f += 1) {
                //data[headers[f]] = " "+x[headers[f]];
                data.push(x[headers[f]]);
            }
            //data.id = (i + 1).toString();
            result.push(Object.assign([], data));
        });
        //console.log(JSON.stringify(result));
        return result;

    };

    const generateObject = function (datav, headers) {
        var result = [];
        datav.map((x, i) => {
            //console.log(JSON.stringify(x));
            //var data = {};
            var data = [];
            for (var f = 0; f < headers.length; f += 1) {
                data[headers[f]] = " " + x[headers[f]];
                //data.push(x[headers[f]]);
            }
            result.push(Object.assign({}, data));
        });
        //console.log(JSON.stringify(result));
        return result;

    };

    const exportToDocs = (apiurl, datakey, filename, jsonfile) => {
        return ""
    }

    const getUserInfo = (fld) => {

        const userLocal = localStorage.getItem('userData') || "";
        let userID = 0;
        let userName = "";
        if (userLocal !== "") {
            const uj = JSON.parse(userLocal);
            return uj[fld];
        }
        return "";
    }

    const exportToPDF = (apiurl, datakey, filename, jsonfile, difftitle, printmode) => {
        return ""
    }



    const ListActionButton = (url = "", querystring = "", apiURL = "", dataParameter = "", filename = "", fullJson = {}, subtitle = "", printmode = "portrait") =>
    (
        <div className="no-print">
            {
                fullJson.urls.hasOwnProperty("list") ?
                    <>
                        <a id="rploader" style={{ width: '40px', height: '40px', display: 'none' }}>
                            <img src={Loader} style={{ width: '40px', height: '40px' }} alt="loader" /> Please wait ...
                        </a>
                        {
                            getPermission(fullJson.urls.list, "add") === 1 || getUserInfo('com_id') === 0 ?

                                <Link to={url + querystring} className="btn btn-text-adjust btn-circle-md" title="Add New">
                                    <i className="fas fa-plus" />
                                </Link> : ""}
                        {/*{*/}
                        {/*    getPermission(fullJson.urls.list,"export") === 1 || getUserInfo('com_id') === 0 ?*/}
                        {/*        <>*/}
                        {/*            <button className="btn btn-export-excel btn-circle-md" onClick={(e) => getAPIData(apiURL,dataParameter,filename,fullJson)} title="Export to Excel"><i*/}
                        {/*                className="fas fa-file-excel"/></button>*/}
                        {/*            <button className="btn btn-export-pdf btn-circle-md" onClick={(e) => exportToPDF(apiURL,dataParameter,filename,fullJson,printmode)} title="Export to PDF"><i*/}
                        {/*                className="fas fa-file-pdf"/></button>*/}
                        {/*            <button className="btn btn-export-word btn-circle-md" onClick={(e) => exportToDocs(apiURL,dataParameter)} title="Export MS Word"><i*/}
                        {/*                className="fas fa-file-word"/></button>*/}
                        {/*        </>*/}
                        {/*        : "" }*/}
                        {/*<button onClick={(e) => print(apiURL,dataParameter,filename)} className="btn btn-export-print btn-circle-md" title="Print"><i*/}
                        {/*    className="fas fa-print"/></button>*/}
                    </>
                    : ""
            }

        </div>
    );

    const ListActionButtonReport = (url = "", querystring = "", apiURL = "", dataParameter = "", filename = "", fullJson = {}, subtitle = "", printmode = "portrait") =>
    (
        <div className="no-print">
            <a id="rploader" style={{ width: '40px', height: '40px', display: 'none' }}>
                <img src={Loader} style={{ width: '40px', height: '40px' }} alt="loader" /> Please wait ...
            </a>
            {
                fullJson.urls.hasOwnProperty("list") ?
                    <>
                        {/*{*/}
                        {/*    getPermission(fullJson.urls.list,"export") === 1 || getUserInfo('com_id') === 0 ? <>*/}
                        <button className="btn btn-export-excel btn-circle-md" onClick={(e) => getAPIData(apiURL, dataParameter, filename, fullJson)} title="Export to Excel"><i
                            className="fas fa-file-excel" /></button>
                        <button className="btn btn-export-pdf btn-circle-md" onClick={(e) => exportToPDF(apiURL, dataParameter, filename, fullJson, printmode)} title="Export to PDF"><i
                            className="fas fa-file-pdf" /></button>
                        <button className="btn btn-export-word btn-circle-md" onClick={(e) => exportToDocs(apiURL, dataParameter)} title="Export MS Word"><i
                            className="fas fa-file-word" /></button>
                        <button onClick={(e) => print(apiURL, dataParameter, filename)} className="btn btn-export-print btn-circle-md" title="Print"><i
                            className="fas fa-print" /></button>
                        {/*</>: "" }*/}
                    </> : ""}
        </div>
    );

    const ListActionButtonNoReport = (url = "", querystring = "", apiURL = "", dataParameter = "", filename = "", fullJson = {}, subtitle = "", printmode = "portrait") =>
    (
        <div className="no-print">
            <a id="rploader" style={{ width: '40px', height: '40px', display: 'none' }}>
                <img src={Loader} style={{ width: '40px', height: '40px' }} alt="loader" /> Please wait ...
            </a>

            {/*{*/}
            {/*    getPermission(fullJson.urls.list,"add") === 1 || getUserInfo('com_id') === 0 ? <>*/}
            <Link to={url + querystring} className="btn btn-text-adjust btn-circle-md" title="Add New">
                <i className="fas fa-plus" />
            </Link>
            {/*</>: "" }*/}
        </div>
    );

    const AddActionButton = (url) => (<>
        <div className="row no-print">
            <div className="col-md-6">
            </div>
            <div className="col-md-6 right">
                <Link to={url} className="btn btn-text-adjust btn-circle-md" title="Font Size Increase">
                    <i className="fas fa-list" />
                </Link>
            </div>
        </div>
    </>);


    const generateData = (numResults = 100) => {
        return ""
    }

    const formItemLayout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };

    const hideUnordered = false;
    const perPage = 50;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'superadmin:123456'
    }

    const dateFormat = 'MM/DD/YYYY';

    const emptyTable = (
        <div className={sematicUI.message}>
            There is no data available to display.
        </div>
    )

    const createNotification = (type, message) => {
        return () => {
            switch (type) {
                case 'info':
                    notification.open({
                        message: 'Alert',
                        description: { message },
                    });
                    break;
                case 'success':
                    notification.open({
                        message: 'Success',
                        description: { message },
                    });
                    break;
                case 'warning':
                    notification.open({
                        message: 'Alert',
                        description: { message },
                    });
                    break;
                case 'error':
                    notification.open({
                        message: 'Error',
                        description: { message },
                    });
                    break;
                default:
                    notification.open({
                        message: 'Alert',
                        description: { message },
                    });
                    break;

            }
        };
    };

    const loadingMsg = (
        <Card title="Customize" className="gx-card">
            <Spin tip="Loading...">
                <Alert
                    message="Alert message title"
                    description="Further details about the context of this alert."
                    type="info"
                />
            </Spin>
        </Card>
    )

    const HeaderRequest = () => {
        const token = localStorage.getItem('token');
        return { headers: { Authorization: token } };
    }

    const CurrencyFormat = (x) => {
        let number = parseFloat(x).toFixed(2);
        return "$" + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const FullName = (fname, lname) => {
        return fname + " " + lname;
    }

    const DateFormat = (data) => {
        // return moment(data).format("MM/DD/YYYY");
        if (data !== null) {
            return moment(data).format("MM-DD-YYYY");
        }
    }

    const ParseDateFormat1 = (data) => {
        // return moment(data).format("MM/DD/YYYY");
        if (data !== null) {
            return moment(data[0]).format("MM-DD-YYYY") + "," + moment(data[1]).format("MM-DD-YYYY");
        }
    }

    const DateFormatWithTime = (data) => {
        // return moment(data).format("MM/DD/YYYY hh:mm:ss");
        if (data !== null) {
            return moment(data).format("MM-DD-YYYY hh:mm:ss");
        }
    }

    const TimeFormat = (data) => {
        // return moment(data).format("MM/DD/YYYY hh:mm:ss");
        if (data !== null) {
            return moment(data);
        }
    }

    const loader = (
        <div className="loader-table">
            <img src={Loader} />
        </div>
    )

    const LoadingOn = () => {
        return <div className="loader-table">
            <img src={Loader} />
        </div>
    }

    const showLoading = (
        <div id="loader" className="loading_view" style={{ display: 'none' }}>
            <div className="loading_center">
                <div className="loader-table">
                    <img style={{ background: 'transparent' }} src={Config.loader} />
                </div>
                <h4 style={{ textAlign: 'center' }}>Please wait...</h4>
            </div>
        </div>
    );

    const showLoading1 = (
        <div id="loader" className="loading_view" style={{ display: 'block' }}>
            <div className="loading_center">
                <div className="loader-table">
                    <img style={{ background: 'transparent' }} src={Loader} />
                </div>
                <h4 style={{ textAlign: 'center' }}>Please wait...</h4>
            </div>
        </div>
    );

    const numberFormat = (value) =>
        new Intl.NumberFormat('en-us', {
            style: 'currency',
            currency: 'GBP'
        }).format(value);

    const getCurrencyFormated = (amount) => {
        return <div style={{ textAlign: 'right', marginRight: "30px", fontWeight: "bold" }}>{numberFormat(amount || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
    }

    const getCurrencyFormated1 = (amount) => {
        if (amount >= 0) {
            return numberFormat(amount || 0);
        }
        else {
            const amt = numberFormat(amount || 0);
            return "(" + amt.replace("-", "") + ")";
        }
    }

    const getCurrencyFormated2 = (amount) => {
        return <div>{numberFormat(amount || 0)}</div>
    }

    const getCurrencyFormated3 = (amount) => {
        return numberFormat(amount || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    const getNumber = (amount) => {
        return new Intl.NumberFormat('en-us').format(amount);
    }

    const parseParams = (params = "") => {
        const rawParams = params.replace("?", "").split("&");
        const extractedParams = {};
        rawParams.forEach((item) => {
            item = item.split("=");
            extractedParams[item[0]] = item[1];
        });
        return extractedParams;
    };

    const getMonth = (month) => {
        if (month !== null) {
            moment.locale('en-gb');
            const date = month.toString().substr(0, 4) + "-" + month.toString().substr(4, 2) + "-01";
            return moment(date).format("MMMM YYYY");
        }
    }

    const ParseDateFormat = (value) => {
        if (value === null || value === undefined) {
            return '';
        }
        else {
            const from = moment(value[0]).format("YYYY-MM-DD");
            const to = moment(value[1]).format("YYYY-MM-DD");
            return [from, to];
        }
    }

    const ParseMonthFormat = (value) => {
        if (value === null || value === undefined) {
            return '';
        }
        else {
            return moment(value[0]).format("YYYY-MM");
        }
    }

    const ParseDateFormatFromMonth = (value) => {
        if (value === null || value === undefined) {
            return '';
        }
        else {
            return moment(value).format("YYYY-MM-01");
        }
    }

    const getWeekRange = (date) => {
        moment.locale('en');
        //const startdate = moment(date).clone().startOf('week').isoWeekday(0).format("MM-DD-YYYY");
        //const enddate = moment(date).clone().endOf('week').isoWeekday(0).format("MM-DD-YYYY");
        //return startdate+","+enddate;
        return moment(date).clone().startOf('week').format("YYYY-MM-DD");
    }

    const getWeekRangeLocal = (date) => {
        moment.locale('en');
        const from = moment(date).clone().startOf('week').format("MM/DD/YYYY");
        const to = moment(date).clone().endOf('week').format("MM/DD/YYYY");

        return [from, to];
    }

    const getMonthRangeLocal = (date) => {
        moment.locale('en');
        const from = moment(date).format("MM/01/YYYY");
        const to = moment(date).format("MM/31/YYYY");

        return [from, to];
    }

    const getMonthTitle = (date) => {
        moment.locale('en-gb');
        const title = moment(date).format("MMMM, YYYY");
        const month = moment(date, 'YYYY-MM-DD').toDate();

        return [title];
    }

    const getMonthRange = (date) => {
        const from = moment(date).format("YYYY-MM-01");
        const to = moment(date).format("YYYY-MM-31");

        return [from, to];
    }

    const getRestrictionType = (key) => {
        const td = {
            none: "None",
            with: "With Restriction",
            without: "Without Restriction"
        }

        return td[key];
    }

    const getQueryStrings = (values) => {
        const qs = Object.keys(values).map(key => {
            if (values[key] === undefined || values[key] === "" || values[key] === null) {
                return `${key}=${""}`
            }
            else {
                console.log(key + ":" + values[key]);
                if (key === 'date') {
                    return `${key}=${CisUI().ParseDateFormat(values[key])}`
                } else {
                    return `${key}=${values[key]}`
                }
            }
        }
        ).join('&');

        return qs.replaceAll("undefined", "");
    }

    const getBillSatus = (statuscode) => {
        let sts = <Tag color="#34495e">Pending</Tag>
        if (statuscode === 0) {
            sts = <Tag color="#34495e">Pending</Tag>
        }
        else if (statuscode === 1) {
            sts = <Tag color="#34495e">Approved</Tag>
        }
        else if (statuscode === 3) {
            sts = <Tag color="#34495e">Reject</Tag>
        }
        return sts;
    }

    const ShowAlert = () => {
        return <Alert
            message="Success Tips"
            description="Detailed description and advice about successful copywriting."
            type="success"
            showIcon
        />
    }

    const getPermission = (key, prms) => {
        const prm = localStorage.getItem("prm");
        if (key != null) {
            const kc = key.split("/");
            let kr = key;
            if (kc.length > 0) {
                kr = kc[(kc.length - 1)];
            }

            if (prm !== "") {
                const jps = JSON.parse(prm);
                if (jps.hasOwnProperty(kr)) {
                    //console.log(JSON.stringify(jps[kr]));
                    if (jps[kr][prms] === 1) {
                        return 1;
                    }
                }
            }
        }
        return 0;
    }

    const Notification = (type, message) => {
        switch (type) {

            case 'success':
                return notification.success({
                    type: type,
                    message: 'Success',
                    description: message
                });

            case 'error':
                return notification.error({
                    type: type,
                    message: 'Error',
                    description: message
                });

            case 'warning':
                return notification.warning({
                    type: type,
                    message: 'Warning',
                    description: message
                });

            case 'info':
                return notification.info({
                    type: type,
                    message: 'Info',
                    description: message
                });

            default:
                return false
        }
    }

    return {
        loader: loader,
        emptyTable: emptyTable,
        hideUnordered: hideUnordered,
        perPage: perPage,
        loadingMsg: loadingMsg,
        sematicUI: sematicUI,
        formItemLayout: formItemLayout,
        headers: headers,
        createNotification: createNotification,
        listAction: ListActionButton,
        listActionReport: ListActionButtonReport,
        listActionNoReport: ListActionButtonNoReport,
        addAction: AddActionButton,
        getStatusLabel: getStatusLabel,
        getRequestStatus: getRequestStatus,
        getBillSatus: getBillSatus,
        getOrderStatus: getOrderStatus,
        generateData: generateData(),
        HeaderRequest: HeaderRequest(),
        CurrencyFormat: CurrencyFormat,
        FullName: FullName,
        DateFormat: DateFormat,
        DateFormatWithTime: DateFormatWithTime,
        dateFormat: dateFormat,
        LoadingOn: LoadingOn,
        getCurrency: getCurrencyFormated,
        getMonth: getMonth,
        ParseDateFormat: ParseDateFormat,
        ParseDateFormatFromMonth: ParseDateFormatFromMonth,
        getWeekRange: getWeekRange,
        getWeekRangeLocal: getWeekRangeLocal,
        getCurrencyFormated: getCurrencyFormated,
        getCurrencyFormated1: getCurrencyFormated1,
        getCurrencyFormated2: getCurrencyFormated2,
        getCurrencyFormated3: getCurrencyFormated3,
        getNumber: getNumber,
        getMonthRangeLocal: getMonthRangeLocal,
        getMonthRange: getMonthRange,
        getMonthTitle: getMonthTitle,
        showLoading: showLoading,
        showLoading1: showLoading1,
        parseParams: parseParams,
        getQueryStrings: getQueryStrings,
        TimeFormat: TimeFormat,
        getUserInfo: getUserInfo,
        getRestrictionType: getRestrictionType,
        getEndPointID: getEndPointID,
        ShowAlert: ShowAlert,
        getPermission: getPermission,
        Notification,
        ParseMonthFormat: ParseMonthFormat,
        getTaskStatus,
        // getBookingStatus,
        // getPaymentStatus,
        IdentityVerified,
        GetMessageEventAction,
        GetLoginStatus
    }
}


const generateData = (numResults = 100) => {
    return ""
}

export {
    CisUI,
    generateData
}


export const getPaymentStatus = (status) => {
    let statusLB = '';

    if (status == 0) {
        statusLB = <Tag color="#34495e">Not Paid</Tag>
    }
    if (status == 1) {
        statusLB = <Tag color="#34495e">Success</Tag>
    }
    else if (status == 2) {
        statusLB = <Tag color="#34495e">Processing</Tag>
    }
    else if (status == 3) {
        statusLB = <Tag color="#34495e">Canceled</Tag>
    }
    else if (status == 4) {
        statusLB = <Tag color="#34495e">Failed</Tag>
    }
    else if (status == 5) {
        statusLB = <Tag color="#34495e">Amount Capturable Updated</Tag>
    }
    else if (status == 6) {
        statusLB = <Tag color="#34495e">Created</Tag>
    }
    else if (status == 7) {
        statusLB = <Tag color="#34495e">Partially Funded</Tag>
    }
    else if (status == 8) {
        statusLB = <Tag color="#34495e">Requires Action</Tag>
    }

    return statusLB;
}

export const getBookingStatus = (status) => {
    let statusLB = ''

    if (status == 1) {
        statusLB = <Tag color="#34495e">Running</Tag>
    }
    else if (status == 0) {
        statusLB = <Tag color="#750707">Not Paid</Tag>
    }
    else if (status == 2) {
        statusLB = <Tag color="#750707">Closed</Tag>
    }
    else {
        statusLB = <Tag color="#750707">Terminated</Tag>
    }
    return statusLB;
}

export const GetBookingSourceImage = (source) => {
    switch (source) {
        case 1:
            return e2m
        case 2:
            return airbnb
        case 4:
            return agoda
        case 5:
            return vrbo
        case 6:
            return booking_com
        case 7:
            return tripadvisor
        case 8:
            return tokeet
        default:
            return e2m
    }
}

export const BookingStatus = (value) => {
    let status = "";
    switch (value) {
        case 1:
            status = 'Booking Confirm';
            break;
        case 2:
            status = 'Checked In';
            break;
        case 3:
            status = 'Checked Out';
            break;
        case 4:
            status = 'Booking Cancel';
            break;
        default:
            status = 'Not Confirm';
    }

    return status;
}
