import React, { useEffect, useState } from 'react'
import {Button, Card, Col, Row} from 'antd'
import axios from 'axios'
import {Link, useHistory} from 'react-router-dom';
import {BookingStatus, CisUI, getPaymentStatus} from '../../../../util/CISUI';
import Config from '../../../../util/config'
import jsonfile from "./booking.json";
import CircularProgress from "../../../../components/CircularProgress";

const BookingDetails = (props) => {
    const [loader, setLoader] = useState(false);
    const [details, setDetails] = useState({});
    const [tranx, setTranx] = useState([]);
    const [statusChange, setStatusChange] = useState(0);
    const [bookingDetail, setBookingDetail] = useState({
        check_in: "",
        check_out: "",
        room_name: "",
        price: 0.00,
        nights: 0,
        total: 0.00,
        discount_percentage: 0.00,
        discount_amount: 0.00,
        cleaning_fee: 0.00,
        pet_fee: 0.00,
        pets: 0,
        grand_total: 0.00,
        after_discount_total: 0.00
    });

    let redirectto = "../../"+jsonfile.urls.list;
    // const [form] = Form.useForm();
    // const[payableAmount, setPayableAmount] = useState(0.00);

    const id = props.match.params.id;

    console.log(id);

    useEffect(() => {
        getBookingDetails()
    }, [statusChange])

    const getBookingDetails = (() => {
        setLoader(true)
        axios.get(Config.apiserver + `booking/bookingdetails/${id}`, CisUI().HeaderRequest)
            .then(res => {
                console.log(res.data)
                if(res.data.status === 1) {
                    setDetails(res.data.booking_info)
                    setTranx(res.data.transaction)
                    setBookingDetail({
                        check_in: res.data.booking_items.check_in,
                        check_out: res.data.booking_items.check_out,
                        room_name: res.data.booking_items.room_name,
                        price: res.data.booking_items.price,
                        nights: res.data.booking_items.nights,
                        total: res.data.booking_items.total,
                        discount_percentage: res.data.booking_items.discount_percentage,
                        discount_amount: res.data.booking_items.discount_amount,
                        cleaning_fee: res.data.booking_items.cleaning_fee,
                        pet_fee: res.data.booking_items.pet_fee,
                        pets: res.data.booking_items.pets !== null ? res.data.booking_items.pets : 0,
                        grand_total: res.data.booking_items.grand_total,
                        after_discount_total: res.data.booking_items.after_discount_total
                    })
                }
                else {
                    CisUI().Notification('warning', res.data.msg)
                }
                setLoader(false)
            })
            .catch(error => {
                setLoader(false)
                console.log(error);
            })
    })

    const history = useHistory();

    const reqBack = () => {
        history.push("/booking/bookinghistory");
    }

    const changeStatus = (value, id) => {
        setStatusChange(0)
        axios.post(Config.apiserver + `booking/bookingstatuschage/${id}`, {status: value}, CisUI().HeaderRequest)
            .then(res => {
                if(res.data.status === 1){
                    CisUI().Notification('success', res.data.msg)
                    setStatusChange(1)
                }
                else{
                    CisUI().Notification('warning', res.data.msg)
                }
            })
            .catch(error => console.log(error))
    }

    return (
        loader ? <CircularProgress /> :
            <Card title={jsonfile.details} extra={CisUI().addAction(redirectto)}>
            <Row>
                <Col span={12}>
                    <Card title="Customer Info" style={{ width: "100%" }}>
                        <div className="gx-media gx-featured-item">
                            <div className="gx-featured-thumb">
                                <img className="gx-rounded-lg gx-width-175" src={details.customer_image != '' ? Config.customer_image+details.customer_image : Config.image_url+'/upload/no_image.png'} alt="..." style={{height:"130px"}}/>
                                {/*<Tag className="gx-rounded-xs gx-bg-orange gx-border-orange gx-text-white">Featured</Tag>*/}
                            </div>
                            <div className="gx-media-body gx-featured-content">
                                <div className="gx-featured-content-left">

                                    {/*<Tag className="gx-rounded-xs" color="#06BB8A">FOR SALE</Tag>*/}
                                    <h3 className="gx-mb-2">Customer Name: {details.customer_name}</h3>

                                    <p className="gx-text-grey gx-mb-1">{details.customer_email}</p>

                                    <div className="ant-row-flex gx-mt-4">
                                        <p className="gx-mr-3 gx-mb-1"><span className="gx-text-grey">Phone:</span> { details.customer_phone }</p>
                                        <p className="gx-mr-3 gx-mb-1"><span className="gx-text-grey">State:</span> { details.customer_state }</p>
                                        <p className="gx-mr-3 gx-mb-1"><span className="gx-text-grey">City:</span> { details.customer_city }</p>
                                    </div>
                                </div>
                                <div className="gx-featured-content-right">
                                    <Link to={`/crm/customeredit/${details.customer_id}`} className="gx-text-primary gx-text-truncate gx-mt-auto gx-mb-0 gx-pointer">Details <i
                                        className={`icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle`}/></Link>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Col>

                <Col span={12}>
                    <Card title="Property Details" style={{ width: "100%" }}>

                        <div className="gx-media gx-featured-item">
                            <div className="gx-featured-thumb">
                                <img className="gx-rounded-lg gx-width-175" src={details.property_image != null ? Config.property_info_image+details.property_image : Config.image_url+'/upload/no_image.png'} alt="..." style={{height:"130px"}}/>
                            </div>
                            <div className="gx-media-body gx-featured-content">
                                <div className="gx-featured-content-left">

                                    <h3 className="gx-mb-2">Property Name: {details.property_name}</h3>

                                    <p className="gx-text-grey gx-mb-1">{details.property_email}</p>

                                    <div className="ant-row-flex gx-mt-4">
                                        <p className="gx-mr-3 gx-mb-1"><span className="gx-text-grey">Phone:</span> { details.property_phone }</p>
                                        <p className="gx-mr-3 gx-mb-1"><span className="gx-text-grey">Website:</span> <a href={ details.property_website } target="_blank">Link</a></p>
                                        <p className="gx-mr-3 gx-mb-1"><span className="gx-text-grey">City:</span> { details.property_city }</p>
                                        <p className="gx-mr-3 gx-mb-1"><span className="gx-text-grey">Area:</span> { details.property_area  }</p>
                                    </div>
                                </div>
                                <div className="gx-featured-content-right">
                                    <Link to={`/property/editpropertyinfo/${details.property_id}`} className="gx-text-primary gx-text-truncate gx-mt-auto gx-mb-0 gx-pointer">Details <i
                                        className={`icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle`}/></Link>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Col>

                <Col span={12}>
                    <Card title="Booking Status" style={{ width: "100%" }}>
                        <p style={{ fontSize:"16px"}} className="gx-mr-3 gx-mb-1">Current Booking Status: {BookingStatus(details.booking_status)}</p>
                        <div className="gx-flex-row gx-mb-4">
                            <p style={{ fontSize:"16px"}} className="gx-mr-3 gx-mb-1">Change Status: </p>
                            {/*<Button type="primary" size="small" onClick={reqBack} style={{ fontSize: '12px',}}>Check In</Button>*/}
                            {/*<Button type="primary" size="small" onClick={reqBack} style={{ fontSize: '12px', }}>Check Out</Button>*/}
                            <p
                                style={{
                                    fontSize: "14px",
                                    cursor: "pointer",
                                    border: "1px solid #1e2b5d",
                                    padding: "3px 5px",
                                    borderRadius: "5px",
                                    background: "#1e2b5d",
                                    color: "#fff"
                                }}
                                className="gx-mr-3 gx-mb-1"
                                onClick={() => changeStatus(2, details.id)}
                            >
                                Check In
                            </p>
                            <p
                                style={{
                                    fontSize: "14px",
                                    cursor: "pointer",
                                    border: "1px solid #1e2b5d",
                                    padding: "3px 5px",
                                    borderRadius: "5px",
                                    background: "#1e2b5d",
                                    color: "#fff"
                                }}
                                className="gx-mr-3 gx-mb-1"
                                onClick={() => changeStatus(3, details.id)}
                            >
                                Check Out
                            </p>
                        </div>
                    </Card>
                </Col>

                <Col span={12}>
                    <Card title="Transaction History" style={{ width: "100%" }}>
                        {
                            tranx.length > 0 ?

                                <div className="table-responsive-md">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Tranx ID</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tranx.map((item, index) =>
                                                    <tr>
                                                        <td>{item.date}</td>
                                                        <td>{item.payment_id}</td>
                                                        <td>{item.debit}</td>
                                                        <td>{getPaymentStatus(item.status)}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div className="gx-flex-row gx-mb-4">
                                    <p style={{ fontSize:"16px"}} className="gx-mr-3 gx-mb-1">No Data Found.</p>
                                </div>
                        }
                    </Card>
                </Col>

                <Col span={24}>
                    <Card title="Booking Details" style={{ width: "100%" }}>

                        <div className="gx-flex-row gx-mb-4">
                            <p style={{ fontSize:"16px"}} className="gx-mr-3 gx-mb-1">Nights: <span style={{ color:"#1e2b5d" }}>{bookingDetail.nights}</span></p>
                            <p style={{ fontSize: "16px" }} className="gx-mr-3 gx-mb-1">No. of pets: <span style={{ color:"#1e2b5d" }}>{bookingDetail.pets ? bookingDetail.pets : 0}</span></p>
                            <p style={{ fontSize:"16px"}} className="gx-mr-3 gx-mb-1">Total Guests: <span style={{ color:"#1e2b5d" }}>{details.guest}</span></p>
                        </div>

                        <div className="table-responsive-md">
                            <table className="table req_table">
                                <thead>
                                    <tr>
                                        <th style={{ width:"30%" }}>Room Name</th>
                                        <th style={{ width:"5%" }}>Check In</th>
                                        <th style={{ width:"5%" }}>Check Out</th>
                                        <th style={{ width:"5%" }}>Price</th>
                                        <th style={{ width:"5%" }}>Nights</th>
                                        <th style={{ width:"6%" }}>Total</th>
                                        <th style={{ width:"5%" }}>Discount (%)</th>
                                        <th style={{ width:"5%" }}>Discount Amount</th>
                                        <th style={{ width:"7%" }}>Total Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width:"30%" }}>{bookingDetail.room_name}</td>
                                        <td style={{ width:"5%" }}>{CisUI().DateFormat(bookingDetail.check_in)}</td>
                                        <td style={{ width:"5%" }}>{CisUI().DateFormat(bookingDetail.check_out)}</td>
                                        <td style={{ width:"5%" }}>{CisUI().getCurrencyFormated(bookingDetail.price)}</td>
                                        <td style={{ width:"5%", textAlign: "center" }}>{bookingDetail.nights}</td>
                                        <td style={{ width:"6%" }}>{CisUI().getCurrencyFormated(bookingDetail.total)}</td>
                                        <td style={{ width:"5%" }}>{CisUI().getCurrencyFormated(bookingDetail.discount_percentage)}</td>
                                        <td style={{ width:"5%" }}>{CisUI().getCurrencyFormated(bookingDetail.discount_amount)}</td>
                                        <td style={{ width:"7%" }}>
                                            <span style={{ color:"#1e2b5d", fontWeight: "bold"}}>
                                                {CisUI().getCurrencyFormated(bookingDetail.total - bookingDetail.discount_amount)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width:"5%", textAlign:"right" }} colSpan={8}>Cleaning Fee</td>
                                        <td style={{ width:"7%" }}><span style={{ color:"#1e2b5d", fontWeight: "bold"}}>{CisUI().getCurrencyFormated(bookingDetail.cleaning_fee)}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width:"5%", textAlign:"right" }} colSpan={8}>Pet Fees</td>
                                        <td style={{ width:"7%" }} ><span style={{ color:"#1e2b5d", fontWeight: "bold"}}>{CisUI().getCurrencyFormated(bookingDetail.pet_fee * bookingDetail.pets)}</span></td>
                                    </tr>

                                    <tr>
                                        <td style={{ width:"5%", textAlign:"right" }} colSpan={8}>Grand Total</td>
                                        <td style={{ width:"7%" }}><span style={{ color:"#1e2b5d", fontWeight: "bold"}}>{CisUI().getCurrencyFormated(details.total)}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width:"5%",  textAlign:"right" }} colSpan={8}>Whole Discount Amount</td>
                                        <td style={{ width:"7%" }}><span style={{ color:"#1e2b5d", fontWeight: "bold"}}>{CisUI().getCurrencyFormated(details.dis_amnt)}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width:"5%",  textAlign:"right" }} colSpan={8}>Payable Amount</td>
                                        <td style={{ width:"7%" }}><span style={{ color:"#1e2b5d", fontWeight: "bold"}}>{CisUI().getCurrencyFormated(details.grand_total)}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width:"5%",  textAlign:"right" }} colSpan={8}>Advance {details.status === 0 ? <span>(Not Paid)</span> : details.status === 3 && <span>(Paid)</span>}</td>
                                        <td style={{ width:"7%" }}><span style={{ color:"#1e2b5d", fontWeight: "bold"}}>{CisUI().getCurrencyFormated(details.advance)}</span></td>
                                    </tr>
                                    {
                                        details.due > 0 &&
                                        <tr>
                                            <td style={{ width:"5%",  textAlign:"right" }} colSpan={8}>Due</td>
                                            <td style={{ width:"7%" }}><span style={{ color:"#1e2b5d", fontWeight: "bold"}}>{CisUI().getCurrencyFormated(details.due)}</span></td>
                                        </tr>
                                    }

                                </tbody>
                            </table>
                        </div>
                    </Card>
                </Col>


                <Col span={24} style={{ textAlign: "center" }}>
                    {/*{*/}
                    {/*    details.status === 0 &&*/}

                    {/*    <Button type="primary" onClick={reqBack}>Pay</Button>*/}
                    {/*}*/}
                    <Button type="primary" onClick={reqBack}>Back</Button>
                </Col>
            </Row>

        </Card>
    )
}

export default BookingDetails
