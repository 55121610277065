import React, {useState, useEffect} from 'react';
import {Button, Col, Form, Modal, Row, Tooltip} from "antd";
import SelectData from "../../../../util/selectdata";
import {CisUI} from "../../../../util/CISUI";
import Config from "../../../../util/config";
import axios from "axios";

const DataExportModal = ({ exportVisible, isExportShow, exporttitle, link }) => {
	const [form] = Form.useForm();
	// const [link, setLink] = useState("");
	const handleCancel = () => {
		// setIsShow(false);
		isExportShow(false)
	}

	const copy = () => {
		let copy = navigator.clipboard.writeText(link);
		if(copy !== ''){
			CisUI().Notification('success', 'Link is copied.')
		}
	}


	
	return(
		<>
			{/*<div id="loader3" className="loading_view" style={{display: 'none'}}>*/}
			{/*	<div className="loading_center">*/}
			{/*		<div className="loader-table">*/}
			{/*			<img style={{background : 'transparent'}} src={Config.loader} />*/}
			{/*		</div>*/}
			{/*		<h4 style={{textAlign: 'center'}}>Please wait...</h4>*/}
			{/*	</div>*/}
			{/*</div>*/}
			
			<Modal
				visible={exportVisible}
				centered
				title={<><span style={{ color: "#1e2b5d"}}>Room Name:  {exporttitle}</span></>}
				cancelText="Cancel"
				onCancel={handleCancel}
				// onOk={() => {
				// 	form
				// 		.validateFields()
				// 		.then((values) => {
				// 			form.resetFields();
				// 			SendICal(values);
				// 		})
				// 		.catch((info) => {
				// 			console.log('Validate Failed:', info);
				// 		});
				// }}
				width={1000}
			>
				<Row>
					<Col lg={20} md={20} sm={20} xs={20}>
						<p style={{fontSize:"17px"}}>Export Link: <a href={link} target="_blank">{link}</a></p>
					</Col>
					<Col lg={4} md={4} sm={4} xs={4}>
						<Tooltip title="Copy Link!">
							<i className="icon icon-copy" style={{float: "right", display: "block", fontSize: "25px", color: "green", cursor:"pointer"}} onClick={copy}/>
						</Tooltip>
					</Col>
				</Row>
			</Modal>
		</>
	);
}

export default DataExportModal;