import React, { useEffect, useState } from 'react'
import Timeline, { DateHeader, SidebarHeader, TimelineHeaders } from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'
import { Avatar, Image, message, Modal, Popover } from 'antd';
import { CisUI, GetBookingSourceImage } from "../../../../util/CISUI";
import axios from "axios";
import Config from "../../../../util/config";
import CircularProgress from '../../../../components/CircularProgress';
import axiosinstance from '../../../../util/Api';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import IntlMessages from '../../../../util/IntlMessages';
import DataImportModal from './DataImportModal';
import DataExportModal from './DataExportModal';

const ChannelManagerv2 = () => {
    const [rooms, setRooms] = useState([]);
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);

    const [isOpen, setIsOpen] = useState(false)
    // const [calendarData, ]
    const [calenderData, setCalenderData] = useState(null)
    console.log("🚀 ~ file: ChannelManagerv2.js:25 ~ ChannelManagerv2 ~ calenderData", calenderData)
    const localizer = momentLocalizer(moment);


    const getChannelManagerData = () => {
        setLoader(true);
        axios.get(Config.apiserver + 'channelmanagerdata')
            .then(res => {
                console.log(res.data)
                if (res.data.status === 1) {
                    setRooms(res.data.rooms)
                    setData(res.data.data)
                    setLoader(false);
                }
                setLoader(false);
            })
            .catch(error => {
                setLoader(false)
                console.log(error)
            })
    }

    console.log(rooms)

    useEffect(() => {
        getChannelManagerData()
    }, [])

    const groups = [];


    const [visible, setVisible] = useState(false);
    const [exportVisible, setExportVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [exportModalTitle, setExportModalTitle] = useState("");
    const [roomID, setRoomID] = useState(0);
    const [link, setLink] = useState("");


    const ExportData = (id) => {

        // document.getElementById("loader3").style.display = "block";
        axios.get(Config.apiserver + 'exportical/' + id, CisUI().HeaderRequest)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    setLink(res.data.url)
                    // CisUI().Notification('success', res.data.msg)
                    // isExportShow(false)
                }
                // else {
                // 	CisUI().Notification('error', res.data.msg)
                // }
                // document.getElementById("loader3").style.display = "none";
            })
            .catch(error => console.log(error))
    }

    const showExportModal = (id, name) => {
        console.log("🚀 ~ file: ChannelManagerv2.js:83 ~ showExportModal ~ id", id)
        // console.log(id);
        setExportVisible(true)
        setExportModalTitle(name)
        ExportData(id)
    }

    const showModal = (id, name) => {
        setVisible(true)
        setRoomID(id)
        setModalTitle(name)
    }

    const content = (room_id, room_name) => {
        return <div>
            <p onClick={() => showModal(room_id, room_name)} style={{ cursor: "pointer" }}>
                {/*<Link to={mprefix+prefix.edit+`/${id}`}>*/}
                <button className="btn btn-primary btn-circle" >
                    <i className="icon icon-upload" />
                </button> <IntlMessages id="Import" />
                {/*</Link>*/}
            </p>
            <p onClick={() => showExportModal(room_id, room_name)} style={{ cursor: "pointer" }}>
                <button className="btn btn-danger btn-circle">
                    <i className="icon icon-sent" />
                </button> <IntlMessages id="Export" />
            </p>
        </div>
    }

    const handleCalender = (roomID) => {
        setIsOpen(true)
        axiosinstance.get(`channelmanagercalendar/${roomID}`).then(res => {
            setCalenderData(res.data.data)
        }).catch(err => {
            message.error("Server Error!!!", err)
        })

    }
    rooms.forEach((room, index) => {
        groups.push({
            id: room.id,
            title: <div>

                <Popover placement="leftBottom" trigger="click" content={() => content(room.id, room.room_name)}>
                    <button className="btn btn-primary btn-circle"><i className="fas fa-ellipsis-v"></i></button>
                </Popover>

                <span style={{ cursor: "pointer" }} onClick={() => handleCalender(room.id)}>{room.room_name}</span></div>
        })
    })

    console.log(groups)


    const items = []
    data.forEach((item, index) => {
        items.push({
            id: item.id,
            group: item.room_id,
            source: item.source,
            title: item.source_name,
            start_time: moment(item.start_date),
            end_time: moment(item.end_date),
            color: "red",
            is_manual: item.is_manual,
            booking_details: item.booking_details
        })

    });
    const handleCallback = (childData) => {
        // console.log(childData);
        setVisible(childData)
    }

    const handleExportCallback = (childData) => {
        // console.log(childData);
        setExportVisible(childData)
    }


    const defaultTimeStart = moment().add(-5, 'day').toDate();
    const defaultTimeEnd = moment().add(1, 'month').toDate();

    const handleDetails = (details) => {
        console.log(details.property_name)
        return (
            <div>
                {/*<p style={{ fontSize: "17px", color: "#1e2b5d", marginBottom: "10px", textAlign:"center" }}>Booking Details</p>*/}
                <div style={{ marginLeft: "15px" }}>
                    <p style={{ margin: "10px 0 7px 0", color: "#1e2b5d", fontSize: "17px" }}>Booking Details</p>
                    <p>Booking ID: <span style={{ color: "#1e2b5d" }}>{details.unqid}</span></p>
                    <p style={{ margin: "5px 0" }}>From <span
                        style={{ color: "#1e2b5d" }}>{details.check_in_date}</span> To <span
                            style={{ color: "#1e2b5d" }}>{details.check_out_date}</span></p>
                    {/*<i className="icon icon-calendar" style={{ color:"#1e2b5d" }}/>*/}
                    <div style={{ display: "flex", margin: "0 0 5px 0" }}>
                        <p><span style={{ color: "#1e2b5d" }}>{details.guest}</span> Guest</p>
                        <p style={{ margin: "0 0 0 10px" }}><span
                            style={{ color: "#1e2b5d" }}>{details.nights}</span> Nights</p>
                    </div>
                </div>

                <div style={{ marginLeft: "15px" }}>
                    <p style={{ margin: "10px 0 7px 0", color: "#1e2b5d", fontSize: "17px" }}>Guest Details</p>
                    <p><i className="icon icon-user-o" style={{
                        color: "#1e2b5d",
                        verticalAlign: "middle",
                        padding: "0 5px 0 0"
                    }} /> {details.customer_name}</p>
                    <p><i className="icon icon-phone" style={{
                        color: "#1e2b5d",
                        verticalAlign: "middle",
                        padding: "0 5px 0 0"
                    }} /> {details.customer_phone}</p>
                    <p><i className="icon icon-email" style={{
                        color: "#1e2b5d",
                        verticalAlign: "middle",
                        padding: "0 5px 0 0"
                    }} /> {details.customer_email}</p>
                </div>

                <div style={{ marginLeft: "15px" }}>
                    <p style={{ margin: "10px 0 7px 0", color: "#1e2b5d", fontSize: "17px" }}>Property Information</p>
                    <p>Property Name: <span style={{ color: "#1e2b5d" }}>{details.property_name}</span></p>
                    <p>Room Name: <span style={{ color: "#1e2b5d" }}>{details.room_name}</span></p>
                </div>

                <div style={{ marginLeft: "15px" }}>
                    <p style={{ margin: "10px 0 7px 0", color: "#1e2b5d", fontSize: "17px" }}>Transaction History</p>
                    <p>Total: <span style={{ color: "#1e2b5d" }}>{CisUI().getCurrencyFormated1(details.total)}</span>
                    </p>
                    <p>Discount Amount: <span
                        style={{ color: "#1e2b5d" }}>{CisUI().getCurrencyFormated1(details.discount_amount)}</span>
                    </p>
                    <p>Grand Total: <span
                        style={{ color: "#1e2b5d" }}>{CisUI().getCurrencyFormated1(details.grand_total)}</span></p>
                </div>
            </div>


        );
    }


    return (
        loader ? <CircularProgress /> :
            <>
                <Timeline
                    groups={groups}
                    lineHeight={40}
                    items={items}
                    defaultTimeStart={defaultTimeStart}
                    defaultTimeEnd={defaultTimeEnd}
                    itemHeightRatio={0.75}
                    itemRenderer={({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {

                        const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
                        const backgroundColor = itemContext.selected ? 'green' : itemContext.dragging ? 'red' : '#1E2B5D'
                        const dayCount = moment(item.end_time).diff(item.start_time, 'days')
                        return (
                            <div
                                {...getItemProps({
                                    style: {
                                        backgroundColor,
                                        color: 'white',
                                        border: '1px solid white',
                                        borderRadius: '30px',
                                        padding: '5px 8px',
                                        width: '100%',
                                        display: 'flex',
                                    }
                                })}
                            >

                                {item?.is_manual === 1 ? (
                                    <div style={{ position: "absolute", top: `${dayCount > 2 ? '-2px' : '3px'}`, width: "100%", display: "flex", gap: "5px", alignItems: "center", justifyContent: "center", zIndex: 999 }}>
                                        <Avatar src={GetBookingSourceImage(item.source)} style={{ width: "20px", height: "20px", padding: "2px" }} />
                                        {dayCount > 2 ? <span>{item.title}</span> : null}
                                    </div>
                                ) : (
                                    <Popover title="Booking Details" content={handleDetails(item?.booking_details)} >
                                        <div style={{ position: "absolute", top: `${dayCount > 2 ? '-2px' : '3px'}`, width: "100%", display: "flex", gap: "5px", alignItems: "center", justifyContent: "center", zIndex: 999 }}>
                                            <Avatar src={GetBookingSourceImage(item.source)} style={{ width: "20px", height: "20px", padding: "2px" }} />
                                            {dayCount > 2 ? <span>{item.title}</span> : null}
                                        </div>
                                    </Popover>
                                )}


                                <div {...leftResizeProps} className="leftlogo" />
                                <div {...rightResizeProps} />
                            </div>
                            // )
                        )
                    }}

                >
                    <TimelineHeaders className="sticky">
                        <SidebarHeader>
                            {({ getRootProps }) => {
                                return <div {...getRootProps()} className="calender-timeline-left">
                                    <Image preview={false} src='/assets/images/logo.png' className='timeline-logo' alt='e2m' />
                                </div>;
                            }}
                        </SidebarHeader>
                        <DateHeader unit="primaryHeader" />
                        <DateHeader />
                        <DateHeader
                            unit="day"
                            labelFormat="ddd"
                            className='calender-date'
                            intervalRenderer={({ getIntervalProps, intervalContext }) => {
                                const { interval } = intervalContext
                                const { start, end } = interval
                                const isToday = moment().isBetween(start, end, 'day', '[]')
                                return (
                                    <div

                                        {...getIntervalProps({
                                            style: {
                                                backgroundColor: isToday ? '#1E2B5D' : 'white',
                                                color: isToday ? 'white' : '#1E2B5D',
                                                // border: '1px solid #1E2B5D',
                                                // borderRadius: '30px',
                                                padding: '6px 8px',
                                                width: '100%',

                                            }
                                        })}
                                    >
                                        {intervalContext.intervalText}
                                    </div>
                                )
                            }}
                        />


                    </TimelineHeaders>
                </Timeline>
                <Modal footer={null} title="Calender" open={isOpen} width="80%" onCancel={() => {
                    setIsOpen(false)
                    setCalenderData(null)
                }}>
                    {calenderData ? (
                        calenderData.length === 0 ? 'No Data Found..' : <Calendar
                        // components={{ event: Event }}
                        localizer={localizer}
                        events={calenderData}
                        step={60}
                        popup
                        
                    />
                    ) : <CircularProgress />}

                </Modal>

                <DataImportModal
                    visible={visible}
                    isShow={handleCallback}
                    title={modalTitle}
                    roomID={roomID}
                />

                <DataExportModal
                    exportVisible={exportVisible}
                    isExportShow={handleExportCallback}
                    exporttitle={exportModalTitle}
                    link={link}
                />
            </>
    )
}

export default ChannelManagerv2
