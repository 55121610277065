import React, {useEffect, useState} from "react";
import {Card, Tabs} from 'antd';
import jsonfile from './room_info.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import IntlMessages from "../../../../util/IntlMessages";
import GeneralInfo from "./general_info/GeneralInfo";
import Amenities from "./amenities/Amenities";
import PolicyInfo from "./policy/PolicyInfo";
import NewPrice from "./room_price/NewPrice";
import OtherInfo from "./others/OtherInfo";
import AddImages from "./images/AddImages";

const { TabPane } = Tabs;

const NewRoomInfo = (props) => {

    const [roomID, setRoomID] = useState(0);

    const handleCallback = (childData) =>{
        // console.log(childData);
        setRoomID(childData)
    }

    // console.log("Room ID:" + roomID);

    const userID = props.match.params.id;
    // console.log(userID)

    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1 )
    {
        Title = jsonfile.edittitle;
        redirectto = "../../"+jsonfile.urls.list;
        setRoomID(userID)
    }

    return (
        <Card title={<IntlMessages id={Title}/>} extra={
                isEdit === 1
                    ? CisUI().addAction(redirectto)
                    : CisUI().addAction(redirectto)
            }>

            <Tabs defaultActiveKey={1} style={{ marginBottom: 32 }}>
                <TabPane tab="General Info" key="1">
                    <GeneralInfo rmID={handleCallback} editId={userID}/>
                </TabPane>
                <TabPane tab="Amenities" key="2">
                    <Amenities rmID={roomID} />
                </TabPane>
                <TabPane tab="Policy Info" key="3">
                    <PolicyInfo rmID={roomID}/>
                </TabPane>
                <TabPane tab="Price" key="4">
                    <NewPrice rmID={roomID}/>
                </TabPane>
                <TabPane tab="Others Info" key="5">
                    <OtherInfo rmID={roomID}/>
                </TabPane>
                <TabPane tab="Images" key="6">
                    <AddImages rmID={roomID}/>
                </TabPane>
            </Tabs>

        </Card>
    );
};

export default NewRoomInfo;
