import React, {useState} from "react";
import {Form, Card, Col, Row, Button} from 'antd';
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import {useHistory} from "react-router-dom";
import SelectData from "../../../../util/selectdata";

const AddIcal = (props) => {

    const [state, setState] = useState({
        rows:[]
    });


    const handleAddRows = () => {
        let items = {
            source: "",
            url: ""
        };
        setState({
            rows: [...state.rows, items]
        })
    }

    const handleChange = (index) => e => {
        // console.log(index, e.target.value)
        const { name, value } = e.target;
        const list = [...state.rows];
        list[index][name] = value;
        // console.log(list)
        setState({
            rows: list
        })
    }

    const handleRowDelete = (i) => {
        let new_arr = state.rows.filter((item, index) => index !== i)

        setState({
            rows: new_arr
        })
    }

    let properties = SelectData('property/properties');
    let booking_source = SelectData('bookingsource');



    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {
        const data = {
            ...values,
            items: state.rows
        }
        // console.log(data);

        // if(state.rows.length > 0) {
        //     // console.log("Hello")
        //     document.getElementById("loader").style.display = "block";
        //     axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
        //         .then(res => {
        //             // console.log(res)
        //             if (res.data.status === 1) {
        //                 CisUI().Notification('success', res.data.msg);
        //                 history.push(redirectto);
        //             } else {
        //                 CisUI().Notification('error', res.data.msg);
        //             }
        //             document.getElementById("loader").style.display = "none";
        //         })
        //         .catch(errors => {
        //             console.log(errors);
        //         });
        // }
        // else {
        //     CisUI().Notification('warning', 'Please select at-least one date');
        // }
    };

    return (
        <Card>
            {CisUI().showLoading}
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            {/*<h2>Purchase Items</h2>*/}
                        </div>
                        <div className="col-md-6" style={{textAlign : "right"}}>
                            <a className="btn btn-primary" onClick={handleAddRows}>Add More</a>
                        </div>
                    </div>

                    <Form
                        form={form}
                        {...CisUI().formItemLayout}
                        name="input"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                        initialValues={{ remember: true }}
                    >
                        <table id="educationinfo" className="ptable table table-bordered">
                            <thead>
                            <tr>
                                <th></th>
                                <th>Source</th>
                                <th>URL</th>
                            </tr>
                            </thead>
                            <tbody>
                            {state.rows.map((item, idx) => (
                                <tr id="addr0" key={idx}>
                                    <td style={{width : '30px'}}>
                                        <a onClick={() => handleRowDelete(state.rows[idx].idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>
                                        {/*<Popconfirm title="Sure to delete?" onConfirm={handleRemoveRow(idx)}>*/}
                                        {/*    <a className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>*/}
                                        {/*</Popconfirm>*/}
                                    </td>
                                    <td>
                                        <select name="source" id="source" className="form-control"  onChange={handleChange(idx)} value={state.rows[idx].source}>
                                            <option value="">Select An Option</option>
                                            {
                                                booking_source.map((source, index) =>
                                                    <option value={source.id}>{source.name}</option>
                                                )
                                            }
                                        </select>
                                    </td>
                                    <td>
                                        <input type="text" placeholder="URL" className="form-control" name="url" value={state.rows[idx].url} onChange={handleChange(idx)} />
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                        <Row gutter={24}>
                            <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                                <Button type="info" onClick={() => history.goBack()} htmlType="back">Save & Submit</Button>
                            </Col>
                        </Row>

                    </Form>

                </div>
            </div>
        </Card>
    );
};

export default AddIcal;
