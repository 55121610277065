import React, {useEffect, useState} from "react";
import {Card, Tabs} from 'antd';
import jsonfile from './room_info.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import IntlMessages from "../../../../util/IntlMessages";
import GeneralInfo from "./general_info/GeneralInfo";
import EditAmenities from "./amenities/EditAmenities";
import EditPolicyInfo from "./policy/EditPolicyInfo";
import EditOtherInfo from "./others/EditOtherInfo";
import axios from "../../../../util/Api";
import Config from "../../../../util/config";
import EditImages from "./images/EditImages";
import EditPrice from "./room_price/EditPrice";
import EditGeneralInfo from "./general_info/EditGeneralInfo";
import CircularProgress from "../../../../components/CircularProgress";

const { TabPane } = Tabs;

const EditRoomInfo = (props) => {
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState([]);
    const [facility, setFacility] = useState({
        high_amenities: [],
        amenities: []
    });
    const [policy, setPolicy] = useState({
        room_policy: "",
        booking_policy: "",
        cancel_policy: "",
    });
    const [price, setPrice] = useState([]);
    const [other, setOther] = useState({
        details: ''
    });

    const [editImages, setEditImages] = useState([]);

    const userID = props.match.params.id;
    console.log(userID)

    const getEditData = () => {
        setLoading(true)
        axios.get(Config.apiserver + jsonfile.urls.view + `/${userID}`, CisUI().HeaderRequest)
            .then(res => {
                if(res.data.status === 1) {
                    console.log(res.data.data.edit_data);
                    setUserData(res.data.data.edit_data);
                    setFacility({
                        high_amenities: res.data.high_amenities,
                        amenities: res.data.amenities
                    })
                    setPolicy({
                        room_policy: res.data.data.edit_data.room_policy,
                        booking_policy: res.data.data.edit_data.booking_policy,
                        cancel_policy: res.data.data.edit_data.cancellation_policy,
                    })
                    setPrice(res.data.pricing);
                    setOther({
                        details: res.data.data.edit_data.details
                    });
                    setEditImages(res.data.data.images)
                }
                else {
                    CisUI().Notification('error', res.data.msg);
                }
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
                setLoading(false)
            })
    }

    useEffect(() => {
        getEditData()
    }, []);

    let Title = jsonfile.edittitle;
    let redirectto = "../../"+jsonfile.urls.list;


    return (
        <>
        {loading ? <CircularProgress />: ''}
        <Card title={<IntlMessages id={Title}/>} extra={
                CisUI().addAction(redirectto)
        }>

            <Tabs defaultActiveKey={1} style={{ marginBottom: 32 }}>
                <TabPane tab="General Info." key="1">
                    <EditGeneralInfo editId={userID} editData={userData}/>
                </TabPane>
                <TabPane tab="Amenities" key="2">
                    <EditAmenities editId={userID} editData={facility}/>
                </TabPane>
                <TabPane tab="Policy Info." key="3">
                    <EditPolicyInfo editId={userID} editData={policy}/>
                </TabPane>
                <TabPane tab="Price" key="4">
                    <EditPrice editId={userID} editData={price}/>
                </TabPane>
                <TabPane tab="Others Info" key="5">
                    <EditOtherInfo editId={userID} editData={other}/>
                </TabPane>
                <TabPane tab="Images" key="6">
                    <EditImages editId={userID} editData={editImages}/>
                </TabPane>
            </Tabs>

        </Card>
        </>
    );
};

export default EditRoomInfo;
