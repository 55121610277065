import React, {useState} from "react";
import {Form, Card, Col, Row, Button, Select} from 'antd';
import jsonfile from './images.json'
import {CisUI} from '../../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../../util/config";
import axios from "../../../../../util/Api";
import {useHistory} from "react-router-dom";
import IntlMessages from "../../../../../util/IntlMessages";
import CircularProgress from "../../../../../components/CircularProgress";

const { Option } = Select;

const AddImages = (props) => {

    const [image, setImage] = useState([]);
    const [src, setSource] = useState([]);
    const [error, setError] = useState();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const changeImage = (e) => {
        let file = e.target.files;

        let img = [];
        let source = [];
        if(file.length > 10){
            setError("Not more than 10 images");
        }
        else {
            for (let i = 0; i < file.length; i++) {
                source.push(URL.createObjectURL(e.target.files[i]))
                let reader = new FileReader();
                reader.onload = (e)=> {
                    img.push(e.target.result);
                };
                reader.readAsDataURL(file[i]);
            }
            // console.log(source)

            // file.forEach((item, i) => {
            //     // console.log(item)
            //     let reader = new FileReader();
            //     reader.onload = (e)=> {
            //         img.push(e.target.result);
            //     };
            //     reader.readAsDataURL(item);
            // })

            setSource(source)
            setImage(img);
        }
    }

    const removeImage = (e) => {
        console.log(e)
        setSource(
            src.filter((item, index) => item !== e)
        );
    }

    const userID = props.rmID;
    console.log(userID);

    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add+"/"+userID;
    let redirectto = "../property/roominfos";

    const history = useHistory();

    const onFinish = (values) => {
        // console.log(values);
        let data = {
            ...values,
            image:image
        }
        // console.log(data);
        if(userID > 0) {
            setLoading(true)
            axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
                .then(res => {
                    // console.log(res)
                    if (res.data.status === 1) {
                        CisUI().Notification('success', res.data.msg);
                        // history.push(redirectto);
                    } else {
                        CisUI().Notification('error', res.data.msg);
                    }
                    setLoading(false)
                })
                .catch(errors => {
                    console.log(errors);
                });
        }
        else {
            CisUI().Notification('error', "Please entry general info first");
        }
    };

    return (
        <>
            {loading? <CircularProgress />: ''}
            <Card title={<IntlMessages id={Title}/>}>
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                <Row gutter={24}>

                    <Col lg={24} md={24} sm={24} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name="image"
                                label="Image (maximum 10 images)"
                            >
                                <input type="file"  onChange={changeImage} multiple/>
                                {/*<img src={Config.profile_pic_url + userData.image} alt="Image" style={{ width: "120px", height: "100px", marginTop: "10px"}}/>*/}
                                {
                                    error !== '' && <p className="text-danger font-weight-bolder">{error}</p>
                                }
                                {/*<div className="row" style={{ marginTop: "10px" }}>*/}
                                {/*    {*/}
                                {/*        editImages?.map((item, index) =>*/}
                                {/*            <div className="col-md-2" key={index}>*/}
                                {/*                <img src={Config.property_info_image + item.image} alt="Image" style={{ width: "150px", height: "120px" }}/>*/}
                                {/*                <button type="button" className="btn btn-secondary btn-sm remove-btn" onClick={() =>deleteImg(item.id)}>x</button>*/}
                                {/*            </div>*/}
                                {/*        )*/}
                                {/*    }*/}
                                {/*</div>*/}

                                <div className="row" style={{ marginTop:"10px" }}>
                                    {
                                        src !== '' && src.map((item, index) =>
                                            <div className="col-md-2" key={index}>
                                                <img src={item} alt="Hello" style={{ width: "150px", height: "120px"}}/>
                                                <button className="btn btn-secondary btn-sm remove-btn" onClick={() => removeImage(item)}>x</button>
                                            </div>
                                        )
                                    }
                                </div>

                            </Form.Item>
                        </div>
                    </Col>

                </Row>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            <IntlMessages id={"Submit"} />
                        </Button>
                        <Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Back"} /></Button>
                    </Col>
                </Row>

            </Form>
        </Card>
        </>
    );
};

export default AddImages;
