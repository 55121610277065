import axios from "axios";

const token = localStorage.getItem("token");

const axiosinstance = axios.create({
    // baseURL: "http://localhost:8000/api/",
    baseURL: "https://e2msandbox.datalibrary.io/api/",
    // baseURL: 'http://192.168.1.109:8000/api/',
    headers: {
        "Content-Type": "application/json",
        Authorization: token,
    },
});

export default axiosinstance;
