import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import {
	NAV_STYLE_ABOVE_HEADER,
	NAV_STYLE_BELOW_HEADER,
	NAV_STYLE_DEFAULT_HORIZONTAL,
	NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";
import Config from "../../util/config";
import {CisUI} from "../../util/CISUI";
import axios from "../../util/Api";


// const SubMenu = Menu.SubMenu;
// const MenuItemGroup = Menu.ItemGroup;

const HorizontalNav = () => {

	const navStyle = useSelector(({settings}) => settings.navStyle);
	const {pathname} = useSelector(({common}) => common);

	const getNavStyleSubMenuClass = (navStyle) => {
		switch (navStyle) {
			case NAV_STYLE_DEFAULT_HORIZONTAL:
				return "gx-menu-horizontal gx-submenu-popup-curve";
			case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
				return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
			case NAV_STYLE_BELOW_HEADER:
				return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
			case NAV_STYLE_ABOVE_HEADER:
				return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
			default:
				return "gx-menu-horizontal";
		}
	};

	const selectedKeys = pathname.substr(1);
	const defaultOpenKeys = selectedKeys.split('/')[1];
	const[userMenu, setUserMenu] = useState([]);
	useEffect(() => {
		// console.log("Hello");
		getUserMenu();

	}, []);

	const getUserMenu = async () => {
		// const userData = localStorage.getItem("userData");
		// const dataparse = JSON.parse(userData);
		// const userId = dataparse.user_name || "";
		//const URL = Config.apiserver+"getusermenu/?username=superadmin";

		const URL = Config.apiserver+"getusermenuv2/?username="+CisUI().getUserInfo("user_name");
		await axios.get(URL)
			.then((response) => {
				console.log(response.data);
				if(response.status === 200) {
					setUserMenu(response.data.allmenu);
					// console.log(response.data.allmenu);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (

		<Menu
			defaultOpenKeys={[defaultOpenKeys]}
			selectedKeys={[selectedKeys]}
			mode="horizontal">

			<Menu.Item key="main/dashboard">
				<Link to="/main/dashboard">
					<i className="icon icon-home"/>
					{/*<span><IntlMessages id="sidebar.dashboard"/></span>*/}
				</Link>
			</Menu.Item>

			{
				userMenu.map((module, index) =>

					// <SubMenu key={index} popupClassName={getNavStyleSubMenuClass(navStyle)}
					//          title={<span> <i className={module.icon}/>
					//          <span><IntlMessages id={module.name}/></span></span>}>
					<Menu.SubMenu key={module.urlkey+"_"+module.id} popupClassName={getNavStyleSubMenuClass(navStyle)}
						// title={<span>{module.name}<i className="fas fa-chevron-down" style={{fontSize: '10px'}} /></span>}>
							 title={<span><IntlMessages id={module.name}/> <i className="fas fa-chevron-down" style={{fontSize: '10px'}} /></span>}>
						{
							module.submodule.map((submenu, index) => {
								if (submenu.menu.length === 0) {

									return <Menu.Item key={submenu.urlkey+"_"+submenu.id} id="customMenu">

										<Link to={ "/" + module.urlkey + '/' + submenu.urlkey}>
											<span><IntlMessages id={submenu.name}/></span>
											{/*{submenu.name}*/}
										</Link>
									</Menu.Item>

								} else {
									return <Menu.SubMenu key={submenu.urlkey+"_"+submenu.id}
													popupClassName={getNavStyleSubMenuClass(navStyle)}
													title={<IntlMessages id={submenu.name}/>}>

										{submenu.menu.map((menu, index) =>
											<Menu.Item key={menu.urlkey+"_"+menu.id} style={{ padding: "0!important"}} id="customMenu">

												<Link to={"/" + module.urlkey + "/" + menu.urlkey}>
													<IntlMessages id={menu.name} />
												</Link>
											</Menu.Item>
										)}
									</Menu.SubMenu>
								}

							})
						}

					</Menu.SubMenu>
				)
			}

		</Menu>

	);
};

HorizontalNav.propTypes = {};

export default HorizontalNav;

