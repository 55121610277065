module.exports = {
  footerText: 'All rights reserved by - Creative IT Soft © 2021',

 // baseurl: 'http://localhost:3000/',
 // apiserver: 'http://127.0.0.1:8000/api/',
  // apiserver: 'http://192.168.1.109:8000/api/',

   baseurl: 'https://e2madmin.datalibrary.io/',
   apiserver: 'https://e2msandbox.datalibrary.io/api/',
  //
  profile_pic_url: 'https://e2msandbox.datalibrary.io/api/upload/profile/',

  // property_info_image: 'http://localhost:8000/upload/property_images/',
  // room_image: 'http://localhost:8000/upload/room_images/',
  property_info_image: 'https://e2msandbox.datalibrary.io/public/upload/property_images/',
  room_image: 'https://e2msandbox.datalibrary.io/public/upload/room_images/',
  // customer_image: 'https://e2msandbox.datalibrary.io/public/upload/customer/',
  customer_image: 'https://e2msandbox.datalibrary.io/public/upload/profile/',
  owner_image: 'https://e2msandbox.datalibrary.io/public/upload/owner/',
  staff_image: 'https://e2msandbox.datalibrary.io/public/upload/staff/',

  // cms_page: 'http://localhost:8000/upload/cms/page/',
  // cms_banner: 'http://localhost:8000/upload/cms/banner/',
  // cms_blog: 'http://localhost:8000/upload/cms/blog/',

  cms_page: 'https://e2msandbox.datalibrary.io/public/upload/cms/page/',
  cms_banner: 'https://e2msandbox.datalibrary.io/public/upload/cms/banner/',
  cms_blog: 'https://e2msandbox.datalibrary.io/public/upload/cms/blog/',


  image_url: 'https://e2msandbox.datalibrary.io/public/',

  // image_url: 'http://localhost:8000/',
  stripe_pk: 'pk_test_51M1rkYKeMPNJBjfnyAWBbWjxSZWudZ83wo8yylnmKBQ4YVpwq8miBllQh7cNPnxaGYJMd1JOewYlx6cfws8rhrZi00fVm512wr',
  // stripe_pk: 'pk_live_51K9W10BSzlec9v53J7r4xK8IVTS203ZvepTqb2yLRAVVNK6j8aU9Q2fSxj26uZHAMVyh1YSELZQzdLSOhuBgvjH900WfmjSu6d',
  login_logo: '/assets/images/logo.png',
  topbar_logo: '/assets/images/logo1.png',
  login_bg: '/assets/images/logi_bg.png',
  loader: '/assets/images/loader_final.gif',
  mobile_login_bg: '/assets/images/mobile_loginbg.svg',
  print_logourl : '/assets/images/cop_nlogo.png',
  company_name : 'Creative IT Soft',
  address : 'Wayne, New Jersey, USA',
  city : 'Wayne',
  state : 'New Jersey',
  zipcode : '11111',
  phone : "",
  email : "info@copusa.org",
  website : "https://copusa.org"
}
