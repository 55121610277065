import React from "react";
import {Route, Switch} from "react-router-dom";

import MyAccount from "../../containers/yotta/profile_setting/MyAccount";
import ChangePassword from "../../containers/yotta/profile_setting/ChangePassword";

const HRM = ({match}) => (
    <Switch>
        <Route path={`${match.url}/myaccount`} component={MyAccount}/>
        <Route path={`${match.url}/changepassword`} component={ChangePassword}/>
    </Switch>
);

export default HRM;
