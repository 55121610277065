import React from "react";
import {Card, Col} from 'antd';
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";

const TaskStaffs = (props) => {
    let staffs = props.data

    return (
        <>
        {
            staffs.map((staff, index) =>
                <Col lg={5} md={5} sm={8} xs={8} style={{ marginTop:"15px"}}>
                    <Card style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.08)" }} className="staff_task_card">
                        <div style={{ display: "flex"}}>
                            {
                                staff.image !== null ? <img src={Config.staff_image + staff.image} alt="" style={{ width:"60px", height:" 60px", borderRadius:"50%", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}/>
                                    :
                                    <div style={{ width: "60px", height:"60px", borderRadius:"50%", background: "#aaa", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}></div>
                            }

                            <div style={{ textAlign: "center", margin: "15px 0 15px 10px" }}>
                                <p style={{ fontWeight:"bold", fontSize: "14px", color: "#1E2B5D" }}>{staff.name}</p>
                                <p style={{ fontWeight:"bold", fontSize: "14px", color: "#aaa"}}>{staff.phone}</p>
                            </div>
                        </div>
                    </Card>
                </Col>
            )
        }
        </>
    );
};

export default TaskStaffs;