import { ReloadOutlined } from '@ant-design/icons';
import { Button, Form, Select } from 'antd';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import axios from 'axios';


const SelectInput = ({ name = "", label = "", requiredMsg = "", staticData = [], initialValue = null, placeholder = "", required = false, path = "", mode = '', ...arg }) => {

    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const intl = useIntl();

    useEffect(() => {
        if(path !== ""){
            setLoading(true);
            axios.get(path, {
                headers: {
                    "authorization": localStorage.getItem('token')
                }
            })
                .then(res => {
                    setLoading(false);
                    setIsError(false)
                    setData(res?.data?.data)
                })
                .catch(err => {
                    setLoading(false);
                    setIsError(true)
            })
        }else{
            setData(staticData);
        }
    }, [path])

    return (
        initialValue ?
        <Form.Item
        label={label === "" ? "" : intl.formatMessage({ id: label })}
        name={name}
        rules={[{ required: required, message: requiredMsg === "" ? "" : intl.formatMessage({ id: requiredMsg }) }]}
        initialValue={initialValue}
        >
            <Select
                allowClear={true}
                showSearch={data.length && true}
                placeholder={ initialValue === null ? placeholder === "" ? "" : intl.formatMessage({ id: placeholder }) : null }
                style={{ width: "100%" }}
                loading={loading}
                filterOption={(input, option) =>
                    option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                }
                mode={mode !== '' && mode}
                {...arg}
            >
                {
                    isError &&
                    <Select.Option style={{ textAlign: "center" }}>
                        <Button
                            type="link"
                            icon={<ReloadOutlined />}
                            onClick={() => window.location.reload()}
                            style={{ width: '100%', display: ' flex', alignItems: 'center' }}
                        >
                            {intl.formatMessage({ id: "Something went wrong" })}
                        </Button>
                    </Select.Option>
                }
                {
                    data.length && data.map(item => <Select.Option key={item?.id} value={item.id} >{item.name}</Select.Option>)
                }

            </Select>
        </Form.Item> :
        <Form.Item
            label={label === "" ? "" : intl.formatMessage({ id: label })}
            name={name}
            rules={[{ required: required, message: requiredMsg === "" ? "" : intl.formatMessage({ id: requiredMsg }) }]}
        >
            <Select
                    allowClear={true}
                    showSearch={data.length && true}
                    placeholder={ initialValue === null ? placeholder === "" ? "" : intl.formatMessage({ id: placeholder }) : null }
                    style={{ width: "100%" }}
                    loading={loading}
                    filterOption={(input, option) =>
                        option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                    }
                    mode={mode !== '' && mode}
                    {...arg}
                >
                    {
                        isError &&
                        <Select.Option style={{ textAlign: "center" }}>
                            <Button
                                type="link"
                                icon={<ReloadOutlined />}
                                onClick={() => window.location.reload()}
                                style={{ width: '100%', display: ' flex', alignItems: 'center' }}
                            >
                                {intl.formatMessage({ id: "Something went wrong" })}
                            </Button>
                        </Select.Option>
                    }
                    {
                        data.length && data.map(item => <Select.Option key={item?.id} value={item.id} >{item.name}</Select.Option>)
                    }
    
                </Select>
            </Form.Item>
    )
}

export default SelectInput