import React, {useEffect, useState} from "react";
import {Form, Card, Col, Row, Button, Collapse} from 'antd';
import jsonfile from './policy.json'
import {CisUI} from '../../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../../util/config";
import axios from "../../../../../util/Api";
import {useHistory} from "react-router-dom";
import IntlMessages from "../../../../../util/IntlMessages";
import CKEditor from "react-ckeditor-component";
import CircularProgress from "../../../../../components/CircularProgress";

const {Panel} = Collapse;
const EditPolicyInfo = (props) => {
    const [roomPolicy, setRoomPolicy] = useState("");
    const [bookingPolicy, setBookingPolicy] = useState("");
    const [cancelPolicy, setCancelPolicy] = useState("");
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const handleRoomChange = (evt) => {
        var newContent = evt.editor.getData();
        setRoomPolicy(newContent)
    }

    const handleBookingChange = (evt) => {
        var newContent = evt.editor.getData();
        setBookingPolicy(newContent)
    }

    const handleCancelChange = (evt) => {
        var newContent = evt.editor.getData();
        setCancelPolicy(newContent)
    }


    const editId = props.editId;
    let editdata = props.editData;

    let Title = jsonfile.edittitle;
    let endpoint = jsonfile.urls.edit+"/"+editId;
    let redirectto = "../../property/roominfos";

    useEffect(() => {
        setRoomPolicy(editdata.room_policy);
        setBookingPolicy(editdata.booking_policy);
        setCancelPolicy(editdata.cancel_policy);
    }, [])


    // console.log(userData);

    const history = useHistory();

    const onFinish = (values) => {
        // console.log(values);
        let data = {
            ...values,
            room_policy:roomPolicy,
            booking_policy:bookingPolicy,
            cancellation_policy:cancelPolicy,

        }
        // console.log(data);
        setLoading(true)
        axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg);
                    // document.getElementById("loader").style.display = "none";
                }
                else {
                    CisUI().Notification('error', res.data.msg);
                    // document.getElementById("loader").style.display = "none";
                }
                setLoading(false)
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    return (
        <>
            {loading ? <CircularProgress /> : ''}
            <Card title={<IntlMessages id={Title}/>}>

            <div id="loader2" className="loading_view" style={{display: 'none'}}>
                <div className="loading_center">
                    <div className="loader-table">
                        <img style={{background : 'transparent'}} src={Config.loader} />
                    </div>
                    <h4 style={{textAlign: 'center'}}>Please wait...</h4>
                </div>
            </div>

            <Form
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                <Collapse defaultActiveKey={['1']}>
                    <Panel header="Room Policy" key="1">
                        <Form.Item
                            name="room_policy"
                        >
                            <CKEditor
                                activeClass="p10"
                                content={roomPolicy}
                                events={{
                                    "change": handleRoomChange
                                }}
                            />
                        </Form.Item>
                    </Panel>
                    <Panel header="Booking Policy" key="2">
                        <Form.Item
                            name="booking_policy"
                        >
                            <CKEditor
                                activeClass="p10"
                                content={bookingPolicy}
                                events={{
                                    "change": handleBookingChange
                                }}
                            />
                        </Form.Item>
                    </Panel>
                    <Panel header="Cancellation Policy" key="3">
                        <Form.Item
                            name="cancellation_policy"
                        >
                            <CKEditor
                                activeClass="p10"
                                content={cancelPolicy}
                                events={{
                                    "change": handleCancelChange
                                }}
                            />
                        </Form.Item>
                    </Panel>
                </Collapse>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            <IntlMessages id={"Submit"} />
                        </Button>
                        <Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Back"} /></Button>
                    </Col>
                </Row>

            </Form>
        </Card>
        </>
    );
};

export default EditPolicyInfo;
