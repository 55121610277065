const folders = [
    {
        id: 0,
        handle: "inbox",
        title: "Inbox",
        icon: "inbox",
    },
    {
        id: 1,
        handle: "sent",
        title: "Sent",
        icon: "sent",
    },
    // {
    //     id: 2,
    //     handle: "drafts",
    //     title: "Drafts",
    //     icon: "draft",
    // },
    // {
    //     id: 3,
    //     handle: "spam",
    //     title: "Spam",
    //     icon: "spam",
    // },
    {
        id: 4,
        handle: "trash",
        title: "Trash",
        icon: "trash",
    },
];

export default folders;
