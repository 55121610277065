import React, {useEffect, useState} from "react";
import {Form, Card, Row, Col, Button} from 'antd';
import jsonfile from './sitesetup.json'
import {CisUI} from '../../../../util/CISUI'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import IntlMessages from "../../../../util/IntlMessages";
import InputFieldV2 from "../../../../util/InputFieldV2";

const NewSiteSetup = (props) => {
    const [ck, setCK] = useState("");
    const [image, setImage] = useState("");
    const [form] = Form.useForm();
    const history = useHistory();

    const [state, setState] = useState({
        rows:[{
            id:"",
            link_name: "",
            url: ""
        }]
    });

    const changeImage = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = (e)=> {
            setImage({
                image : e.target.result
            });
        };
        reader.readAsDataURL(file);
    }

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;
    let links = [];
    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        // links = userData['social_links'];
        let data = userData.social_links;
        console.log(data)


        data?.map((item, index) =>
            links.push({
                id:item.id,
                link_name: item.link_name,
                url: item.url
            })
        )

        console.log(links)

        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }

    useEffect(() => {
        if(isEdit === 1){
            setState({
                rows: links
            })
        }
    }, [userData]);



    const onFinish = (values) => {
        let data = {
            ...values,
            image: image,
            body: ck,
            social_links: state.rows
        }
        // console.log(data);
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, data)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg);
                    history.push(redirectto);
                }
                else {
                    CisUI().Notification('error', res.data.msg);
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };



    const handleAddRows = () => {
        let items = {
            id:"",
            link_name: "",
            url:""
        };
        setState({
            rows: [...state.rows, items]
        })
    }

    const handleAddChange = (id) => e => {
        console.log(id, e.target.value)
        const { name, value } = e.target;
        const list = [...state.rows];
        list[id][name] = value;
        console.log(list)
        setState({
            rows: list
        })
    }

    const handleRowDelete = (i) => {

        // console.log(i)
        if(state.rows.length === 1) {
            CisUI().Notification('warning', 'You can not delete this item.')
        }
        else {
            let new_arr = state.rows.filter((item, index) => index !== i)

            setState({
                rows: new_arr
            })
        }
    }

    return (
        <Card title={<IntlMessages id={Title}/>} extra={
                isEdit === 1
                    ? CisUI().addAction(redirectto)
                    : CisUI().addAction(redirectto)
            }>
            {CisUI().showLoading}
            <Form
                // form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                {/*{InputFields(jsonfile.input,userData,isEdit,props)}*/}
                {InputFieldV2(jsonfile.input,userData,isEdit,props, '', redirectto)}

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", flexDirection: "row" }}>
                            <Col lg={12} md={12} sm={24} xs={24}>
                                <h4>Social Link</h4>
                            </Col>
                            {/*<Col lg={12} md={12} sm={24} xs={24} style={{textAlign : "end"}}>*/}
                            {/*    <Button onClick={() => handleAddRows()}>*/}
                            {/*        Add field*/}
                            {/*    </Button>*/}
                            {/*</Col>*/}
                        </div>
                        <table id="educationinfo" className="ptable table table-bordered">
                            <thead>
                                <tr>
                                    {/*<th>Action</th>*/}
                                    <th>Source</th>
                                    <th>URL</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                state.rows.map((item, idx) => (
                                    <tr id="addr0" key={idx}>
                                        {/*<td style={{width : '30px'}}>*/}
                                        {/*    <a onClick={() => handleRowDelete(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>*/}
                                        {/*</td>*/}
                                        <td>
                                            <select name="link_name" id="link_name" className="form-control"  onChange={handleAddChange(idx)} value={item.link_name}>
                                                <option value="">Select An Option</option>
                                                <option value="facebook">Facebook</option>
                                                <option value="twitter">Twitter</option>
                                                <option value="instagram">Instagram</option>
                                                <option value="youtube">YouTube</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input type="text" placeholder="URL" className="form-control" name="url" value={item.url} onChange={handleAddChange(idx)}/>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </Col>


                    <Col lg={8} md={8} sm={24} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name="image"
                                label="Image"
                            >
                                <input type="file" onChange={changeImage}/>
                                {
                                    isEdit === 1 && (
                                        <img src={Config.image_url + userData.image} alt="Image" style={{ width: "120px", height: "100px", marginTop: "10px"}}/>
                                    )
                                }

                            </Form.Item>
                        </div>
                    </Col>

                </Row>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            <IntlMessages id={"Submit"} />
                        </Button>
                        {/*<Button type="info" onClick={() => history.goBack()} htmlType="back"><IntlMessages id={"Cancel"} /></Button>*/}
                        {/*<Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Cancel"} /></Button>*/}
                        <Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Back"} /></Button>
                    </Col>
                </Row>

            </Form>
        </Card>
    );
};

export default NewSiteSetup;
