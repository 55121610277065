import React, { useState} from "react";
import {Form, Card, Col, Row, Button, Select, Collapse} from 'antd';
import jsonfile from './policy.json'
import {CisUI} from '../../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../../util/config";
import axios from "../../../../../util/Api";
import {useHistory} from "react-router-dom";
import IntlMessages from "../../../../../util/IntlMessages";
import CKEditor from "react-ckeditor-component";
import CircularProgress from "../../../../../components/CircularProgress";

const { Option } = Select;
const {Panel} = Collapse;
const PolicyInfo = (props) => {
    const [loading, setLoading] = useState(false);
    const [roomPolicy, setRoomPolicy] = useState("");
    const [bookingPolicy, setBookingPolicy] = useState("");
    const [cancelPolicy, setCancelPolicy] = useState("");
    const [form] = Form.useForm();

    const handleRoomChange = (evt) => {
        var newContent = evt.editor.getData();
        setRoomPolicy(newContent)
    }

    const handleBookingChange = (evt) => {
        var newContent = evt.editor.getData();
        setBookingPolicy(newContent)
    }

    const handleCancelChange = (evt) => {
        var newContent = evt.editor.getData();
        setCancelPolicy(newContent)
    }

    const userID = props.rmID;

    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add+"/"+userID;
    let redirectto = "../property/roominfos";

    // console.log(userData);

    const history = useHistory();

    const onFinish = (values) => {
        // console.log(values);
        let data = {
            ...values,
            room_policy:roomPolicy,
            booking_policy:bookingPolicy,
            cancellation_policy:cancelPolicy,

        }
        // console.log(data);
        if( userID > 0) {
            setLoading(true)
            axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
                .then(res => {
                    // console.log(res)
                    if (res.data.status === 1) {
                        CisUI().Notification('success', res.data.msg);
                        // history.push(redirectto);
                    } else {
                        CisUI().Notification('error', res.data.msg);
                    }
                    setLoading(false)
                })
                .catch(errors => {
                    console.log(errors);
                });
        }
        else {
            CisUI().Notification('error', "Please entry general info first");
        }
    };

    return (
        <>
            { loading ? <CircularProgress /> : ''}
            <Card title={<IntlMessages id={Title}/>}>

                <Form
                    // form={form}
                    {...CisUI().formItemLayout}
                    name="input"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                    initialValues={{ remember: true }}
                >
                    {/*<Row gutter={24}>*/}
                        {/*<Col lg={6} md={6} sm={24} xs={24}>*/}
                            {/*<div className="gx-form-row0">*/}
                            {/*    <Form.Item*/}
                            {/*        name="policy_id"*/}
                            {/*        label="Policy"*/}
                            {/*    >*/}

                            {/*        <Select showSearch*/}
                            {/*                placeholder="Select An Option"*/}
                            {/*                optionFilterProp="children"*/}
                            {/*                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}*/}
                            {/*                onSelect={getPolicyData}*/}
                            {/*        >*/}
                            {/*            {*/}
                            {/*                policies.map((item, index) =>*/}
                            {/*                    <Option key={index} value={item.id}>{item.name}</Option>*/}
                            {/*                )*/}
                            {/*            }*/}
                            {/*        </Select>*/}
                            {/*    </Form.Item>*/}

                            {/*</div>*/}
                        {/*</Col>*/}
                        {/*<Col lg={24} md={24} sm={24} xs={24}>*/}
                            {/*<div className="gx-form-row0">*/}
                                {/*<p>Booking Policy</p>*/}
                                {/*<Form.Item*/}
                                {/*    name="policy_details"*/}
                                {/*    label="Policy Details"*/}
                                {/*>*/}
                                {/*    <CKEditor*/}
                                {/*        activeClass="p10"*/}
                                {/*        content={ck}*/}
                                {/*        events={{*/}
                                {/*            "change": handleChange*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*</Form.Item>*/}
                                <Collapse defaultActiveKey={['1']}>
                                    <Panel header="Room Policy" key="1">
                                        <Form.Item
                                            name="room_policy"
                                        >
                                            <CKEditor
                                                activeClass="p10"
                                                content={roomPolicy}
                                                events={{
                                                    "change": handleRoomChange
                                                }}
                                            />
                                        </Form.Item>
                                    </Panel>
                                    <Panel header="Booking Policy" key="2">
                                        <Form.Item
                                            name="booking_policy"
                                        >
                                            <CKEditor
                                                activeClass="p10"
                                                content={bookingPolicy}
                                                events={{
                                                    "change": handleBookingChange
                                                }}
                                            />
                                        </Form.Item>
                                    </Panel>
                                    <Panel header="Cancellation Policy" key="3">
                                        <Form.Item
                                            name="cancellation_policy"
                                        >
                                            <CKEditor
                                                activeClass="p10"
                                                content={cancelPolicy}
                                                events={{
                                                    "change": handleCancelChange
                                                }}
                                            />
                                        </Form.Item>
                                    </Panel>
                                </Collapse>,
                            {/*</div>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}

                    <Row gutter={24}>
                        <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                            <Button type="primary" htmlType="submit">
                                <IntlMessages id={"Submit"} />
                            </Button>
                            <Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Back"} /></Button>
                        </Col>
                    </Row>

                </Form>
            </Card>
        </>
    );
};

export default PolicyInfo;
