import React, { useState } from "react";
import { Form, Col, Input, Collapse, Card } from 'antd';
import jsonfile from './user_roles.json'
import { CisUI } from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import ActionButton from '../../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";

// const Search = Input.Search;
const Panel = Collapse.Panel;

const LoginHistory = () => {
    // const value = 0;
    const headers = {
        // _id: {
        //     text: '#',
        //     invisible: false,
        //     filterable: false,
        //     transform: (value) => `${++value}`,
        // },
        id: {
            invisible: true
        },
        name: {
            text: <IntlMessages id="Name" />,
            sortable: true,
            filterable: true,
        },
        roles: {
            invisible: true
        },
        description: {
            text: <IntlMessages id="Description" />,
            sortable: true,
            filterable: true,
        },
        created: {
            text: <IntlMessages id="Created" />,
            sortable: true,
            filterable: true,
        },
        username: {
            text: <IntlMessages id="Created By" />,
            sortable: true,
            filterable: true,
        },
        status: {
            text: <IntlMessages id="Status" />,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getStatusLabel(value)
            )
        },
        actions: {
            text: <IntlMessages id="Actions" />,
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {ActionButton(row.id, jsonfile.urls, jsonfile.urls.list)}
                </>
            ),
        },
    };

    const orderedHeaders = jsonfile.listView.title;

    // const getFields = () => {
    //     const count = expand ? 10 : 6;
    //     const children = [];
    //     const inputs = jsonfile.input;

    //     inputs.map((list, index) => {
    //         if (list.isFilterable == 1) {
    //             children.push(
    //                 <Col lg={6} md={6} sm={12} xs={24} key={index} style={{ display: index < count ? 'block' : 'none' }}>
    //                     <div className="gx-form-row0">
    //                         <Form.Item
    //                             name={`${list.name}`}
    //                             label={`${list.label}`}
    //                             rules={[
    //                                 {
    //                                     required: true,
    //                                     message: "",
    //                                 },
    //                             ]}
    //                         >
    //                             <Input placeholder={list.name} />
    //                         </Form.Item>
    //                     </div>
    //                 </Col>,
    //             );
    //         }
    //     });
    //     return children;
    // };

    // const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        console.log('Received values of form: ', values);
    };


    let filterValue = "";

    // const handleOnFilter = ({ target: { name, value } }) => {
    //     //filterValue = value;
    //     console.log("filteringwith :" + value);
    // }

    let searchView = "";
    if (jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header={<IntlMessages id="Advance Search" />} key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listActionNoReport("../" + jsonfile.urls.add)}
        </>
    );

    return (
        <>
            {searchView}
            <Card title={<IntlMessages id={jsonfile.listtitle} />} extra={getExtra}>

                <SmartDataTable
                    name='test-fake-table'
                    data={Config.apiserver + "user/allroles"}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
        </>
    );
};

export default LoginHistory;
