import React, {useEffect, useState} from "react";
import {Form, Card, Col, Row, Button, Select, Input, DatePicker, Divider, Checkbox, Radio, Image} from 'antd';
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import {useHistory} from "react-router-dom";
import IntlMessages from "../../../../util/IntlMessages";

const {RangePicker} = DatePicker;
const {TextArea} = Input;
const {Option} = Select;

const RoomInformation = (props) => {

    return (
        <Row>
            <Col lg={24} md={24} sm={24} xs={24}  style={{ marginTop:"15px"}}>
                <p style={{ fontSize: "14px" }}><i className="icon icon-company"/> Room Name: <span style={{ fontWeight:"bold", color: "#1E2B5D"}}>{props.data.room_name}</span></p>
            </Col>
            <Col lg={24} md={24} sm={24} xs={24}  style={{ marginTop:"15px"}}>
                <p style={{ fontSize: "14px" }}><i className="icon icon-phone"/> Phone Number: <span style={{ fontWeight:"bold", color: "#1E2B5D"}}>{props.data.room_phone}</span></p>
            </Col>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop:"15px"}}>
                <p style={{ fontSize: "14px" }}><i className="icon icon-email"/> Email: <span style={{ fontWeight:"bold", color: "#1E2B5D"}}>{props.data.room_email}</span></p>
            </Col>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop:"15px"}}>
                <p style={{ fontSize: "14px" }}><i className="icon icon-home"/> Address: <span style={{ fontWeight:"bold", color: "#1E2B5D"}}>{props.data.room_address}</span></p>
            </Col>
        </Row>
    );
};

export default RoomInformation;