import React from "react";
import {Route, Switch} from "react-router-dom";

import BookingDateList from "../../containers/yotta/booking_date/BookingDateList";
import NewBookingDate from "../../containers/yotta/booking_date/NewBookingDate";

const BookingDate = ({match}) => (
    <Switch>
        <Route path={`${match.url}/newbookingdate`} component={NewBookingDate}/>
        <Route path={`${match.url}/bookingdates`} component={BookingDateList}/>
        {/*<Route path={`${match.url}/bookingreqdetails/:id`} component={BookingReqDetails}/>*/}
    </Switch>
);

export default BookingDate;
