import React, {useEffect, useState} from "react";
import {Form, Card, Col, Row, Button, Checkbox, Select, Divider} from 'antd';
import jsonfile from './general_info.json'
import {CisUI} from '../../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../../util/config";
import axios from "../../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../../util/gerremotedata";
import IntlMessages from "../../../../../util/IntlMessages";
import InputFieldV2 from "../../../../../util/InputFieldV2";
import StaticSelectData from "../../../../../util/StaticSelectData";
import CircularProgress from "../../../../../components/CircularProgress";

const { Option } = Select;

const GeneralInfo = (props) => {
    const [form] = Form.useForm();
    const [isShow, setIsShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState("");
    const userID = props.editId;
    let priorities = StaticSelectData("priority");
    let display_priorities = StaticSelectData("display_priority");

    const changeImage = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = (e)=> {
            setImage({
                image : e.target.result
            });
        };
        reader.readAsDataURL(file);
    }

    const handleFeatured = (e) => {
        if(e.target.checked === true){
            setIsShow(true)
        }
        else {
            setIsShow(false)
        }
    }

    let userData = [];
    // console.log("Userid : "+props.match.params.id);

    userData = props.editData;
    let Title = jsonfile.edittitle;
    let endpoint = jsonfile.urls.edit+"/"+userID;
    let redirectto = "../../"+jsonfile.urls.list;

    // console.log(userData);

    useEffect(() => {
        if(userData.is_featured === 1){
            // console.log("hello")
            setIsShow(true)
        }
    },[userData])

    const history = useHistory();

    const onFinish = (values) => {
        console.log(values);
        let data = {
            ...values,
            'featured_image': image['image'],
            'check_in': values['check_in'].format('HH:mm:ss'),
            'check_out': values['check_out'].format('HH:mm:ss'),
        }
        // console.log("ok");
        setLoading(true)
        axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg);
                    // history.push(redirectto);
                    // props.rmID(res.data.room_id)
                    // document.getElementById("loader").style.display = "none";
                }
                else {
                    CisUI().Notification('error', res.data.msg);
                }
                setLoading(false)
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    const deleteImage = (e) => {
        e.preventDefault();
        axios.delete(Config.apiserver + 'property/deleteimage/' + userID +"?featured_image=1", CisUI().HeaderRequest)
            .then(res => {
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg);
                }
                else {
                    CisUI().Notification('error', res.data.msg);
                }
            })
            .catch(error => console.log(error))
    }

    return (
        <>
            {loading? <CircularProgress />: ''}
            <Card title={<IntlMessages id={Title}/>}>

            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                {InputFieldV2(jsonfile.input,userData, 1,props, '', redirectto)}

                <Row>
                    {
                        userData != '' && (
                            <Col lg={8} md={8} sm={24} xs={24}>
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="display_priority"
                                        label="Display Priority"
                                        rules={[
                                            {
                                                required: isShow === true ? true : false,
                                                message: "Display priority is required"
                                            }
                                        ]}
                                        initialValue={ userData.display_priority }
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select An Option"
                                            optionFilterProp="children"
                                            allowClear={true}
                                            loading={display_priorities.length > 0 ? false : true}
                                        >
                                            {
                                                display_priorities.map((item,index) =>
                                                    <Option value={item.id}>{item.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </Col>
                        )
                    }
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name="image"
                                label="Featured Image"
                            >
                                <input type="file" onChange={changeImage}/>
                                {/*{*/}
                                {/*    userData.featured_image !== '' && (*/}
                                {/*        <img src={Config.image_url + '/upload/room_featured_image/'+ userData.featured_image} alt="Image" style={{ width: "120px", height: "100px", marginTop: "10px"}}/>*/}
                                {/*    )*/}
                                {/*}*/}
                                {
                                    userData.featured_image !== '' && userData.featured_image !== null && (
                                        <div className="row" style={{ marginTop:"10px" }}>
                                            <div className="col-md-24">
                                                <img src={Config.image_url + '/upload/room_featured_image/'+ userData.featured_image} alt="Image" style={{ width: "150px", height: "120px" }}/>
                                                <button className="btn btn-secondary btn-sm remove-btn" onClick={(e) => deleteImage(e)}>x</button>
                                            </div>
                                        </div>
                                    )
                                }

                            </Form.Item>
                        </div>
                    </Col>
                </Row>

                <Divider orientation="left">Featured</Divider>
                <Row>
                    {
                        userData != '' && (
                            <Col lg={8} md={8} sm={12} xs={12}>
                                <Form.Item
                                    name="is_featured"
                                    label="Is Featured"
                                    initialValue={userData.is_featured === 1}
                                    valuePropName="checked"
                                >
                                    <Checkbox onChange={handleFeatured} />
                                </Form.Item>
                            </Col>
                        )
                    }

                    {
                        isShow === true && (
                            <Col lg={8} md={8} sm={12} xs={12}>
                                <Form.Item
                                    name="priority"
                                    label="Featured Priority"
                                    rules={[
                                        {
                                            required: isShow === true ? true : false,
                                            message: "Display priority is required"
                                        }
                                    ]}
                                    loading={priorities.length > 0 ? false : true}
                                    initialValue={ userData.priority }
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select An Option"
                                        optionFilterProp="children"
                                    >
                                        {
                                            priorities.map((item,index) =>
                                                <Option value={item.id}>{item.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        )
                    }

                </Row>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            <IntlMessages id={"Submit"} />
                        </Button>
                        <Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Back"} /></Button>
                    </Col>
                </Row>

            </Form>
        </Card>
        </>
    );
};

export default GeneralInfo;
