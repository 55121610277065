import React, {useEffect, useState} from "react";
import {Form, Card, Col, Radio, Row, Input, Button, Select} from 'antd';
import jsonfile from './templates.json'
import {CisUI} from '../../../../util/CISUI'
import InputFields from '../../../../util/InputField'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import IntlMessages from "../../../../util/IntlMessages";
import CKEditor from "react-ckeditor-component";
import CircularProgress from "../../../../components/CircularProgress";

const {Option} = Select;

const NewTemplate = (props) => {
    const userID = props.match.params.id;
    const [ck, setCK] = useState("");
    const [form] = Form.useForm();
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    let userData = [];
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    const handleChange = (evt) => {
        // console.log(evt.editor);
        var newContent = evt.editor.getData();
        setCK(newContent)
    }

    let body = "";
    if(isEdit === 1 ) {

        userData = RemoteJson(jsonfile.urls.view+"/"+userID);

        form.setFieldsValue({
            name: userData['name'],
            description: userData['description'],
            subject: userData['subject'],
            status: userData['status']
        })
        body = userData['body'];

        // console.log(userData)
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }

    useEffect(() => {
        setCK(body)
    }, [body]);


    const onFinish = (values) => {

        let data = {
            ...values,
            body: ck
        }
        setLoader(true)
        axios.post(Config.apiserver + endpoint, data)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg);
                    history.push(redirectto);
                }
                else {
                    CisUI().Notification('error', res.data.msg);
                }
                setLoader(false)
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    return (
        loader ? <CircularProgress /> :
        <Row justify="center">
            <Col lg={16} md={16} sm={24} xs={24}>
                <Card title={<IntlMessages id={Title}/>} extra={
                    isEdit === 1
                        ? CisUI().addAction(redirectto)
                        : CisUI().addAction(redirectto)
                }>
                    <Form
                        form={form}
                        {...CisUI().formItemLayout}
                        name="input"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                        initialValues={{ remember: true }}
                    >
                        <Row gutter={24}>

                            <Col lg={8} md={8} sm={24} xs={24}>
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="name"
                                        label="Name"
                                        rules={[{
                                            required: true,
                                            message: "Name is required"
                                        }]}
                                    >
                                        <Input placeholder="Template name"/>
                                    </Form.Item>
                                </div>
                            </Col>

                            <Col lg={8} md={8} sm={24} xs={24}>
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="description"
                                        label="Description"
                                        rules={[{
                                            required: false,
                                            message: "Name is required"
                                        }]}
                                    >
                                        <Input placeholder="Details"/>
                                    </Form.Item>
                                </div>
                            </Col>

                            <Col lg={8} md={8} sm={24} xs={24}>
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="status"
                                        label="Status"
                                        rules={[{
                                            required: true,
                                            message: "Status is required"
                                        }]}
                                        initialValue={0}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select an option"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase())}
                                        >
                                            <Option value={0}>Inactive</Option>
                                            <Option value={1}>Active</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            </Col>

                            <Col lg={24} md={24} sm={24} xs={24}>
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="subject"
                                        label="Subject"
                                        rules={[{
                                            required: true,
                                            message: "Subject is required"
                                        }]}
                                    >
                                        <Input placeholder="Subject"/>
                                    </Form.Item>
                                </div>
                            </Col>

                            <Col lg={24} md={24} sm={24} xs={24}>
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="body"
                                        label="Body"
                                    >
                                        <CKEditor
                                            activeClass="p10"
                                            content={ck}
                                            events={{
                                                "change": handleChange
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                            </Col>

                            <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                                <Button type="primary" htmlType="submit">
                                    <IntlMessages id={"Submit"} />
                                </Button>
                                {/*<Button type="info" onClick={() => history.goBack()} htmlType="back"><IntlMessages id={"Cancel"} /></Button>*/}
                                {/*<Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Cancel"} /></Button>*/}
                                <Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Back"} /></Button>
                            </Col>

                        </Row>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

export default NewTemplate;
