import React, {useState, useEffect} from "react";
import { Form, Row, Col, Input, Button ,Card, Collapse, Table} from 'antd';
import jsonfile from './useractivitylog.json';
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "../../../../util/IntlMessages";
import Config from "../../../../util/config";

const Search = Input.Search;
const Panel = Collapse.Panel;
const Meta = Card.Meta;


const UserActivityLog = () => {
    const value = 0;
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.list,
        filtervalue : '',
        subtitle: ''
    });
    useEffect(() => {
        setState({
            reqURL: Config.apiserver+jsonfile.urls.list,
        })
    }, []);
    const history = useHistory();
    const headers = {
        id: {
            text: <IntlMessages id="Sl NO"/>,
            sortable: true,
            filterable: true,
        },
        user_name: {
            text: <IntlMessages id="User Name"/>,
            sortable: true,
            filterable: true,
        },
        table: {
            text: <IntlMessages id="Table"/>,
            sortable: true,
            filterable: true,
        },
        ip: {
            text: <IntlMessages id="IP"/>,
            sortable: true,
            filterable: true,
        },
        title: {
            text: <IntlMessages id="Title"/>,
            sortable: true,
            filterable: true,
        },
        details: {
            text: <IntlMessages id="Details"/>,
            sortable: true,
            filterable: true,
        },
        created_at: {
            text: <IntlMessages id="Created At"/>,
            sortable: true,
            filterable: true,
            transform: (value) => (
                CisUI().DateFormatWithTime(value)
            )
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: true,
            filterable: true,
            invisible: true,
            transform: (value, idx, row) => (
                CisUI().getStatusLabel(value)
            )
        },
        // actions: {
        //     text: 'Actions',
        //     sortable: false,
        //     filterable: false,
        //     transform: (value, idx, row) => (
        //         <>
        //             <Link to={`userprofile/${row.id}`} className="btn btn-primary btn-circle">
        //                 <i className="fas fa-trash-restore"></i>
        //             </Link>
        //         </>
        //     ),
        // },
    };

    const orderedHeaders = jsonfile.listView.title


    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        // console.log('Received values of form: ', values);
        const qs = Object.keys(values).map(key => `${key}=${values[key]}`).join('&');
        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.list+"/?"+qsup;
        setState({reqURL: newURL});
    };

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header={<IntlMessages id="Advance Search"/>}  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.userActivityLog)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :" + value);
        setState({filtervalue : "test"});
    }

        const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listAction("../"+jsonfile.urls.add, "", state.reqURL, "data", 'user_activity', jsonfile, state.subtitle, 'landscape')}
        </>
    );

    return (
        <>
            {searchView}
            <Card title={<IntlMessages id={jsonfile.listtitle}/>} extra={getExtra}>
                {/*{CisUI().listAction('newuser')}*/}
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    dynamic
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
        </>
    );
};

export default UserActivityLog;
