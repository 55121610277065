import React, {useEffect, useState} from "react";
import {Form, Card, Col, Row, Button, Checkbox, Select, Divider} from 'antd';
import jsonfile from './general_info.json'
import {CisUI} from '../../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../../util/config";
import axios from "../../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../../util/gerremotedata";
import IntlMessages from "../../../../../util/IntlMessages";
import InputFieldV2 from "../../../../../util/InputFieldV2";
import StaticSelectData from "../../../../../util/StaticSelectData";
import CircularProgress from "../../../../../components/CircularProgress";

const { Option } = Select;

const GeneralInfo = (props) => {
    const [form] = Form.useForm();
    const [isShow, setIsShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState("");

    const userID = props.editId;
    let priorities = StaticSelectData("priority");
    let display_priorities = StaticSelectData("display_priority");

    const changeImage = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = (e)=> {
            setImage({
                image : e.target.result
            });
        };
        reader.readAsDataURL(file);
    }

    const handleFeatured = (e) => {
        if(e.target.checked === true){
            setIsShow(true)
        }
        else {
            setIsShow(false)
        }
    }

    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1 ) {
        // userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        userData = props.editData;
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }

    // console.log(userData);

    const history = useHistory();

    const onFinish = (values) => {
        // console.log(values);
        let data = {
            ...values,
            featured_image: image['image'],
            'check_in': values['check_in'].format('HH:mm:ss'),
            'check_out': values['check_out'].format('HH:mm:ss'),
        }
        console.log(data);
        setLoading(true)
        axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg);
                    // history.push(redirectto);
                    props.rmID(res.data.room_id)
                    // document.getElementById("loader").style.display = "none";
                }
                else {
                    CisUI().Notification('error', res.data.msg);
                }
                setLoading(false)
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    return (
        <>
            {loading ? <CircularProgress />: ''}

            <Card title={<IntlMessages id={Title}/>}>
            <Form
                // form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                {/*{InputFields(jsonfile.input,userData,isEdit,props)}*/}
                {InputFieldV2(jsonfile.input,userData,isEdit,props, '', redirectto)}
                <Row>
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <div className="gx-form-row0">
                        <Form.Item
                            name="display_priority"
                            label="Display Priority"
                            rules={[
                                {
                                    required: isShow === true ? true : false,
                                    message: "Display priority is required"
                                }
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select An Option"
                                optionFilterProp="children"
                                allowClear={true}
                                loading={display_priorities.length > 0 ? false : true}
                            >
                                {
                                    display_priorities.map((item,index) =>
                                        <Option value={item.id}>{item.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                        </div>
                    </Col>
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name="image"
                                label="Featured Image"
                            >
                                <input type="file" onChange={changeImage}/>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Divider orientation="left">Featured</Divider>
                <Row>
                    <Col lg={8} md={8} sm={12} xs={12}>
                        <Form.Item
                            name="is_featured"
                            label="Is Featured"
                            valuePropName="checked"
                        >
                            <Checkbox onChange={handleFeatured} />
                        </Form.Item>
                    </Col>
                    {
                        isShow === true && (
                            <Col lg={8} md={8} sm={12} xs={12}>
                                <Form.Item
                                    name="priority"
                                    label="Featured Priority"
                                    rules={[
                                        {
                                            required: isShow === true ? true : false,
                                            message: "Display priority is required"
                                        }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select An Option"
                                        optionFilterProp="children"
                                        allowClear={true}
                                        loading={priorities.length > 0 ? false : true}
                                    >
                                        {
                                            priorities.map((item,index) =>
                                                <Option value={item.id}>{item.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        )
                    }

                </Row>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            <IntlMessages id={"Submit"} />
                        </Button>
                        <Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Back"} /></Button>
                    </Col>
                </Row>

            </Form>
        </Card>
        </>
    );
};

export default GeneralInfo;
