import React from "react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import RetrievePaymentStatus from "./RetrievePaymentStatus";
import Config from "../../../../../util/config";
const stripePromise = loadStripe(Config.stripe_pk);

const RetrievePayment = () => {
    const paymentIntent = new URLSearchParams(window.location.search).get("payment_intent");
    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
    const redirectStatus = new URLSearchParams(window.location.search).get("redirect_status");

	return (
        <Elements stripe={stripePromise}>
            <RetrievePaymentStatus paymentIntent={paymentIntent} clientSecret={clientSecret} redirectStatus={redirectStatus}/>
        </Elements>
	);
};

export default RetrievePayment;
