import React from "react";
import {Route, Switch} from "react-router-dom";

import TaskList from "../../containers/yotta/task/TaskList";
import AddTask from "../../containers/yotta/task/AddTask";
import EditTask from "../../containers/yotta/task/EditTask";
import TaskHistory from "../../containers/yotta/task/TaskHistory";
import TaskCalendar from "../../containers/yotta/task/TaskCalendar";
import RoomList from "../../containers/yotta/task/cleaning_rooms/RoomList";
import AddRoom from "../../containers/yotta/task/cleaning_rooms/AddRoom";
import EditRoom from "../../containers/yotta/task/cleaning_rooms/EditRoom";
import GroupLists from "../../containers/yotta/task/Group/GroupLists";
import NewGroup from "../../containers/yotta/task/Group/NewGroup";
import ToDoLists from "../../containers/yotta/task/ToDo/ToDoLists";
import NewToDo from "../../containers/yotta/task/ToDo/NewToDo";
import EditGroup from "../../containers/yotta/task/Group/EditGroup";


const Task = ({match}) => (
    <Switch>
        <Route path={`${match.url}/groups`} component={GroupLists}/>
        <Route path={`${match.url}/newgroup`} component={NewGroup}/>
        <Route path={`${match.url}/groupedit/:id`} component={EditGroup}/>
        <Route path={`${match.url}/todolist`} component={ToDoLists}/>
        <Route path={`${match.url}/newtodo`} component={NewToDo}/>
        <Route path={`${match.url}/todoedit/:id`} component={NewToDo}/>
        <Route path={`${match.url}/tasks`} component={TaskList}/>
        <Route path={`${match.url}/newtask`} component={AddTask}/>
        <Route path={`${match.url}/taskedit/:id`} component={EditTask}/>
        <Route path={`${match.url}/taskhistories/:id`} component={TaskHistory}/>
        <Route path={`${match.url}/managerooms`} component={RoomList}/>
        <Route path={`${match.url}/addroom`} component={AddRoom}/>
        <Route path={`${match.url}/roomedit/:id`} component={EditRoom}/>
        <Route path={`${match.url}/calendarview`} component={TaskCalendar}/>
    </Switch>
);

export default Task;
