import React, {useState} from "react";
import {Form, Card, notification, Col, Row, Button, Input, Select} from 'antd';
import jsonfile from './room.json'
import {CisUI} from '../../../../util/CISUI'
import InputFields from '../../../../util/InputField'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import IntlMessages from "../../../../util/IntlMessages";
import InputFieldV2 from "../../../../util/InputFieldV2";
import TextArea from "antd/es/input/TextArea";
import CircularProgress from "../../../../components/CircularProgress";

const {Option} = Select;

const AddRoom = (props) => {
    const [image, setImage] = useState("");
    const userID = props.match.params.id;
    const [form] = Form.useForm();
    const history = useHistory();
    const [loader, setLoader] = useState(false);

    const changeImage = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = (e)=> {
            setImage({
                image : e.target.result
            });
        };
        reader.readAsDataURL(file);
    }

    let userData = RemoteJson(jsonfile.urls.view+"/"+userID);
    let Title = jsonfile.edittitle;
    let endpoint = jsonfile.urls.edit+"/"+userID;
    let redirectto = "../../"+jsonfile.urls.list;

    const onFinish = (values) => {
        // console.log(values);
        let data = {
            ...values,
            image: image['image']
        }
        setLoader(true)
        // document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg);
                    history.push(redirectto);
                }
                else {
                    CisUI().Notification('error', res.data.msg);
                }
                // document.getElementById("loader").style.display = "none";
                setLoader(false)
            })
            .catch(errors => {
                setLoader(false)
                console.log(errors);
            });
    };

    return (
        <Card title={<IntlMessages id={Title}/>} extra={CisUI().addAction(redirectto)}>
            {
                userData == '' || loader ? <CircularProgress/> :
                <Form
                    form={form}
                    {...CisUI().formItemLayout}
                    name="input"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                    initialValues={{
                        name: userData.name,
                        website: userData.website,
                        phone: userData.phone,
                        url: userData.url,
                        address: userData.address,
                        details: userData.details,
                        status: userData.status
                    }}
                >
                    <Row>
                        <Col lg={8} md={8} sm={12} xs={24}>
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="name"
                                    label="Name"
                                    rules={[{
                                        required: true,
                                        message: "Name is required"
                                    }]}
                                >
                                    <Input placeholder="Name"/>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24}>
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="website"
                                    label="Website"
                                    rules={[{
                                        required: false,
                                        message: "Website is required"
                                    }]}
                                >
                                    <Input placeholder="Website"/>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24}>
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="phone"
                                    label="Phone"
                                    rules={[{
                                        required: true,
                                        message: "Phone is required"
                                    }]}
                                >
                                    <Input placeholder="Phone"/>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24}>
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="url"
                                    label="Map Link"
                                    rules={[{
                                        required: true,
                                        message: "Map Link is required"
                                    }]}
                                >
                                    <Input placeholder="Map Link Title"/>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24}>
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="address"
                                    label="Address"
                                    rules={[{
                                        required: true,
                                        message: "Address is required"
                                    }]}
                                >
                                    <TextArea className="gx-w-100" placeholder="Address"/>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24}>
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="details"
                                    label="Details"
                                    rules={[{
                                        required: false,
                                        message: "Task title is required"
                                    }]}
                                >
                                    <TextArea className="gx-w-100" placeholder="Task Title"/>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24}>
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="status"
                                    label="Status"
                                    rules={[{
                                        required: true,
                                        message: "Status is required"
                                    }]}
                                    initialValue={0}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select an option"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase())}
                                    >
                                        <Option value={0}>Inactive</Option>
                                        <Option value={1}>Active</Option>
                                        <Option value={2}>Blocked</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col lg={8} md={8} sm={24} xs={24}>
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="image"
                                    label="Image"
                                >
                                    <input type="file" onChange={changeImage}/>

                                    <img src={Config.image_url + '/upload/cleaning_rooms/' + userData.image}
                                                 alt="Image" style={{width: "120px", height: "100px", marginTop: "10px"}}/>


                                </Form.Item>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                            <Button type="primary" htmlType="submit">
                                <IntlMessages id={"Submit"}/>
                            </Button>
                            <Button type="info" onClick={() => history.push(redirectto)}><IntlMessages
                                id={"Back"}/></Button>
                        </Col>
                    </Row>

                </Form>
            }
        </Card>
    );
};

export default AddRoom;
