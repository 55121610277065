import React, {useEffect, useState} from "react";
import {useStripe} from '@stripe/react-stripe-js';
import axios from "axios";
import Config from "../../../../../util/config";
import {CisUI} from "../../../../../util/CISUI";
import img2 from '../../../../../styles/img2.png'
import {CheckCircleOutlined, IssuesCloseOutlined} from "@ant-design/icons";

const RetrievePaymentStatus = (props) => {
    const stripe = useStripe();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [url, setUrl] = useState(null);
    const [success, setSuccess] = useState(0);
    const [processing, setProcessing] = useState(0);
    const [failed, setFailed] = useState(0);
    const [other, setOther] = useState(0);

    const getData = async () => {
        if (!stripe) {
            return;
        }

        if (!props.clientSecret) {
            return;
        }

        // const {paymentIntent} = await stripe.retrievePaymentIntent(props.clientSecret)
        //     console.log(paymentIntent)

        await stripe.retrievePaymentIntent(props.clientSecret)
            .then(({ paymentIntent }) => {

                switch (paymentIntent.status) {
                    case "requires_action":
                        if(paymentIntent.next_action.type === "verify_with_microdeposits"){
                            console.log(paymentIntent.next_action.verify_with_microdeposits.hosted_verification_url)
                            setUrl(paymentIntent.next_action.verify_with_microdeposits.hosted_verification_url);
                        }
                        break;
                    case "succeeded":
                        setSuccess(1)
                        setMessage("Payment succeeded!");
                        break;
                    case "processing":
                        setProcessing(1)
                        setMessage("Your payment is processing.");
                        break;
                    case "requires_payment_method":
                        setFailed(1)
                        setMessage("Your payment was not successful, please try again.");
                        break;
                    default:
                        setOther(1)
                        setMessage("Something went wrong.");
                        break;
                }
            });

        axios.get(Config.apiserver+'booking/retrievePayment?payment_intent='+props.paymentIntent+'&payment_intent_client_secret='+props.clientSecret+'&redirect_status='+props.redirectStatus)
            .then(res => {
                console.log("")
                if(res.data.status === 1){
                    CisUI().Notification('success', res.data.msg)
                }
                else {
                    CisUI().Notification('error', res.data.msg)
                }
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        getData();
    }, [stripe]);


	return (
        <>
            {
                message && success === 1 &&

                <div className="page_success">
                    <div className="success__icon">
                        <CheckCircleOutlined className="success__icon_img"/>

                    </div>

                    <h3>{message}</h3>
                    <p>Thank you for completing your secure online payment.</p>
                </div>
            }

            {
                message && processing === 1 &&

                <div className="page_proccess">
                    <div className="process__icon__area">
                        <img src={img2} alt="" className="round___image"/>
                    </div>

                    <div className="process___title">
                        <h3>{message}</h3>
                        <p>Your payment is processing.</p>
                    </div>
                </div>
            }
            {
                message && failed === 1 &&

                <div className="page_failed">
                    <div className="failed__icon">
                        <IssuesCloseOutlined  />
                    </div>

                    <h3>{message}</h3>
                    <p>{message}</p>
                </div>
            }

            {
                message && other === 1 &&

                // <div id="payment-message">{message}</div>
                <div className="page_failed">
                    <div className="failed__icon">
                        <IssuesCloseOutlined  />
                    </div>

                    <h3>{message}</h3>
                    <p>{message}</p>
                </div>
            }

            {
                url &&
                <a href={url}>Verify Link</a>
            }
        </>
	);
};

export default RetrievePaymentStatus;
