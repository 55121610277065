import React from "react";
import {Route, Switch} from "react-router-dom";
import CRM from "./crm/index";
import Company from "./company/index";
import User from "./user/index";
import Settings from "./settings/index";
import Dashboard from "./dashboard/index";
import Profile from "./profile/index";
import PartnerCMS from "./partner_cms/index";

// import Dashboard from "./main/dashboard/CRM/index";

import Owner from "./owner";
import CMS from "./cms/index";
import Property from "./property";
import Booking from "./booking";
import BookingDate from "./booking_date";
import Message from "./message";
import ChannelManager from "./channel_manager";
import Staff from "./staff";
import Task from "./task";

const App = ({match}) => (
    <div className="gx-main-content-wrapper">
        <Switch>
            <Route path={`${match.url}main`} component={Dashboard}/>
            <Route path={`${match.url}crm`} component={CRM}/>
            <Route path={`${match.url}company`} component={Company}/>
            <Route path={`${match.url}user`} component={User}/>
            <Route path={`${match.url}settings`} component={Settings}/>
            <Route path={`${match.url}profile`} component={Profile}/>
            <Route path={`${match.url}owner`} component={Owner}/>
            <Route path={`${match.url}cms`} component={CMS}/>
            <Route path={`${match.url}partnercms`} component={PartnerCMS}/>
            <Route path={`${match.url}property`} component={Property}/>
            <Route path={`${match.url}booking`} component={Booking}/>
            <Route path={`${match.url}bookingdate`} component={BookingDate}/>
            <Route path={`${match.url}message`} component={Message}/>
            <Route path={`${match.url}channelmanager`} component={ChannelManager}/>
            <Route path={`${match.url}staff`} component={Staff}/>
            <Route path={`${match.url}task`} component={Task}/>
            {/* <Route path={`${match.url}mail`} component={Mail}/> */}
        </Switch>
    </div>
);

export default App;

