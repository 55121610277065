import React, {useEffect, useState} from "react";
import {Form, Card, Col, Row, Button, Select, Divider, DatePicker, Input, Popconfirm, InputNumber} from 'antd';
import jsonfile from './booking.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
// import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import { useHistory} from "react-router-dom";
import IntlMessages from "../../../../util/IntlMessages";
import SelectData from "../../../../util/selectdata";
import moment from "moment";
// import Slider from 'react-slick';
import RoomDetails from "./RoomDetails";
import Config from "../../../../util/config";

import CircularProgress from "../../../../components/CircularProgress";

const {Option} = Select;

const NewBooking = (props) => {
    const [form] = Form.useForm();
    const history = useHistory();
    // const [ck, setCK] = useState("");
    const [loader, setLoader] = useState(false);
    const [rooms, setRooms] = useState([]);
    // const [price, setPrice] = useState();
    const [roomImages, setRoomImages] = useState([]);
    // const [selectedRoom, setSelectedRoom] = useState("");
    const [submitButton, setSubmitButton] = useState(0);

    const numEachPage = 8;
    const [state, setState] = useState({
        minValue: 0,
        maxValue: numEachPage
    });

    const [roomDetails, setRoomDetails] = useState({
        room_details: "",
        amenities: [],
        high_amenities: [],
        selectedRoom: "",
    });

    const [multiRoom, setMultiRoom] = useState([]);

    const [calculation, setCalculation] = useState({
        total_rent: 0,
        total: 0,
        tt_discount: 0,
        tt_discount_amn: 0,
        tt_after_dis: 0,
        total_cleaningfee: 0,
        tt_petfee: 0,
        tt_pets: 0,
        tt_grand: 0
    });

    const handleChange = value => {
        setState({
            minValue: (value-1)*numEachPage,
            maxValue: value*numEachPage
        })
    };

    const property = SelectData('property/properties');
    const customer = SelectData('crm/customerlist');

    const handleHotelChange = (value) => {
        // console.log(value)
        if(value !== undefined) {
            setLoader(true)
            axios.get('property/propertywiserooms/' + value)
                .then(res => {
                    // console.log(res.data)
                    setRooms(res.data.data)
                    setRoomImages(res.data.images)
                    setRoomDetails({
                        room_details: "",
                        amenities: [],
                        high_amenities: [],
                        selectedRoom: ""
                    })
                    setLoader(false)
                })
                .catch(error => {
                    setLoader(false)
                    console.log(error)
                })
        }
    }

    useEffect(() => {
        handleHotelChange()
    }, []);

    const getRoomDetails = (value) => {
        console.log(value)
        if(value !== undefined) {
            setLoader(true)
            axios.get('property/roomdetails/' + value)
                .then(res => {
                    // console.log(res.data)
                    setRoomDetails({
                        room_details: res.data.data.room_data,
                        amenities: res.data.amenities,
                        high_amenities: res.data.high_amenities,
                        selectedRoom: value
                    })

                    setLoader(false)
                })
                .catch(error =>{
                    setLoader(false)
                    console.log(error)
                })
        }
    }

    useEffect(() => {
        getRoomDetails()
    }, []);

    if(roomDetails.room_details !== ''){

        form.setFieldsValue({
            'room_nm' : roomDetails.room_details.room_name,
            'check_in_date' : moment(),
            'check_out_date' : moment().add(1, 'days'),
            'customer_price' : roomDetails.room_details.customer_price,
            'cleaning_fee' : roomDetails.room_details.cleaning_fee,
            'pet_fee': roomDetails.room_details.pet_fee,
            'pets': 0,
            'guest': 0,
            'discount' : roomDetails.room_details.discount,
        });

        let check_in_date = form.getFieldValue('check_in_date');
        let check_out_date = form.getFieldValue('check_out_date');
        form.setFieldsValue({
            'nights' : check_out_date.diff(check_in_date, 'days')
        })
    }

    const handleDateChange = () => {
        let check_in_date = form.getFieldValue('check_in_date');
        let check_out_date = form.getFieldValue('check_out_date');
        if(check_in_date !== null && check_out_date !== null) {
            form.setFieldsValue({
                'nights': check_out_date.diff(check_in_date, 'days')
            })
        }
        else {
            form.setFieldsValue({
                'nights': 0
            })
        }
    }

    const handleBook = (room_id) => {
       if(roomDetails.room_details !== ''){
           let room_data = roomDetails.room_details;

           let room_arr = [
               ...multiRoom
           ];
           let guest = form.getFieldValue('guest');
           if(guest === 0){
               CisUI().Notification("error", "Please add atleast one guest.")
           }
           else{
               if(room_data.id === room_id){
                   // console.log(room_data.id, room_id)
                   let check_in = form.getFieldValue('check_in_date');
                   let check_out = form.getFieldValue('check_out_date');
                   let nights = form.getFieldValue('nights');
                   let cleaning_fee = form.getFieldValue('cleaning_fee');
                   let pet_fee = form.getFieldValue('pet_fee');
                   let pets = form.getFieldValue('pets');
                   let guest = form.getFieldValue('guest');
                   let customer_price = room_data.customer_price;
                   let total_price = nights * customer_price;
                   // let with_cleaningfee = (total_price + cleaning_fee);
                   let discount = form.getFieldValue('discount');
                   let discount_amount = (total_price * discount) / 100;
                   let after_discount = total_price - discount_amount;
                   let pet_amount = pet_fee * pets;
                   let grand_total = after_discount + cleaning_fee + pet_amount;

                   let isExists = false;

                   if(room_arr.length > 0){
                       room_arr.map((item, index) => {
                           if(item.room_id === room_id){
                               isExists = true
                           }
                       })

                       if(isExists === true){
                           CisUI().Notification("warning", "You already added this room.")
                       }
                       else {
                           CisUI().Notification("success", "One room is added for booking.")
                           room_arr.push({
                               'room_id': room_data.id,
                               'room_name': room_data.room_name,
                               'check_in': moment(check_in).format("YYYY-MM-DD"),
                               'check_out': moment(check_out).format("YYYY-MM-DD"),
                               'nights': nights,
                               'customer_price': customer_price,
                               'total': total_price,
                               'guest': guest,
                               'discount': parseFloat(discount),
                               'discount_amount': discount_amount,
                               'after_discount': after_discount,
                               'cleaning_fee': cleaning_fee,
                               'pet_fee': pet_fee,
                               'pets': pets,
                               'grand_total': grand_total
                           })
                       }
                   } else {
                       CisUI().Notification("success", "One room is added for booking.")
                       room_arr.push({
                           'room_id': room_data.id,
                           'room_name': room_data.room_name,
                           'check_in': moment(check_in).format("YYYY-MM-DD"),
                           'check_out': moment(check_out).format("YYYY-MM-DD"),
                           'nights': nights,
                           'customer_price': customer_price,
                           'total': total_price,
                           'guest': guest,
                           'discount': discount,
                           'discount_amount': discount_amount,
                           'after_discount': after_discount,
                           'cleaning_fee': cleaning_fee,
                           'pet_fee': pet_fee,
                           'pets': pets,
                           'grand_total': grand_total
                       })
                   }

               }
               else {
                   console.log("nothing");
               }
               setMultiRoom(room_arr)
               total_calculation(room_arr)
           }

       }
    }

    const handleRoomItemDelete = (room_id) => {
        let new_arr = multiRoom.filter((item, index) => item.room_id !== room_id)
        setMultiRoom(new_arr)
        total_calculation(new_arr)
        CisUI().Notification("success", "One room is deleted from booking items.")
    }

    const total_calculation = (arr) => {
        let total_rent = 0;
        let tt_total = 0;
        let tt_discount = 0;
        let tt_discount_amn = 0;
        let tt_after_dis = 0;
        let tt_cleaningfee = 0;
        let tt_petfee = 0;
        let tt_pets = 0;
        let tt_grand = 0;

        arr.forEach((item, index) => {
            total_rent += item.customer_price
            tt_total += item.total
            tt_discount += item.discount
            tt_discount_amn += item.discount_amount
            tt_after_dis += item.after_discount
            tt_cleaningfee += item.cleaning_fee
            tt_petfee += item.pet_fee
            tt_pets += item.pets
            tt_grand += item.grand_total
        })

        setCalculation({
            total_rent: total_rent,
            total: tt_total,
            tt_discount: parseFloat(tt_discount),
            tt_discount_amn: tt_discount_amn,
            tt_after_dis: tt_after_dis,
            total_cleaningfee: tt_cleaningfee,
            tt_petfee: tt_petfee,
            tt_pets: tt_pets,
            tt_grand: tt_grand
        })
    }

    useEffect(() => {
        handleBook()
    },[])


    let Title = jsonfile.addtitle;
    let req_endpoint = jsonfile.urls.bookingRequest;
    let redirectto = "../"+jsonfile.urls.list;

    // console.log(multiRoom)

    const handleClick = (value) =>{
        setSubmitButton(value)
    }

    const onFinish = (values) => {
        if(multiRoom.length > 0)
        {
            let data = {
                // details:ck
                property_id: values['property_id'],
                customer_id: values['customer_id'],
                booking_items: multiRoom,
            }
            // console.log(data)
            if(submitButton === 1) {
                console.log(data)
                setLoader(true)
                axios.post(Config.apiserver + req_endpoint, data, CisUI().HeaderRequest)
                    .then(res => {
                        // console.log(res)
                        if(res.data.status === 1) {
                            CisUI().Notification('success', res.data.msg);
                            history.push("../booking/bookingrequest");
                        }
                        else {
                            CisUI().Notification('error', res.data.msg);
                        }
                        setLoader(false)
                    })
                    .catch(errors => {
                        setLoader(false)
                        console.log(errors);
                    });
            }
            else {
                // history.push('/booking/payment/25')
            }
        }
        else {
            CisUI().Notification('warning', "Please select atleast one room for booking!!!");
        }
    };

    return (
        loader ? <CircularProgress /> :
        <Card title={<IntlMessages id={Title}/>} extra={CisUI().addAction(redirectto)}>
            <Row gutter={24}>

                <Col lg={16} md={16} sm={24} xs={24}>
                    <Card>

                        <Form
                            form={form}
                            {...CisUI().formItemLayout}
                            name="input"
                            className="ant-advanced-search-form"
                            onFinish={onFinish}
                            initialValues={{ remember: true }}
                        >
                            <Row gutter={24}>
                                <Col lg={8} md={8} sm={12} xs={24}>
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="property_id"
                                            label="Property/Hotel"
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select an option"
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase())}
                                                onChange={handleHotelChange}
                                            >
                                                {
                                                    property.map((item, index) =>
                                                        <option value={item.id}>{item.name}</option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col lg={8} md={8} sm={12} xs={24}>
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="room_id"
                                            label="Room"
                                            rules={[{
                                                required: true,
                                                message: "Room is required."
                                            }]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select an option"
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase())}
                                                onSelect={getRoomDetails}
                                            >
                                                {
                                                    rooms.map((item, index) =>
                                                        <option value={item.id}>{item.room_name}</option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col lg={8} md={8} sm={12} xs={24}>
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="customer_id"
                                            label="Customer"
                                            rules={[{
                                                required: true,
                                                message: "Customer is required."
                                            }]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select an option"
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase())}
                                            >
                                                {
                                                    customer.map((item, index) =>
                                                        <Option value={item.id} key={++index}>{item.name}</Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Col>
                                {/*<Col lg={6} md={6} sm={12} xs={24}>*/}
                                {/*    <Card>*/}
                                {/*        <h4>Selected Room: <span style={{ color: "#1e2b5d", fontWeight:"100"}}>{state.selectedRoom}</span></h4>*/}
                                {/*        /!*<p>{selectedRoom}</p>*!/*/}
                                {/*    </Card>*/}
                                {/*</Col>*/}
                                {/*<Col lg={6} md={6} sm={12} xs={24}>*/}
                                {/*    <Card>*/}
                                {/*        <h4>Room is booked from: 25/12/2021 - 31/12/2021</h4>*/}
                                {/*    </Card>*/}
                                {/*</Col>*/}
                            </Row>
                            {/*{*/}
                            {/*    rooms.length > 0  &&*/}
                            {/*    <>*/}
                            {/*        <Divider orientation="left" plain>*/}
                            {/*            Rooms*/}
                            {/*        </Divider>*/}
                            {/*        <Slider arrows={true} slidesToShow={rooms.length > 1 ? 3 : 1}>*/}
                            {/*        {*/}
                            {/*            rooms.map((item, index) =>*/}
                            {/*                <Card size="small"*/}
                            {/*                    key={++index}*/}
                            {/*                    cover={*/}
                            {/*                        <img alt="example" src={Config.image_url + item.image} style={{ width:"250px", height:"150px" }}/>*/}
                            {/*                    }*/}
                            {/*                    onClick={() => getRoomDetails(item.id)}*/}
                            {/*                    // actions={[*/}
                            {/*                    //     <Button onClick={() => getRoomDetails(item.id)}>{"Room No: "+item.room_name}</Button>,*/}
                            {/*                    //     // <p>{item.size} sq.ft</p>*/}
                            {/*                    // ]}*/}
                            {/*                    //   style={{ width: rooms.length > 1 ? style_content : style_content2 }}*/}
                            {/*                      className="room_slider"*/}
                            {/*                >*/}
                            {/*                    <p style={{ fontWeight:"bold", color: roomDetails.selectedRoom === item.id ? 'red' : ''}}>*/}
                            {/*                        Room No: {item.room_name}*/}
                            {/*                    </p>*/}
                            {/*                    <p style={{ color: roomDetails.selectedRoom === item.id ? 'red' : '' }}>Price: {item.customer_price}</p>*/}
                            {/*                    <p style={{ color: roomDetails.selectedRoom === item.id ? 'red' : '' }}>Room Size: {item.size} sq.ft</p>*/}
                            {/*                </Card>*/}
                            {/*            )*/}
                            {/*        }*/}
                            {/*        </Slider>*/}
                            {/*    </>*/}
                            {/*}*/}

                            {/*{*/}
                            {/*    rooms.length > 0  &&*/}
                            {/*        <>*/}
                            {/*    <Row gutter={24}*/}

                            {/*    >*/}
                            {/*        <Divider orientation="left" plain>*/}
                            {/*            Rooms*/}
                            {/*        </Divider>*/}

                            {/*            {*/}
                            {/*                rooms.slice(state.minValue, state.maxValue).map((item, index) =>*/}
                            {/*                // {console.log(item)}*/}

                            {/*                        <Col lg={6} md={8} sm={12} xs={12}>*/}
                            {/*                            <Card size="small"*/}
                            {/*                                  key={++index}*/}
                            {/*                                  cover={*/}
                            {/*                                      <img alt="example" src={Config.image_url + item.image} style={{ height:"150px" }}/>*/}
                            {/*                                  }*/}
                            {/*                                  onClick={() => getRoomDetails(item.id)}*/}
                            {/*                                // actions={[*/}
                            {/*                                //     <Button onClick={() => getRoomDetails(item.id)}>{"Room No: "+item.room_name}</Button>,*/}
                            {/*                                //     // <p>{item.size} sq.ft</p>*/}
                            {/*                                // ]}*/}
                            {/*                                  style={{ cursor:"pointer" }}*/}
                            {/*                                //   className="room_slider"*/}
                            {/*                                //   pagination={{pageSize:3, total: rooms.length}}*/}
                            {/*                            >*/}
                            {/*                                <p style={{ fontWeight:"bold", color: roomDetails.selectedRoom === item.id ? 'red' : ''}}>*/}
                            {/*                                    Room No: {item.room_name}*/}
                            {/*                                </p>*/}
                            {/*                                <p style={{ color: roomDetails.selectedRoom === item.id ? 'red' : '' }}>Room Size: {item.size} sq.ft</p>*/}
                            {/*                                <p style={{ color: roomDetails.selectedRoom === item.id ? 'red' : '' }}>*/}
                            {/*                                    Price: <span style={{fontWeight:"bold"}}>{CisUI().getCurrencyFormated1(item.customer_price)}</span>*/}
                            {/*                                </p>*/}
                            {/*                                <p style={{ color: roomDetails.selectedRoom === item.id ? 'red' : '' }}>Discount: {item.discount}%</p>*/}
                            {/*                            </Card>*/}
                            {/*                        </Col>*/}
                            {/*                )*/}
                            {/*            }*/}


                            {/*    </Row>*/}

                            {/*            <Pagination*/}
                            {/*                // showLast={true}*/}
                            {/*                // pageSize={3}*/}
                            {/*                defaultCurrent={1}*/}
                            {/*                size="small"*/}
                            {/*                // current={1}*/}
                            {/*                pageSize={numEachPage}*/}
                            {/*                onChange={handleChange}*/}
                            {/*                total={rooms.length}*/}
                            {/*            />*/}

                            {/*        </>*/}
                            {/*}*/}

                            {
                                roomDetails.room_details !== '' &&
                                <Row gutter={24}>
                                    <Divider orientation="left" plain>Room No: {roomDetails.room_details.room_name} Details</Divider>
                                    <Col lg={8} md={8} sm={8} xs={8}>
                                        <Form.Item
                                            name="room_nm"
                                            label="Room Name"
                                        >
                                            <Input className="gx-mb-3 gx-w-100" disabled={true}/>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8} md={8} sm={8} xs={8}>
                                        <Form.Item
                                            name="check_in_date"
                                            label="Check In Date"
                                            // initialValue={moment()}

                                        >
                                            <DatePicker onChange={handleDateChange} className="gx-mb-3 gx-w-100"/>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8} md={8} sm={8} xs={8}>
                                        <Form.Item
                                            name="check_out_date"
                                            label="Check Out Date"
                                        >
                                            <DatePicker onChange={handleDateChange} className="gx-mb-3 gx-w-100"/>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8} md={8} sm={8} xs={8}>
                                        <Form.Item
                                            name="nights"
                                            label="Total Nights"
                                        >
                                            <Input className="gx-mb-3 gx-w-100" disabled={true}/>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8} md={8} sm={8} xs={8}>
                                        <Form.Item
                                            name="customer_price"
                                            label="Rent"
                                        >
                                            <Input className="gx-mb-3 gx-w-100" disabled={true}/>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8} md={8} sm={8} xs={8}>
                                        <Form.Item
                                            name="cleaning_fee"
                                            label="Cleaning Fee"
                                        >
                                            <Input className="gx-mb-3 gx-w-100" disabled={true}/>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8} md={8} sm={8} xs={8}>
                                        <Form.Item
                                            name="pet_fee"
                                            label="Pet Fee"
                                        >
                                            <Input className="gx-mb-3 gx-w-100" disabled={true}/>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8} md={8} sm={8} xs={8}>
                                        <Form.Item
                                            name="pets"
                                            label="No of pet"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Pet is required"
                                                }
                                            ]}
                                        >
                                            <InputNumber className="gx-mb-3 gx-w-100"/>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8} md={8} sm={8} xs={8}>
                                        <Form.Item
                                            name="guest"
                                            label="Guest (Not more than 4 persons)"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Guest is required"
                                                }
                                            ]}
                                        >
                                            <InputNumber min={0} max={4} className="gx-mb-3 gx-w-100"/>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8} md={8} sm={8} xs={8}>
                                        <Form.Item
                                            name="discount"
                                            label="Discount(%)"
                                        >
                                            <Input className="gx-mb-3 gx-w-100" />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={4} md={4} sm={6} xs={6}>
                                        <Button type="primary" onClick={() => handleBook(roomDetails.room_details.id)} style={{ margin: "15px 0 0 15px"}}>
                                            <IntlMessages id={"Book Now"} />
                                        </Button>
                                    </Col>
                                </Row>
                            }


                            <Divider orientation="left" plain>Booking Item Details</Divider>

                            <Row gutter={24}>
                                <Col lg={24} md={24} sm={24} xs={24}>
                                    <Card>
                                        <div className="table-responsive">
                                            <table className="table book_dt_table" style={{ marginBottom: "10px"}}>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "10%" }}>Room No</th>
                                                    <th style={{ width: "10%" }}>Check In</th>
                                                    <th style={{ width: "10%" }}>Check Out</th>
                                                    <th style={{ width: "10%", textAlign:"right" }}>Nights</th>
                                                    <th style={{ width: "10%", textAlign:"right" }}>Rent</th>
                                                    <th style={{ width: "10%", textAlign:"right" }}>Total</th>
                                                    <th style={{ width: "10%", textAlign:"right" }}>Discount(%)</th>
                                                    <th style={{ width: "10%", textAlign:"right" }}>Discount Amount</th>
                                                    <th style={{ width: "10%", textAlign:"right" }}>After Discount</th>
                                                    <th style={{ width: "10%", textAlign:"right" }}>Cleaning Fee</th>
                                                    <th style={{ width: "10%", textAlign:"right" }}>Pet Fee</th>
                                                    <th style={{ width: "10%", textAlign:"right" }}>No of pets</th>
                                                    <th style={{ width: "10%", textAlign:"right" }}>Grand Total</th>
                                                    <th style={{ width: "10%" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    multiRoom.map((item, index) =>
                                                        <tr>
                                                            <td>{item.room_name}</td>
                                                            <td>{item.check_in}</td>
                                                            <td>{item.check_out}</td>
                                                            <td style={{ textAlign:"right"}}>{item.nights}</td>
                                                            <td style={{ textAlign:"right"}}>{CisUI().getCurrencyFormated1(item.customer_price)}</td>
                                                            <td style={{ textAlign:"right"}}>{CisUI().getCurrencyFormated1(item.total)}</td>
                                                            <td style={{ textAlign:"right"}}>{CisUI().getCurrencyFormated1(item.discount)}%</td>
                                                            <td style={{ textAlign:"right"}}>{CisUI().getCurrencyFormated1(item.discount_amount)}</td>
                                                            <td style={{ textAlign:"right"}}>{CisUI().getCurrencyFormated1(item.after_discount)}</td>
                                                            <td style={{ textAlign:"right"}}>{CisUI().getCurrencyFormated1(item.cleaning_fee)}</td>
                                                            <td style={{ textAlign:"right"}}>{CisUI().getCurrencyFormated1(item.pet_fee)}</td>
                                                            <td style={{ textAlign:"right"}}>{item.pets}</td>
                                                            <td style={{ textAlign:"right"}}>{CisUI().getCurrencyFormated1(item.grand_total)}</td>
                                                            <td style={{ textAlign:"right"}}>
                                                                <Popconfirm
                                                                    title="Are you sure to delete this item?"
                                                                    onConfirm={() => handleRoomItemDelete(item.room_id)}
                                                                    // onCancel={cancel}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <p style={{ cursor: "pointer" }} className="btn btn-text-adjust btn-circle-md"><i className="icon icon-trash"/></p>
                                                                </Popconfirm>

                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                <tr>
                                                    <td style={{ width:"10%" }}></td>
                                                    <td style={{ width:"10%" }}></td>
                                                    <td style={{ width:"10%" }}></td>
                                                    <td style={{ width: "10%", textAlign:"right", fontWeight:"bold", fontSize:"15px" }}>Total</td>
                                                    <td style={{ width: "10%", textAlign:"right", fontWeight:"bold", fontSize:"15px" }}>{CisUI().getCurrencyFormated1(calculation.total_rent)}</td>
                                                    <td style={{ width: "10%", textAlign:"right", fontWeight:"bold", fontSize:"15px" }}>{CisUI().getCurrencyFormated1(calculation.total)}</td>
                                                    <td style={{ width: "10%", textAlign:"right", fontWeight:"bold", fontSize:"15px" }}>{CisUI().getCurrencyFormated1(calculation.tt_discount)}</td>
                                                    <td style={{ width: "10%", textAlign:"right", fontWeight:"bold", fontSize:"15px" }}>{CisUI().getCurrencyFormated1(calculation.tt_discount_amn)}</td>
                                                    <td style={{ width: "10%", textAlign:"right", fontWeight:"bold", fontSize:"15px" }}>{CisUI().getCurrencyFormated1(calculation.tt_after_dis)}</td>
                                                    <td style={{ width: "10%", textAlign:"right", fontWeight:"bold", fontSize:"15px" }}>{CisUI().getCurrencyFormated1(calculation.total_cleaningfee)}</td>
                                                    <td style={{ width: "10%", textAlign:"right", fontWeight:"bold", fontSize:"15px" }}>{CisUI().getCurrencyFormated1(calculation.tt_petfee)}</td>
                                                    <td style={{ width: "10%", textAlign:"right", fontWeight:"bold", fontSize:"15px" }}>{calculation.tt_pets}</td>
                                                    <td style={{ width: "10%", textAlign:"right", fontWeight:"bold", fontSize:"15px" }}>{CisUI().getCurrencyFormated1(calculation.tt_grand)}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </Card>
                                    {/*<Table columns={columns} dataSource={multiRoom} />*/}
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                                    <Button type="primary" htmlType="submit" onClick={() => handleClick(1)}>
                                        <IntlMessages id={"Reserve"} />
                                    </Button>
                                    <Button type="primary" htmlType="submit" onClick={() => handleClick(2)}>
                                        <IntlMessages id={"Reserve & Pay"} />
                                    </Button>
                                    {/*<Button type="info" onClick={() => history.goBack()} htmlType="back"><IntlMessages id={"Cancel"} /></Button>*/}
                                    {/*<Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Cancel"} /></Button>*/}
                                    <Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Back"} /></Button>
                                </Col>
                            </Row>

                        </Form>
                    </Card>
                </Col>

                <Col lg={8} md={8} sm={24} xs={24}>
                    {
                        roomDetails.room_details !== '' &&
                            <RoomDetails details={roomDetails} high_amenities={roomDetails.high_amenities} amenities={roomDetails.amenities}/>
                    }

                </Col>
            </Row>
        </Card>
    );
};

export default NewBooking;
