import React, {useEffect, useState} from "react";
import {Form, Card, Col, Row, Button, Select, Input, DatePicker, Divider, Checkbox, Radio, Image} from 'antd';
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import {useHistory} from "react-router-dom";
import IntlMessages from "../../../../util/IntlMessages";

const {RangePicker} = DatePicker;
const {TextArea} = Input;
const {Option} = Select;

const TaskDetails = (props) => {

    return (
        <p>{props.details}</p>
    );
};

export default TaskDetails;