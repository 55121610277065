import React from "react";
import { Route, Switch } from "react-router-dom";
import NewTemplate from "../../containers/yotta/message/template/NewTemplate";
import TemplateList from "../../containers/yotta/message/template/TemplateList";
import AutomationList from "../../containers/yotta/message/automation/AutomationList";
import AddAutomation from "../../containers/yotta/message/automation/AddAutomation";
// import MailBox from "../../containers/yotta/message/Mail/index";
import Mailv2 from "../../containers/yotta/message/Mail/Mailv2";

const Message = ({ match }) => (
    <Switch>
        <Route path={`${match.url}/templates`} component={TemplateList} />
        <Route path={`${match.url}/newtemplate`} component={NewTemplate} />
        <Route path={`${match.url}/edittemplate/:id`} component={NewTemplate} />
        <Route path={`${match.url}/automation`} component={AutomationList} />
        <Route path={`${match.url}/addautomation`} component={AddAutomation} />
        <Route
            path={`${match.url}/editautomation/:id`}
            component={AddAutomation}
        />
        <Route path={`${match.url}/mails`} component={Mailv2} />
        {/* <Route path={`${match.url}/mails1`} component={Mailv2} /> */}
    </Switch>
);

export default Message;
