import React from "react";
import {Route, Switch} from "react-router-dom";

import NewBooking from "../../containers/yotta/booking/booking_info/NewBooking";
import BookingHistory from "../../containers/yotta/booking/booking_info/BookingHistory";
import BookingReqDetails from "../../containers/yotta/booking/booking_request/BookingReqDetails";
import BookingReqDeclined from "../../containers/yotta/booking/booking_request/BookingReqDeclined";
import BookingRequest from "../../containers/yotta/booking/booking_request/BookingRequest";
import Payment from "../../containers/yotta/booking/booking_info/payment/Payment";
import RetrievePaymentIntent from "../../containers/yotta/booking/booking_info/payment/RetrievePaymentIntent";
import BookingDetails from "../../containers/yotta/booking/booking_info/BookingDetails";


const Booking = ({match}) => (
    <Switch>
        <Route path={`${match.url}/newbooking`} component={NewBooking}/>
        <Route path={`${match.url}/bookinghistory`} component={BookingHistory}/>
        <Route path={`${match.url}/bookingdetails/:id`} component={BookingDetails}/>
        {/* <Route path={`${match.url}/bookingrequest`} component={BookingRequest}/> */}
        <Route path={`${match.url}/bookingrequest`} component={BookingRequest}/>
        <Route path={`${match.url}/bookingreqdetails/:id`} component={BookingReqDetails}/>
        <Route path={`${match.url}/payment/:id`} component={Payment}/>
        <Route path={`${match.url}/retrievePayment`} component={RetrievePaymentIntent}/>
        {/*<Route path={`${match.url}/bookingreqdeclined/:id`} component={BookingReqDeclined}/>*/}
    </Switch>
);

export default Booking;
