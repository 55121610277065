import React, {useEffect, useState} from "react";
import {Form, Card, Row, Col, Button, Input, Select} from 'antd';
import jsonfile from './source.json';
import {CisUI} from '../../../../util/CISUI';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "axios";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import IntlMessages from "../../../../util/IntlMessages";
import InputField from "../../../../util/InputField";
import CKEditor from "react-ckeditor-component";
import InputFieldV2 from "../../../../util/InputFieldV2";
import SelectData from "../../../../util/selectdata";

const {Option} = Select;
const AddNewSource = (props) => {

    const [form] = Form.useForm();
    const history = useHistory();
    const [ck, setCK] = useState("");
    const userID = props.match.params.id;
    let userData = [];
    let details = "";

    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + jsonfile.urls.list;
    let status = SelectData('userstatus');

    const [source, setSource] = useState("");
    const [image, setImage] = useState("");
	const [editImg, setEditImg] = useState("");

    const changeImage = (e) => {
        let file = e.target.files[0];

        let reader = new FileReader();

        if(e.target.files.length !== 0) {
            setSource(URL.createObjectURL(file));
        }
        else {
            setSource('')
        }

        reader.onload = (e) => {
            setImage({
                image: e.target.result,
            });
        };
        reader.readAsDataURL(file);
    };

    if (isEdit === 1) {
        userData = RemoteJson(jsonfile.urls.view + "/" + userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;

        form.setFieldsValue({
            'name': userData.name,
            'status': parseInt(userData.status)
        })

        // setImage(userData.logo)
    }

    useEffect(() => {
		setEditImg(userData.logo)
    }, [userData]);


    const onFinish = (values) => {
        // console.log(values)
        const data = {
            ...values,
            details: ck,
            image: image['image']
        }
        console.log(data);
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg);
                    history.push(redirectto);
                } else {
                    CisUI().Notification('error', res.data.msg);
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    return (
        <Card title={<IntlMessages id={Title}/>} extra={
            isEdit === 1
                ? CisUI().addAction('../../' + jsonfile.urls.list)
                : CisUI().addAction('../' + jsonfile.urls.list)
        }>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{remember: true}}
            >
                {/*{InputFieldV2(jsonfile.input, userData, isEdit, props, '', redirectto)}*/}
                <Row>
                    <Col lg={8} md={8} sm={12} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name="name"
                                label="Source Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Source Name is required'
                                    }
                                ]}
                            >
                                <Input  placeholder="Source Name"/>
                            </Form.Item>
                        </div>
                    </Col>

                    <Col lg={8} md={8} sm={12} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name="status"
                                label="Status"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Status is required'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select an option"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    // defaultValue={`${InV}`}
                                >
                                    {
                                        status.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                    </Col>

                    <Col lg={8} md={8} sm={12} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name="image"
                                label="Image"
                            >
                                <input type="file" onChange={changeImage} multiple/>
                                {
                                    source != '' &&
                                    <img src={source} alt="" style={{ width: "120px", height: "100px", marginTop: "10px" }}/>
                                }
	                            {
									editImg != '' &&
		                            <img src={Config.image_url+'upload/source/'+editImg} alt="" style={{ width: "120px", height: "100px", marginTop: "10px" }}/>
	                            }
                                {/*{*/}
                                {/*    image != '' &&*/}
                                {/*    <img src={Config.image_url + image} alt="" style={{ width: "120px", height: "100px", marginTop: "10px" }}/>*/}
                                {/*}*/}
                                {/*<button className="btn btn-primary btn-sm">x</button>*/}
                                {/*{*/}
                                {/*    error !== '' && <p className="text-danger font-weight-bolder">{error}</p>*/}
                                {/*}*/}
                                {/*<div className="row" style={{marginTop: "10px"}}>*/}
                                {/*    {*/}
                                {/*        editImages?.map((item, index) =>*/}
                                {/*            <div className="col-md-2" key={index}>*/}
                                {/*                <img src={Config.property_info_image + item.image} alt="Image"*/}
                                {/*                     style={{width: "150px", height: "120px"}}/>*/}
                                {/*                <button type="button" className="btn btn-secondary btn-sm remove-btn"*/}
                                {/*                        onClick={() => deleteImg(item.id)}>x*/}
                                {/*                </button>*/}
                                {/*            </div>*/}
                                {/*        )*/}
                                {/*    }*/}
                                {/*</div>*/}

                                {/*<div className="row" style={{marginTop: "10px"}}>*/}
                                {/*    {*/}
                                {/*        src !== '' && src.map((item, index) =>*/}
                                {/*            <div className="col-md-2" key={index}>*/}
                                {/*                <img src={item} alt="Hello" style={{width: "150px", height: "120px"}}/>*/}
                                {/*                <button className="btn btn-secondary btn-sm remove-btn"*/}
                                {/*                        onClick={() => removeImage(item)}>x*/}
                                {/*                </button>*/}
                                {/*            </div>*/}
                                {/*        )*/}
                                {/*    }*/}
                                {/*</div>*/}

                            </Form.Item>
                        </div>
                    </Col>

                </Row>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            <IntlMessages id={"Submit"}/>
                        </Button>
                        {/*<Button type="info" onClick={() => history.goBack()} htmlType="back"><IntlMessages id={"Cancel"} /></Button>*/}
                        {/*<Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Cancel"} /></Button>*/}
                        <Button type="info" onClick={() => history.push(redirectto)}><IntlMessages
                            id={"Back"}/></Button>
                    </Col>
                </Row>

                {/*<Row gutter={24}>*/}
                {/*    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>*/}
                {/*        <Button type="primary" htmlType="submit">*/}
                {/*            <IntlMessages id={"Submit"} />*/}
                {/*        </Button>*/}
                {/*        <Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Back"} /></Button>*/}
                {/*    </Col>*/}

                {/*</Row>*/}
            </Form>
        </Card>
    );
};

export default AddNewSource;