import React from "react";
import {Route, Switch} from "react-router-dom";
import PageList from "../../containers/yotta/partner_cms/page/PageList";
import NewPage from "../../containers/yotta/partner_cms/page/NewPage";
import BannerList from "../../containers/yotta/partner_cms/banner/BannerList";
import NewBanner from "../../containers/yotta/partner_cms/banner/NewBanner";
import BlogList from "../../containers/yotta/partner_cms/blog/BlogList";
import NewBlog from "../../containers/yotta/partner_cms/blog/NewBlog";
import NewWidget from "../../containers/yotta/partner_cms/widgets/NewWidget";
import WidgetList from "../../containers/yotta/partner_cms/widgets/WidgetList";
import SiteSetupList from "../../containers/yotta/partner_cms/setup/SiteSetupList";
import NewSiteSetup from "../../containers/yotta/partner_cms/setup/NewSiteSetup";



const PartnerCMS = ({match}) => (
    <Switch>
        <Route path={`${match.url}/pages`} component={PageList}/>
        <Route path={`${match.url}/newpage`} component={NewPage}/>
        <Route path={`${match.url}/editpage/:id`} component={NewPage}/>
        <Route path={`${match.url}/banners`} component={BannerList}/>
        <Route path={`${match.url}/newbanner`} component={NewBanner}/>
        <Route path={`${match.url}/editbanner/:id`} component={NewBanner}/>
        <Route path={`${match.url}/blogs`} component={BlogList}/>
        <Route path={`${match.url}/newblog`} component={NewBlog}/>
        <Route path={`${match.url}/editblog/:id`} component={NewBlog}/>
        <Route path={`${match.url}/newwidget`} component={NewWidget}/>
        <Route path={`${match.url}/widgets`} component={WidgetList}/>
        <Route path={`${match.url}/editwidget/:id`} component={NewWidget}/>
        <Route path={`${match.url}/sitesetup`} component={SiteSetupList}/>
        <Route path={`${match.url}/editsetup/:id`} component={NewSiteSetup}/>
    </Switch>
);

export default PartnerCMS;