import React, { useEffect, useState } from "react";
import { Form, Card, Col, Row, Button, Checkbox } from 'antd';
import jsonfile from './amenities.json'
import { CisUI } from '../../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../../util/config";
import axios from "../../../../../util/Api";
import { useHistory } from "react-router-dom";
import IntlMessages from "../../../../../util/IntlMessages";
import CircularProgress from "../../../../../components/CircularProgress";

const Amenities = (props) => {
    const [amenities, setEmenities] = useState([]);
    const [loading, setLoading] = useState(false);

    const getAmenities = () => {
        axios.get(Config.apiserver + 'settings/amenities', CisUI().HeaderRequest)
            .then(res => {
                setEmenities(res.data.data)
            })
            .catch(error => console.log(error))
    }


    useEffect(() => {
        getAmenities();
    }, []);


    const userID = props.editId;
    const editdata = props.editData;
    const high_amenities = editdata.high_amenities;
    const editamenities = editdata.amenities;
    // console.log(editdata)

    let Title = jsonfile.edittitle;
    let endpoint = jsonfile.urls.edit + "/" + userID;
    let redirectto = "../../property/roominfos";

    // console.log(userData);

    const history = useHistory();

    const onFinish = (values) => {
        // console.log(values);
        // console.log(values, endpoint);
        let data = {
            ...values,
            high_amenities: values['high_amenities'] === undefined ? high_amenities : values['high_amenities'],
            amenities: values['amenities'] === undefined ? editamenities : values['amenities']
        }
        // console.log(data);
        setLoading(true)
        axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if (res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg);
                }
                else {
                    CisUI().Notification('error', res.data.msg);
                }
                setLoading(false)
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    return (
        <>
            {loading ? <CircularProgress /> : ''}

            <Card title={<IntlMessages id={Title} />}>

                <div id="loader1" className="loading_view" style={{ display: 'none' }}>
                    <div className="loading_center">
                        <div className="loader-table">
                            <img style={{ background: 'transparent' }} src={Config.loader} alt="e2m" />
                        </div>
                        <h4 style={{ textAlign: 'center' }}>Please wait...</h4>
                    </div>
                </div>

                <Form
                    // form={form}
                    {...CisUI().formItemLayout}
                    name="input"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                    initialValues={{ remember: true }}
                >

                    <Row gutter={24}>
                        <Col lg={24} md={24} sm={24} xs={24}>
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="high_amenities"
                                    label="Highlighted Amenities"
                                    valuePropName="checked"
                                >

                                    <Checkbox.Group defaultValue={high_amenities}>
                                        {
                                            amenities.map((item, index) =>
                                                <Checkbox key={index} type="checkbox" id="facilities" value={item.id}>{item.name}</Checkbox>
                                            )
                                        }
                                    </Checkbox.Group>
                                </Form.Item>

                            </div>
                        </Col>

                        <Col lg={24} md={24} sm={24} xs={24}>
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="amenities"
                                    label="Amenities"
                                    valuePropName="checked"
                                >

                                    <Checkbox.Group defaultValue={editamenities}>
                                        {
                                            amenities.map((item, index) =>
                                                <Checkbox key={index} type="checkbox" id="facilities" value={item.id}>{item.name}</Checkbox>
                                            )
                                        }
                                    </Checkbox.Group>
                                </Form.Item>

                            </div>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col lg={24} md={24} sm={12} xs={24} style={{ textAlign: 'center' }}>
                            <Button type="primary" htmlType="submit">
                                <IntlMessages id={"Submit"} />
                            </Button>
                            <Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Back"} /></Button>
                        </Col>
                    </Row>

                </Form>
            </Card>
        </>
    );
};

export default Amenities;