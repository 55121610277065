import React, {useState, useEffect} from 'react';
import {Card, Col, Popover, Row, Form, DatePicker, Collapse, Button, Select, Modal} from "antd";
import Config from "../../../../util/config";
import axios from 'axios';
import {CisUI} from "../../../../util/CISUI";
import IntlMessages from "../../../../util/IntlMessages";
import RemoteJson from "../../../../util/gerremotedata";
import {Link} from "react-router-dom";
import {Calendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment';
import DataImportModal from "./DataImportModal";
import DataExportModal from "./DataExportModal";

const {RangePicker} = DatePicker;
const Panel = Collapse.Panel;
const {Option} = Select;
moment.locale('en-us');
const localizer = momentLocalizer(moment);


const BookingCalendar = (props) => {
  const [form] = Form.useForm();
	const [days, setDays] = useState([]);
	const [data, setData] = useState([]);
	const [title, setTitle] = useState("");

	let roomlists = RemoteJson('property/roominfos');

	const getData = () => {
		document.getElementById("loader").style.display = "block";
		axios.get(Config.apiserver + "channelmanager")
			.then(res => {
				if (res.data.status === 1) {
					setTitle(res.data.title);
					setData(res.data.data)
					setDays(res.data.days)
				} else {
					console.log("Failed");
				}
				document.getElementById("loader").style.display = "none";
			})
			.catch(error => console.log(error.response))
	}

	useEffect(() => {
		getData()

	}, []);

	const onFinish = values => {

		let data = '';
		if (values['month'] === null || values['month'] === undefined) {
			data = {
				room_id: values['room_id'],
			}
		} else {
			data = {
				room_id: values['room_id'],
				start_month: values['month'].format('YYYY-MM')
			}
		}


		const qs = Object.keys(data).map(key => `${key}=${data[key]}`).join('&');
		const qsup = qs.replaceAll("undefined", "");
		console.log(qsup);
		document.getElementById("loader").style.display = "block";
		axios.get(Config.apiserver + 'channelmanager' + "/?" + qsup)
			.then(res => {
				console.log(res)
				if (res.data.status == 1) {
					setTitle(res.data.title);
					setData(res.data.data)
					setDays(res.data.days)
				} else {
					console.log("failed")
				}
				document.getElementById("loader").style.display = "none";
			})
			.catch(error => console.log(error.response))
	};

	const bookingDetails = (details) => {

		if (details !== undefined) {
			let content = (
				<div>
					{/*<p style={{ fontSize: "17px", color: "#1e2b5d", marginBottom: "10px", textAlign:"center" }}>Booking Details</p>*/}
					<div style={{marginLeft: "15px"}}>
						<p style={{margin: "10px 0 7px 0", color: "#1e2b5d", fontSize: "17px"}}>Booking Details</p>
						<p>Booking ID: <span style={{color: "#1e2b5d"}}>{details.unqid}</span></p>
						<p style={{margin: "5px 0"}}>From <span
							style={{color: "#1e2b5d"}}>{details.check_in_date}</span> To <span
							style={{color: "#1e2b5d"}}>{details.check_out_date}</span></p>
						{/*<i className="icon icon-calendar" style={{ color:"#1e2b5d" }}/>*/}
						<div style={{display: "flex", margin: "0 0 5px 0"}}>
							<p><span style={{color: "#1e2b5d"}}>{details.guest}</span> Guest</p>
							<p style={{margin: "0 0 0 10px"}}><span
								style={{color: "#1e2b5d"}}>{details.nights}</span> Nights</p>
						</div>

					</div>

					<div style={{marginLeft: "15px"}}>
						<p style={{margin: "10px 0 7px 0", color: "#1e2b5d", fontSize: "17px"}}>Guest Details</p>
						<p><i className="icon icon-user-o" style={{
							color: "#1e2b5d",
							verticalAlign: "middle",
							padding: "0 5px 0 0"
						}}/> {details.customer_name}</p>
						<p><i className="icon icon-phone" style={{
							color: "#1e2b5d",
							verticalAlign: "middle",
							padding: "0 5px 0 0"
						}}/> {details.customer_phone}</p>
						<p><i className="icon icon-email" style={{
							color: "#1e2b5d",
							verticalAlign: "middle",
							padding: "0 5px 0 0"
						}}/> {details.customer_email}</p>
					</div>

					<div style={{marginLeft: "15px"}}>
						<p style={{margin: "10px 0 7px 0", color: "#1e2b5d", fontSize: "17px"}}>Property Information</p>
						<p>Property Name: <span style={{color: "#1e2b5d"}}>{details.property_name}</span></p>
						<p>Room Name: <span style={{color: "#1e2b5d"}}>{details.room_name}</span></p>
					</div>

					<div style={{marginLeft: "15px"}}>
						<p style={{margin: "10px 0 7px 0", color: "#1e2b5d", fontSize: "17px"}}>Transaction History</p>
						<p>Total: <span style={{color: "#1e2b5d"}}>{CisUI().getCurrencyFormated1(details.total)}</span>
						</p>
						<p>Discount Amount: <span
							style={{color: "#1e2b5d"}}>{CisUI().getCurrencyFormated1(details.discount_amount)}</span>
						</p>
						<p>Grand Total: <span
							style={{color: "#1e2b5d"}}>{CisUI().getCurrencyFormated1(details.grand_total)}</span></p>
					</div>
				</div>


			);
			return [content]
		} else {
			return (
				<Link to={`/booking/newbooking`} style={{marginBottom: "0px"}}>New Booking</Link>
			)
		}
	}

	const [visible, setVisible] = useState(false);
	const [exportVisible, setExportVisible] = useState(false);
	const [modalTitle, setModalTitle] = useState("");
	const [exportModalTitle, setExportModalTitle] = useState("");
	const [roomID, setRoomID] = useState(0);

	const showModal = (id, name) => {
		setVisible(true)
		setRoomID(id)
		setModalTitle(name)
	}

	const handleCallback = (childData) => {
		// console.log(childData);
		setVisible(childData)
	}

	//For Single Room Calendar
	const [showCalendar, setShowCalendar] = useState(false);
	const [calendarTitle, setCalendarTitle] = useState(false);
	const [calendarData, setCalendarData] = useState([]);

	const getCalendarData = (id) => {
		axios.get(Config.apiserver+'showcalendardata/'+id, CisUI().HeaderRequest)
			.then(res =>{
				console.log(res.data)
				if(res.data.status === 1) {
					setCalendarData(res.data.data)
				}
				// else {
				// 	CisUI().Notification('error', res.data.msg)
				// }

			})
			.catch(error => console.log(error))
	}


	const handleCalendar = (id, room_name) => {
		setCalendarTitle(room_name)
		setShowCalendar(true)
		getCalendarData(id)
	}

	const handleCancelCalendar = () => {
		setShowCalendar(false)
	}

	useEffect(() => {
		getCalendarData()
	}, [])

	//End Single Room Calendar

	// For Export ICAL Data
	const [link, setLink] = useState("");
	const ExportData = (id) => {

		// document.getElementById("loader3").style.display = "block";
		axios.get(Config.apiserver+'exportical/'+id, CisUI().HeaderRequest)
			.then(res =>{
				console.log(res)
				if(res.data.status === 1) {
					setLink(res.data.url)
					// CisUI().Notification('success', res.data.msg)
					// isExportShow(false)
				}
				// else {
				// 	CisUI().Notification('error', res.data.msg)
				// }
				// document.getElementById("loader3").style.display = "none";
			})
			.catch(error => console.log(error))
	}

	const showExportModal = (id, name) => {
		// console.log(id);
		setExportVisible(true)
		setExportModalTitle(name)
		ExportData(id)
	}

	const handleExportCallback = (childData) => {
		// console.log(childData);
		setExportVisible(childData)
	}

	useEffect(() => {
		ExportData()
	}, [])

	//End Export ICAL Data

	const content = (room_id, room_name) => {
		return <div>
					<p onClick={() => showModal(room_id, room_name)} style={{cursor:"pointer"}}>
						{/*<Link to={mprefix+prefix.edit+`/${id}`}>*/}
						<button className="btn btn-primary btn-circle" >
							<i className="icon icon-upload"/>
						</button> <IntlMessages id="Import"/>
						{/*</Link>*/}
					</p>
					<p onClick={() => showExportModal(room_id, room_name)} style={{cursor:"pointer"}}>
						<button className="btn btn-danger btn-circle">
							<i className="icon icon-sent"/>
						</button> <IntlMessages id="Export" />
					</p>
				</div>
	}

	return (
		<>
			<Row>
				<Col lg={24} md={24} xs={24}>

					<Card className="advance-search-card">
						<Collapse
							accordion
							expandIconPosition="right"
							defaultActiveKey={['1']}>
							<Panel header={<IntlMessages id="Advance Search"/>} key="1">
								<Form
									key={5}
									form={form}
									{...CisUI().formItemLayout}
									name="advanced_search"
									className="ant-advanced-search-form"
									onFinish={onFinish}
								>
									<Row gutter={24}>
										<Col lg={6} md={6} sm={12} xs={12}>
											<Form.Item
												name="room_id"
												label="Room"
											>
												<Select
													showSearch
													allowClear={true}
													placeholder="Select an option"
													optionFilterProp="children"
													filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												>
													{
														roomlists.map((items, index) =>
															<Option key={++index} value={items.id}>{items.name}</Option>
														)
													}
												</Select>
											</Form.Item>
										</Col>
										<Col lg={6} md={6} sm={12} xs={12}>
											<div className="gx-form-row0">
												<Form.Item
													name="month"
													label="Month"
												>
													<DatePicker className="gx-w-100" picker="month"/>
												</Form.Item>
											</div>
										</Col>

										<Col lg={6} md={6} sm={12} xs={24} style={{textAlign: 'left'}}>
											<br/>
											<Button type="primary" className="searchbutton2" htmlType="submit">
												<i class="fas fa-search"/> <IntlMessages id={"Search"}/>
											</Button>
										</Col>

									</Row>
								</Form>
							</Panel>
						</Collapse>
					</Card>

				</Col>
				<Col lg={24} md={24} xs={24}>
					<Card extra={
						<Link to={`/channelmanager/importical`} className="ant-btn ant-btn-primary">Import ICal</Link>
					}>
						{CisUI().showLoading}
						<h2 className="calendar_title">{title}</h2>
						<div className="table-responsive">
							<table className="calendar_table table table-bordered" id="calendar_table">
								<thead>
								<tr>
									<th className="first_col">Room\Days</th>
									{
										days?.map((item, i) =>

											<th className="rest_col" key={++i}>
												<span style={{
													color: "#aaa",
													fontSize: "13px"
												}}>{item.split(',')[1]}</span>
												<br/>
												<span style={{
													color: "#1e2b5d",
													fontSize: "18px"
												}}>{item.split(',')[0]}</span>
											</th>
										)
									}
								</tr>
								</thead>
								<tbody>
								{
									data?.map((item1, i) =>
										<tr key={++i}>
											<td className="first_col"
											>
												<Popover placement="leftBottom" trigger="click" content={() => content(item1.room_id, item1.room_name)}>
													<button className="btn btn-primary btn-circle"><i className="fas fa-ellipsis-v"></i></button>
												</Popover>
												<span onClick={() => handleCalendar(item1.room_id,item1.room_name)}
													  style={{cursor: "pointer", marginLeft:"5px"}}>{item1.room_name}</span>
											</td>
											{
												item1?.dates?.map((date, id) =>
													<Popover
														content={() => bookingDetails(date.booking_details)}
														trigger="click" key={++id}>

														<td key={++id}>
															<span><img src={date.booked.split(',')[0]} alt="" style={{
																width: "20px",
																marginTop: "5px"
															}}/></span>
															{
																date.booked.split(',')[1] && (
																	<>
																		<br/>
																		<span style={{
																			fontSize: "10px",
																			padding: "5px"
																		}}>{date.booked.split(',')[1]}</span>
																	</>
																)
															}
														</td>
													</Popover>
												)
											}
										</tr>
									)
								}
								</tbody>
							</table>

						</div>
					</Card>
				</Col>
			</Row>

			<Modal
				visible={showCalendar}
				title={<><span style={{ color: "#1e2b5d"}}>Room Name:  {calendarTitle}</span></>}
				cancelText="Cancel"
				onCancel={handleCancelCalendar}
				width={1000}
			>

				<Calendar
					localizer={localizer}
					{...props}
					events={calendarData}
					step={60}
				/>

			</Modal>

			<DataImportModal
				visible={visible}
				isShow={handleCallback}
				title={modalTitle}
				roomID={roomID}
			/>

			<DataExportModal
				exportVisible={exportVisible}
				isExportShow={handleExportCallback}
				exporttitle={exportModalTitle}
				link={link}
			/>

		</>
	)
}

export default BookingCalendar;
