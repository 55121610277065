import React from "react";
import {Route, Switch} from "react-router-dom";

import ShowChannelManager from "../../containers/yotta/booking/channel_manager/ChannelManager";
import ImportICAL from "../../containers/yotta/booking/channel_manager/ImportICAL";
import AddIcal from "../../containers/yotta/booking/channel_manager/AddIcal";
import ChannelManagerv2 from "../../containers/yotta/booking/channel_manager/ChannelManagerv2";
// import ChannelManagerSchedular from "../../containers/yotta/booking/channel_manager/ChannelManagerSchedular";



const ChannelManager = ({match}) => (
    <Switch>
        <Route path={`${match.url}/calendar`} component={ChannelManagerv2}/>
        <Route path={`${match.url}/calendar1`} component={ShowChannelManager}/>
        <Route path={`${match.url}/importical`} component={ImportICAL}/>
        <Route path={`${match.url}/addical`} component={AddIcal}/>
        {/*<Route path={`${match.url}/schedular`} component={ChannelManagerSchedular}/>*/}
    </Switch>
);

export default ChannelManager;
