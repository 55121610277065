import React, {useEffect, useState} from "react";
import {Form, Card, Col, Row, Button, Select, Input, DatePicker, Divider, Radio} from 'antd';
import jsonfile from './task.json'
import {CisUI} from '../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../util/config";
import axios from "../../../util/Api";
import {useHistory} from "react-router-dom";
import IntlMessages from "../../../util/IntlMessages";

const {RangePicker} = DatePicker;
const {TextArea} = Input;
const {Option} = Select;

const AddTask = (props) => {
    const [image, setImage] = useState("");
    const [rooms, setRooms] = useState([]);
    const [cleaningRooms, setCleaningRooms] = useState([]);
    const [staffs, setStaffs] = useState([]);
    const [todos, setToDos] = useState([]);
    const [taskGroup, setTaskGroup] = useState([]);
    const [source1, setSource1] = useState(true);
    const [source2, setSource2] = useState(false);
    const [source3, setSource3] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();
    const userID = props.match.params.id;

    const handleSource = (e) => {
        let value = e.target.value
        if(value == 1){
            setSource1(true)
        }
        else{
            setSource1(false)
        }

        if (value == 2){
            setSource2(true)
        }
        else {
            setSource2(false)
        }

        if(value == 3){
            setSource3(true)
        }
        else {
            setSource3(false)
        }
        console.log(e.target.value)
    }

    const getRooms = () => {

        // document.getElementById("loader").style.display = "block";
        axios.get(Config.apiserver+`property/roominfos`, CisUI().HeaderRequest)
            .then(res => {
                if(res.data.status === 1) {
                    setRooms(res.data.data)
                }
                else {
                    CisUI().Notification('warning', 'Failed')
                }
                // document.getElementById("loader").style.display = "none";
            })
            .catch(error => console.log(error.response))
    }

    const getCleaningRooms = () => {

        // document.getElementById("loader").style.display = "block";
        axios.get(Config.apiserver+`task/managerooms`, CisUI().HeaderRequest)
            .then(res => {
                if(res.data.status === 1) {
                    setCleaningRooms(res.data.data)
                }
                else {
                    CisUI().Notification('warning', 'Failed')
                }
                // document.getElementById("loader").style.display = "none";
            })
            .catch(error => console.log(error.response))
    }

    const getStaffs = () => {

        axios.get(Config.apiserver+`staff/staffs`, CisUI().HeaderRequest)
            .then(res => {
                if(res.data.status === 1) {
                    setStaffs(res.data.data)
                }
                else {
                    CisUI().Notification('warning', 'Failed')
                }
            })
            .catch(error => console.log(error.response))
    }

    const getTaskGroup = () => {

        axios.get(Config.apiserver+`task/groups`, CisUI().HeaderRequest)
            .then(res => {
                if(res.data.status === 1) {
                    setTaskGroup(res.data.data)
                }
                else {
                    CisUI().Notification('warning', 'Failed')
                }
            })
            .catch(error => console.log(error.response))
    }

    const getTaskToDos = (values) => {
        console.log(values)

        axios.get(Config.apiserver + `task/grouptodos/` + values, CisUI().HeaderRequest)
            .then(res => {
                if (res.data.status === 1) {
                    let data = [...todos];
                    data.push(res.data.data);
                    // data.push(res.data.data)
                    setToDos(data);
                } else {
                    CisUI().Notification('warning', 'Failed')
                }
                // document.getElementById("loader").style.display = "none";
            })
            .catch(error => console.log(error.response))
    }

    const handleClear = (e) => {
        let new_arr = todos.filter((item, index) => item.id !== e)
        setToDos(new_arr)
    }


    useEffect(() => {
        getRooms()
        getCleaningRooms()
        getStaffs()
        getTaskGroup()
    }, []);

    const [roomDetails, setRoomDetails] = useState('');

    const handleCleaningRoom = (id) => {
        axios.get(Config.apiserver + `task/roomopen/` + id, CisUI().HeaderRequest)
            .then(res => {
                if(res.data.status === 1){
                    // setRoomDetails(res.data.data.details)
                    form.setFieldsValue({
                        details: res.data.data.details
                    })
                }
            })
    }

    useEffect(() => {
        handleCleaningRoom()
    }, [roomDetails]);


    const changeImage = (e) => {
        let file = e.target.files[0];

        const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            form.setFieldsValue({
                attachment: ""
            })
            CisUI().Notification('error', 'You can only upload jpg, png,and pdf file only!');
        } else {

            let reader = new FileReader();
            // console.log(reader)
            reader.onload = (e) => {
                // console.log(e)
                setImage({
                    image: e.target.result
                });
            };
            reader.readAsDataURL(file);
        }
    }

    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    const onFinish = (values) => {

        let data = {
            ...values,
            start_date: values['date'][0].format('YYYY-MM-DD HH:mm:ss'),
            end_date: values['date'][1].format('YYYY-MM-DD HH:mm:ss'),
            attachment: image['image'],
        }

        // console.log(data)

        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg);
                    history.push(redirectto);
                }
                else {
                    CisUI().Notification('error', res.data.msg);
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });

    };

    return (
        <Card title={<IntlMessages id={Title}/>} extra={CisUI().addAction(redirectto)}>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                <Row gutter={24}>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Card>
                            <Row gutter={24}>

                                <Col lg={24} md={24} sm={24} xs={24}>
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="source"
                                            label="Source"
                                            onChange={handleSource}
                                            rules={[{
                                                required: true,
                                                message: "Source is required"
                                            }]}
                                            initialValue={1}
                                        >
                                            <Radio.Group>
                                                <Radio value={1}>E2M Rooms</Radio>
                                                <Radio value={2}>Cleaning Rooms</Radio>
                                                <Radio value={3}>Others</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                </Col>
                                {
                                    source1 === true &&
                                    <>
                                    {/*<Col lg={24} md={24} sm={24} xs={24}>*/}
                                        <Divider orientation={"left"}>E2M Room</Divider>
                                    {/*</Col>*/}
                                        <Col lg={12} md={12} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <Form.Item
                                                    name="room_id"
                                                    label="E2M Rooms"
                                                    rules={[{
                                                        required: true,
                                                        message: "Room is required"
                                                    }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="Select an option"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase())}
                                                        // onChange={handleChange}
                                                        // disabled={rooms != '' ? false : true}
                                                        allowClear={true}
                                                        loading={rooms.length > 0 ? false : true}
                                                    >
                                                        {
                                                            rooms.map((item, index) =>
                                                                <Option value={item.id}>{item.name}</Option>
                                                            )
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </>
                                }

                                {
                                    source2 === true &&
                                    <>
                                        <Divider orientation={"left"}>Cleaning Room</Divider>
                                        <Col lg={12} md={12} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <Form.Item
                                                    name="room_id"
                                                    label="Cleaning Rooms"
                                                    rules={[{
                                                        required: true,
                                                        message: "Room is required"
                                                    }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="Select an option"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase())}
                                                        onChange={handleCleaningRoom}
                                                        // disabled={rooms != '' ? false : true}
                                                        allowClear={true}
                                                        loading={cleaningRooms.length > 0 ? false : true}
                                                    >
                                                        {
                                                            cleaningRooms.map((item, index) =>
                                                                <Option value={item.id}>{item.name}</Option>
                                                            )
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </>
                                }

                                {
                                    source3 === true &&
                                    <>
                                        {/*<Row gutter={24}>*/}
                                        <Divider orientation={"left"} plain>Other Room Details</Divider>
                                            <Col lg={12} md={12} sm={12} xs={24}>
                                                <div className="gx-form-row0">
                                                    <Form.Item
                                                        name="room_name"
                                                        label="Room Name"
                                                        rules={[{
                                                            required: true,
                                                            message: "Room Name is required"
                                                        }]}
                                                    >
                                                        <Input placeholder="Room Name"/>
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={24}>
                                                <div className="gx-form-row0">
                                                    <Form.Item
                                                        name="phone"
                                                        label="Phone"
                                                        rules={[{
                                                            required: true,
                                                            message: "Phone is required"
                                                        }]}
                                                    >
                                                        <Input placeholder="Phone"/>
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={24}>
                                                <div className="gx-form-row0">
                                                    <Form.Item
                                                        name="email"
                                                        label="Email"
                                                        rules={[{
                                                            required: true,
                                                            message: "Email is required"
                                                        }]}
                                                    >
                                                        <Input placeholder="Email"/>
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={24}>
                                                <div className="gx-form-row0">
                                                    <Form.Item
                                                        name="address"
                                                        label="Address"
                                                        rules={[{
                                                            required: true,
                                                            message: "Address is required"
                                                        }]}
                                                    >
                                                        <Input placeholder="Address"/>
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <Form.Item
                                                    name="url"
                                                    label="Location URL"
                                                    rules={[{
                                                        required: true,
                                                        message: "Location URL is required"
                                                    }]}
                                                >
                                                    <Input placeholder="Location URL"/>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        {/*</Row>*/}
                                    </>
                                }

                                <Divider orientation={"left"} plain>Task Details</Divider>
                                <Col lg={12} md={12} sm={12} xs={24}>
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="staff_id"
                                            label="Staff"
                                            rules={[{
                                                required: true,
                                                message: "Staff is required"
                                            }]}
                                        >
                                            <Select
                                                mode="multiple"
                                                allowClear
                                                // defaultValue={[119, 120]}
                                                style={{ width: '100%' }}
                                                showSearch
                                                placeholder="Select an option"
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase())}
                                                loading={staffs.length > 0 ? false : true}
                                            >
                                                {
                                                    staffs.map((item, index) =>
                                                        <Option value={item.id}>{item.name}</Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Col>

                                <Col lg={12} md={12} sm={12} xs={24}>
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="title"
                                            label="Task Title"
                                            rules={[{
                                                required: true,
                                                message: "Task title is required"
                                            }]}
                                        >
                                            <Input placeholder="Task Title"/>
                                        </Form.Item>
                                    </div>
                                </Col>

                                <Col lg={12} md={12} sm={12} xs={24}>
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="date"
                                            label="Date"
                                            rules={[{
                                                required: true,
                                                message: "Task title is required"
                                            }]}
                                        >
                                            <RangePicker className="gx-w-100" showTime/>
                                        </Form.Item>
                                    </div>
                                </Col>

                                <Col lg={12} md={12} sm={12} xs={24}>
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="details"
                                            label="Details"
                                            rules={[{
                                                required: false,
                                                message: "Details is required"
                                            }]}
                                        >
                                            <TextArea className="gx-w-100" placeholder="Details"/>
                                        </Form.Item>
                                    </div>
                                </Col>

                                <Col lg={12} md={12} sm={12} xs={24}>
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="status"
                                            label="Status"
                                            rules={[{
                                                required: true,
                                                message: "Status is required"
                                            }]}
                                            initialValue={0}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select an option"
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase())}
                                            >
                                                <Option value={0}>Pending</Option>
                                                <Option value={1}>Finished</Option>
                                                <Option value={2}>Working</Option>
                                                <Option value={3}>Reassign</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Col>

                                <Col lg={12} md={12} sm={24} xs={24}>
                                    <div className="gx-form-row0">
                                        <Form.Item
                                            name="attachment"
                                            label="Attachment"
                                        >
                                            <input type="file" onChange={changeImage}/>
                                        </Form.Item>
                                    </div>
                                </Col>


                                <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center', marginTop:"30px"}}>
                                    <Button type="primary" htmlType="submit">
                                        <IntlMessages id={"Submit"} />
                                    </Button>
                                    {/*<Button type="info" onClick={() => history.goBack()} htmlType="back"><IntlMessages id={"Cancel"} /></Button>*/}
                                    {/*<Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Cancel"} /></Button>*/}
                                    <Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Back"} /></Button>
                                </Col>

                            </Row>
                        </Card>
                    </Col>


                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Card className="custom_todo" style={{ height: "570px"}}>
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="group_id"
                                        label="Task Group"
                                        rules={[{
                                            required: true,
                                            message: "Status is required"
                                        }]}
                                    >
                                        <Select
                                            mode="multiple"
                                            onDeselect={handleClear}
                                            showSearch
                                            placeholder="Select an option"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase())}
                                            onSelect={getTaskToDos}
                                            // allowClear={true}
                                            loading={taskGroup.length > 0 ? false : true}
                                        >
                                            {
                                                taskGroup.map((item, index) =>
                                                    <Option value={item.id}>{item.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </Col>

                            {
                                todos?.map((item, index) =>
                                    <>
                                        <Divider orientation={"left"} plain style={{ fontSize: "16px", color: "#1E2B5D", margin:"10px 0"}}>{item.group_name}</Divider>
                                        {
                                            item.items.map((todo, i) =>
                                                <p style={{width:"100%"}}>
                                                    <span>{todo.title}</span>
                                                </p>

                                            )
                                        }
                                    </>
                                )
                            }
                        </Card>
                    </Col>

                </Row>

            </Form>
        </Card>
    );
};

export default AddTask;
