/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import { Card, Col, Row, Divider, Tabs} from 'antd';
import jsonfile from './task.json'
import {CisUI} from '../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../util/config";
import axios from "../../../util/Api";
import IntlMessages from "../../../util/IntlMessages";
import CircularProgress from "../../../components/CircularProgress";
import TaskDetails from "./task_history_component/TaskDetails";
import RoomInformation from "./task_history_component/RoomInformation";
import MemberActivity from "./task_history_component/MemberActivity";
import IncompleteToDos from "./task_history_component/IncompleteToDos";
import TaskStaffs from "./task_history_component/TaskStaffs";

const TaskHistory = (props) => {
    const [staffs, setStaffs] = useState([]);
    const [unfinishedToDo, setUnfinishedToDo] = useState([]);
    // const [newTodos, setNewToDos] = useState([]);
    // const [newTodoStatus, setNewToDoStatus] = useState(0);
    const [data, setData] = useState([]);
    const [taskHistories, setTaskHistories] = useState({});
    // const [taskGroup, setTaskGroup] = useState([]);

    const userID = props.match.params.id;


    let Title = "Task History";
    let redirectto = "../../"+jsonfile.urls.list;


    const getData = () => {
        axios.get(Config.apiserver + "task/taskhistory/"+ userID, CisUI().HeaderRequest)
            .then(res => {
                console.log(res.data)
                if(res.data.status === 1) {
                    setTaskHistories(res.data.task_histories)
                    setStaffs(res.data.task_members)
                    setUnfinishedToDo(res.data.unfinished_todos)
                    setData(res.data.task)
                    // let source = res.data.task.source;

                }
                else {
                    CisUI().Notification('warning', 'Failed')
                }
            })
            .catch(error => console.log(error.response))
    }

    // const getTaskToDos = (values) => {
    //     console.log(values)

    //     axios.get(Config.apiserver + `task/grouptodos/` + values, CisUI().HeaderRequest)
    //         .then(res => {
    //             if (res.data.status === 1) {
    //                 let data = [...newTodos];
    //                 data.push(res.data.data);
    //                 // data.push(res.data.data)
    //                 setNewToDos(data);
    //             } else {
    //                 CisUI().Notification('warning', 'Failed')
    //             }
    //             // document.getElementById("loader").style.display = "none";
    //         })
    //         .catch(error => console.log(error.response))
    // }

    useEffect(() => {
        getData()
    }, []);


    return (
        <>
        <Card title={<IntlMessages id={Title}/>} extra={CisUI().addAction(redirectto)}>
            {CisUI().showLoading}
            {
                data === '' ? <CircularProgress /> :

                        <Row gutter={24}>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>

                                <Divider orientation={"left"} plain style={{ fontSize: "16px", color: "#1E2B5D", margin:"10px 0"}}>Task Information</Divider>
                                <Row>
                                    <Col lg={8} md={8} sm={12} xs={24} style={{ marginTop:"15px"}}>
                                        <p style={{ fontSize: "14px" }}>Reference ID: <span style={{ fontWeight:"bold", color: "#1E2B5D"}}>{data.reference_id}</span></p>
                                    </Col>
                                    <Col lg={8} md={8} sm={12} xs={24} style={{ marginTop:"15px"}}>
                                        <p style={{ fontSize: "14px" }}>Task Title: <span style={{ fontWeight:"bold", color: "#1E2B5D"}}>{data.title}</span></p>
                                    </Col>
                                    <Col lg={8} md={24} sm={24} xs={24} style={{ marginTop:"15px"}}>
                                        <p style={{ fontSize: "14px" }}>Task Started At: <span style={{ fontWeight:"bold", color: "#1E2B5D"}}>{data.start_date}</span></p>
                                    </Col>
                                    <Col lg={8} md={8} sm={24} xs={24} style={{ marginTop:"15px"}}>
                                        <p style={{ fontSize: "14px" }}>Task Finished At: <span style={{ fontWeight:"bold", color: "#1E2B5D"}}>{data.end_date}</span></p>
                                    </Col>
                                    <Col lg={8} md={8} sm={24} xs={24} style={{ marginTop:"15px"}}>
                                        <p style={{ fontSize: "14px" }}>Task Status:
                                            <span style={{ fontWeight:"bold", color: "#1E2B5D"}}>{data.status === 0 && ' Pending'}</span>
                                            <span style={{ fontWeight:"bold", color: "#1E2B5D"}}>{data.status === 1 && ' Finished'}</span>
                                            <span style={{ fontWeight:"bold", color: "#1E2B5D"}}>{data.status === 2 && ' Working'}</span>
                                            <span style={{ fontWeight:"bold", color: "#1E2B5D"}}>{data.status === 3 && ' Reassign'}</span>
                                        </p>
                                    </Col>

                                    <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop:"15px"}}>

                                        <p style={{ fontSize: "14px" }}>Assigned To:</p>


                                            <Row>
                                                <TaskStaffs data={staffs}/>
                                            </Row>
                                        {/*// </Col>*/}

                                    </Col>
                                </Row>

                                {/*<Divider orientation={"left"} plain style={{ fontSize: "16px", color: "#1E2B5D", margin:"10px 0"}}>Room Information</Divider>*/}
                                {/*<Row>*/}
                                {/*    <Col lg={24} md={24} sm={24} xs={24}  style={{ marginTop:"15px"}}>*/}
                                {/*        <p style={{ fontSize: "14px" }}><i className="icon icon-company"/> Room Name: <span style={{ fontWeight:"bold", color: "#1E2B5D"}}>{data.room_name}</span></p>*/}
                                {/*    </Col>*/}
                                {/*    <Col lg={24} md={24} sm={24} xs={24}  style={{ marginTop:"15px"}}>*/}
                                {/*        <p style={{ fontSize: "14px" }}><i className="icon icon-phone"/> Phone Number: <span style={{ fontWeight:"bold", color: "#1E2B5D"}}>{data.room_phone}</span></p>*/}
                                {/*    </Col>*/}
                                {/*    <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop:"15px"}}>*/}
                                {/*        <p style={{ fontSize: "14px" }}><i className="icon icon-email"/> Email: <span style={{ fontWeight:"bold", color: "#1E2B5D"}}>{data.room_email}</span></p>*/}
                                {/*    </Col>*/}
                                {/*    <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop:"15px"}}>*/}
                                {/*        <p style={{ fontSize: "14px" }}><i className="icon icon-home"/> Address: <span style={{ fontWeight:"bold", color: "#1E2B5D"}}>{data.room_address}</span></p>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}


                                {/*<Divider orientation={"left"} plain style={{ fontSize: "16px", color: "#1E2B5D", margin:"10px 0"}}>All Attachments</Divider>*/}

                            </Col>

                            {/*<Col xl={2} lg={2} md={24} sm={24} xs={24}>*/}
                            {/*</Col>*/}

                            {/*<Col xl={24} lg={24} md={24} sm={24} xs={24}>*/}

                            {/*    */}


                            {/*</Col>*/}

                        </Row>

            }

        </Card>
        <Card>
            <Tabs>
                <Tabs.TabPane tab="Task Details" key="1">
                    <TaskDetails details={data.details}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Room Information" key="2">
                    <RoomInformation data={data}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Members Activity" key="3">
                    <MemberActivity data={taskHistories} staffs={staffs}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Incomplete Task ToDos" key="4">
                    <IncompleteToDos data={unfinishedToDo}/>
                </Tabs.TabPane>

            </Tabs>
        </Card>
    </>
    );
};

export default TaskHistory;