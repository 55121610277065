import React from "react";
import {Layout} from 'antd';
import {useDispatch, useSelector} from "react-redux";
// import CustomScrollbars from "util/CustomScrollbars";
// import languageData from "../languageData";
import HorizontalNav from "../HorizontalNav";
import {Link} from "react-router-dom";
import Config from "../../../util/config"
import { toggleCollapsedSideNav} from "../../../appRedux/actions";
import UserProfile from "../../Topbar/UserProfile";
// import UserInfo from "../../../components/UserInfo";

const {Header} = Layout;
// const Option = Select.Option;
// const menu = (
//     <Menu onClick={handleMenuClick}>
//         <Menu.Item key="1">Products</Menu.Item>
//         <Menu.Item key="2">Apps</Menu.Item>
//         <Menu.Item key="3">Blogs</Menu.Item>
//     </Menu>
// );

// function handleMenuClick(e) {
//     message.info('Click on menu item.');
// }
//
// function handleChange(value) {
//     console.log(`selected ${value}`);
// }

const HorizontalDefault = () => {

    const dispatch = useDispatch();

    // const [searchText, setSearchText] = useState('');
    // const locale = useSelector(({settings}) => settings.locale);
    const {navCollapsed} = useSelector(({common}) => common);

    // const languageMenu = () => (
    //     <CustomScrollbars className="gx-popover-lang-scroll">
    //         <ul className="gx-sub-popover">
    //             {languageData.map(language =>
    //                 <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
    //                     dispatch(switchLanguage(language))
    //                 }>
    //                     <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
    //                     <span className="gx-language-text">{language.name}</span>
    //                 </li>
    //             )}
    //         </ul>
    //     </CustomScrollbars>);

    // const updateSearchChatUser = (evt) => {
    //     setSearchText(evt.target.value)
    // };

    return (
        <div className="gx-header-horizontal">
            <Header
                className="gx-header-horizontal-main">
                <div className="gx-container">
                    <div className="gx-header-horizontal-main-flex">

                        <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3">
                            <i className="gx-icon-btn icon icon-menu"
                               onClick={() => {
                                   console.log("navCollapsed", !navCollapsed)
                                   dispatch(toggleCollapsedSideNav(!navCollapsed));
                               }}
                            />

                        </div>
                        <Link to="/main/dashboard" className="gx-d-block gx-d-lg-none gx-pointer gx-w-logo">
                            <img alt="" src={ Config.topbar_logo } style={{width: "45px", height: "20px"}}/></Link>
                        <Link to="/main/dashboard" className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo">
                            <img alt="" src={ Config.topbar_logo } style={{width: "100px", height: "40px"}}/></Link>

                        <ul className="gx-header-notifications gx-ml-auto">

                            <li className="gx-notify">
                                {/*<Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<AppNotification/>}*/}
                                {/*         trigger="click">*/}
                                {/*<span className="gx-pointer gx-d-block"><i className="icon icon-notification"/></span>*/}
                                {/*</Popover>*/}
                            </li>

                            <li className="gx-msg">
                                {/*<Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"*/}
                                {/*         content={<MailNotification/>} trigger="click">*/}
                                <span className="gx-pointer gx-status-pos gx-d-block">
                                    {/*<i className="icon icon-chat-new"/>*/}
                                    {/*<span className="gx-status gx-status-rtl gx-small gx-orange"/>*/}
                                </span>
                                {/*</Popover>*/}
                            </li>

                            {/*<li className="gx-language">*/}
                            {/*    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"*/}
                            {/*             content={languageMenu()} trigger="click">*/}
                            {/*      <span className="gx-pointer gx-flex-row gx-align-items-center"><i*/}
                            {/*          className={`flag flag-24 flag-${locale.icon}`}/>*/}
                            {/*      </span>*/}
                            {/*    </Popover>*/}
                            {/*</li>*/}
                            <li className="gx-user-nav" style={{marginLeft: "0", marginTop: '-10px'}}>
                                {/*<UserInfo/>*/}
                                <UserProfile/>
                            </li>
                        </ul>
                    </div>
                </div>
            </Header>
            <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block" style={{}}>
                <div className="gx-container">
                    <div className="gx-header-horizontal-nav-flex">
                        <HorizontalNav/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HorizontalDefault;
