import React, {useEffect, useState} from "react";
import {Form, Card, Col, Row, Button, Select, Input, DatePicker, Divider, Checkbox, Radio, Image} from 'antd';
import jsonfile from './task.json'
import {CisUI} from '../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../util/config";
import axios from "../../../util/Api";
import {useHistory} from "react-router-dom";
import IntlMessages from "../../../util/IntlMessages";
import CircularProgress from "../../../components/CircularProgress";
import moment from "moment";

const {RangePicker} = DatePicker;
const {TextArea} = Input;
const {Option} = Select;

const EditTask = (props) => {
    const [image, setImage] = useState("");
    const [rooms, setRooms] = useState([]);
    const [cleaningRooms, setCleaningRooms] = useState([]);
    const [staffs, setStaffs] = useState([]);
    const [todos, setToDos] = useState([]);
    const [newTodos, setNewToDos] = useState([]);
    const [newTodoStatus, setNewToDoStatus] = useState(0);
    const [data, setData] = useState([]);
    const [taskHistories, setTaskHistories] = useState({});
    const [slider, setSlider] = useState([]);
    const [taskGroup, setTaskGroup] = useState([]);
    const [source1, setSource1] = useState(false);
    const [source2, setSource2] = useState(false);
    const [source3, setSource3] = useState(false);
    const [form] = Form.useForm();
    const userID = props.match.params.id;

    const handleSource = (e) => {
        let value = e.target.value
        if(value == 1){
            setSource1(true)
        }
        else{
            setSource1(false)
        }

        if (value == 2){
            setSource2(true)
        }
        else {
            setSource2(false)
        }

        if(value == 3){
            setSource3(true)
        }
        else {
            setSource3(false)
        }
        console.log(e.target.value)
    }

    const handleNewToDo = () =>{
        setNewToDoStatus(1)
    }

    const handleClear = (e) => {
        // console.log(e)
        // setToDos([])

        let new_arr = newTodos.filter((item, index) => item.id !== e)

        setNewToDos(new_arr)
    }

//     const handleAdd = () => {
//         let value = form.getFieldValue('add_todo')
// // console.log(value)
//         if(value == undefined || value == '') {
//             setNewToDos([...newTodos])
//         }
//         else {
//             setNewToDos([...newTodos, {
//                 title: value,
//                 status: 0
//             }])
//         }
//
//         form.setFieldsValue({'add_todo': ""})
//     }
//
//     const handleDelete = (i) => {
//         console.log(i)
//         let newItem = newTodos.filter((item, index) => index != i)
//
//         setNewToDos(newItem)
//     }

    const getRooms = () => {

        // document.getElementById("loader").style.display = "block";
        axios.get(Config.apiserver+`property/roominfos`, CisUI().HeaderRequest)
            .then(res => {
                if(res.data.status === 1) {
                    setRooms(res.data.data)
                }
                else {
                    CisUI().Notification('warning', 'Failed')
                }
                // document.getElementById("loader").style.display = "none";
            })
            .catch(error => console.log(error.response))
    }

    const getCleaningRooms = () => {

        // document.getElementById("loader").style.display = "block";
        axios.get(Config.apiserver+`task/managerooms`, CisUI().HeaderRequest)
            .then(res => {
                if(res.data.status === 1) {
                    setCleaningRooms(res.data.data)
                }
                else {
                    CisUI().Notification('warning', 'Failed')
                }
                // document.getElementById("loader").style.display = "none";
            })
            .catch(error => console.log(error.response))
    }

    const getStaffs = () => {

        axios.get(Config.apiserver+`staff/staffs`, CisUI().HeaderRequest)
            .then(res => {
                if(res.data.status === 1) {
                    setStaffs(res.data.data)
                }
                else {
                    CisUI().Notification('warning', 'Failed')
                }
            })
            .catch(error => console.log(error.response))
    }

    const getTaskGroup = () => {

        axios.get(Config.apiserver+`task/groups`, CisUI().HeaderRequest)
            .then(res => {
                if(res.data.status === 1) {
                    setTaskGroup(res.data.data)
                }
                else {
                    CisUI().Notification('warning', 'Failed')
                }
            })
            .catch(error => console.log(error.response))
    }

    const getData = () => {
        axios.get(Config.apiserver+ jsonfile.urls.view +"/"+ userID, CisUI().HeaderRequest)
            .then(res => {
                if(res.data.status === 1) {
                    // setTaskHistories(res.data.task_histories)
                    //
                    //
                    // let start_attachment = res.data.task_histories.start_attachment
                    // let finished_attachment = res.data.task_histories.finished_attachment
                    // let newArr = "";
                    //
                    // if(start_attachment == null && finished_attachment == null){
                    //     setSlider([])
                    // }
                    // else if(finished_attachment == null){
                    //     setSlider(start_attachment)
                    // }
                    // else if(start_attachment !== null) {
                    //     newArr = start_attachment.concat(finished_attachment)
                    //     setSlider(newArr)
                    //     // console.log(newArr)
                    // }


                    setToDos(res.data.todos)
                    setData(res.data.task)
                    let source = res.data.task.source;

                    if(source == 1){
                        setSource1(true)
                    }
                    else if(source == 2){
                        setSource2(true)
                    }
                    else{
                        setSource3(true)
                    }
                }
                else {
                    CisUI().Notification('warning', 'Failed')
                }
            })
            .catch(error => console.log(error.response))
    }

    const getTaskToDos = (values) => {
        console.log(values)

        axios.get(Config.apiserver + `task/grouptodos/` + values, CisUI().HeaderRequest)
            .then(res => {
                if (res.data.status === 1) {
                    let data = [...newTodos];
                    data.push(res.data.data);
                    // data.push(res.data.data)
                    setNewToDos(data);
                } else {
                    CisUI().Notification('warning', 'Failed')
                }
                // document.getElementById("loader").style.display = "none";
            })
            .catch(error => console.log(error.response))
    }

    useEffect(() => {
        getRooms()
        getCleaningRooms()
        getStaffs()
        getTaskGroup()
        getData()
    }, []);

    const handleToDoStatus = (e, id) => {
        let itemStatus = 0;
        if(e.target.checked === true) {
            todos.map((item, index) => {
                item.assign_todos.map((todo, i) => {
                    if (todo.id === id) {
                        todo.status = 1
                        itemStatus = 1
                    }
                })

            })
        }
        else {
            todos.map((item, index) => {
                // if (item.id === id) {
                //     item.status = 0
                //     itemStatus = 0
                // }

                item.assign_todos.map((todo, i) => {
                    if (todo.id === id) {
                        todo.status = 0
                        itemStatus = 0
                    }
                })
            })
        }

        let data = {
            id: id,
            status: itemStatus
        }

        // console.log(data)

        axios.post(Config.apiserver + 'task/tasktodoupdate/'+userID, data, CisUI().HeaderRequest)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg);
                    setToDos([
                        ...todos,
                    ])
                }
                else {
                    CisUI().Notification('error', res.data.msg);
                }
                // document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });


    }

    // console.log(todos)

    const changeImage = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = (e)=> {
            setImage({
                image : e.target.result
            });
        };
        reader.readAsDataURL(file);
    }

    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }

    let Title = jsonfile.edittitle;
    let endpoint = jsonfile.urls.edit+"/"+userID;
    let redirectto = "../../"+jsonfile.urls.list;


    const history = useHistory();

    const onFinish = (values) => {
        // console.log(values);

        let data = {
            ...values,
            start_date: values['date'][0].format('YYYY-MM-DD'),
            end_date: values['date'][1].format('YYYY-MM-DD'),
            attachment: image['image'],
            // task_todos: todos,
            // newTodos: newTodos
            // todoid:,
        }
        console.log(data)

        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg);
                    history.push(redirectto);
                }
                else {
                    CisUI().Notification('error', res.data.msg);
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    return (
        <Card title={<IntlMessages id={Title}/>} extra={CisUI().addAction(redirectto)}>
            {CisUI().showLoading}
            {
                data == '' ? <CircularProgress /> :
                    <Form
                        form={form}
                        {...CisUI().formItemLayout}
                        name="input"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                        initialValues={{
                            source: data.source,
                            room_id: data.room_id,
                            room_name: data.room_name,
                            phone: data.room_phone,
                            email: data.room_email,
                            address: data.room_address,
                            url: data.room_url,
                            staff_id: data.staff_id,
                            title: data.title,
                            date: [moment(data.start_date),moment(data.end_date)],
                            details: data.details,
                            status: data.status,
                            group_id: data.task_group
                        }}
                    >
                        <Row gutter={24}>
                            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                <Card>
                                    <Row gutter={24}>

                                        {/*<Divider orientation={"left"} plain style={{marginBottom:"20px"}}>*/}
                                        {/*    Details*/}
                                        {/*</Divider>*/}

                                        <Col lg={24} md={24} sm={24} xs={24}>
                                            <div className="gx-form-row0">
                                                <Form.Item
                                                    name="source"
                                                    label="Source"
                                                    onChange={handleSource}
                                                    rules={[{
                                                        required: true,
                                                        message: "Source is required"
                                                    }]}
                                                    initialValue={1}
                                                >
                                                    <Radio.Group>
                                                        <Radio value={1}>E2M Rooms</Radio>
                                                        <Radio value={2}>Cleaning Rooms</Radio>
                                                        <Radio value={3}>Others</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </div>
                                        </Col>

                                        {
                                            source1 === true &&
                                            <>
                                                {/*<Col lg={24} md={24} sm={24} xs={24}>*/}
                                                <Divider orientation={"left"}>E2M Room</Divider>
                                                {/*</Col>*/}
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <Form.Item
                                                            name="room_id"
                                                            label="E2M Rooms"
                                                            rules={[{
                                                                required: true,
                                                                message: "Room is required"
                                                            }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                placeholder="Select an option"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase())}
                                                                // onChange={handleChange}
                                                                // disabled={rooms != '' ? false : true}
                                                                allowClear={true}
                                                                loading={rooms.length > 0 ? false : true}
                                                            >
                                                                {
                                                                    rooms.map((item, index) =>
                                                                        <Option value={item.id}>{item.name}</Option>
                                                                    )
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                            </>
                                        }

                                        {
                                            source2 === true &&
                                            <>
                                                <Divider orientation={"left"}>Cleaning Room</Divider>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <Form.Item
                                                            name="room_id"
                                                            label="Cleaning Rooms"
                                                            rules={[{
                                                                required: true,
                                                                message: "Room is required"
                                                            }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                placeholder="Select an option"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase())}
                                                                // onChange={handleChange}
                                                                // disabled={rooms != '' ? false : true}
                                                                allowClear={true}
                                                                loading={rooms.length > 0 ? false : true}
                                                            >
                                                                {
                                                                    cleaningRooms.map((item, index) =>
                                                                        <Option value={item.id}>{item.name}</Option>
                                                                    )
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                            </>
                                        }

                                        {
                                            source3 === true &&

                                            <>
                                                {/*<Row gutter={24}>*/}
                                                <Divider orientation={"left"} plain>Other Room Details</Divider>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <Form.Item
                                                            name="room_name"
                                                            label="Room Name"
                                                            rules={[{
                                                                required: true,
                                                                message: "Room Name is required"
                                                            }]}
                                                        >
                                                            <Input placeholder="Room Name"/>
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <Form.Item
                                                            name="phone"
                                                            label="Phone"
                                                            rules={[{
                                                                required: true,
                                                                message: "Phone is required"
                                                            }]}
                                                        >
                                                            <Input placeholder="Phone"/>
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <Form.Item
                                                            name="email"
                                                            label="Email"
                                                            rules={[{
                                                                required: true,
                                                                message: "Email is required"
                                                            }]}
                                                        >
                                                            <Input placeholder="Email"/>
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <Form.Item
                                                            name="address"
                                                            label="Address"
                                                            rules={[{
                                                                required: true,
                                                                message: "Address is required"
                                                            }]}
                                                        >
                                                            <Input placeholder="Address"/>
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <Form.Item
                                                            name="url"
                                                            label="Location URL"
                                                            rules={[{
                                                                required: true,
                                                                message: "Location URL is required"
                                                            }]}
                                                        >
                                                            <Input placeholder="Location URL"/>
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                                {/*</Row>*/}
                                            </>
                                        }

                                        <Divider orientation={"left"} plain>Task Details</Divider>

                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <Form.Item
                                                    name="staff_id"
                                                    label="Staff"
                                                    rules={[{
                                                        required: true,
                                                        message: "Staff is required"
                                                    }]}
                                                >
                                                    <Select
                                                        mode="multiple"
                                                        allowClear
                                                        showSearch
                                                        placeholder="Select an option"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase())}
                                                        loading={staffs.length > 0 ? false : true}
                                                    >
                                                        {
                                                            staffs.map((item, index) =>
                                                                <Option value={item.id}>{item.name}</Option>
                                                            )
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col>

                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <Form.Item
                                                    name="title"
                                                    label="Task Title"
                                                    rules={[{
                                                        required: true,
                                                        message: "Task title is required"
                                                    }]}
                                                >
                                                    <Input placeholder="Task Title"/>
                                                </Form.Item>
                                            </div>
                                        </Col>

                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <Form.Item
                                                    name="date"
                                                    label="Date"
                                                    rules={[{
                                                        required: true,
                                                        message: "Task title is required"
                                                    }]}
                                                >
                                                    <RangePicker className="gx-w-100" showTime/>
                                                </Form.Item>
                                            </div>
                                        </Col>

                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <Form.Item
                                                    name="details"
                                                    label="Details"
                                                    rules={[{
                                                        required: true,
                                                        message: "Task title is required"
                                                    }]}
                                                >
                                                    <TextArea className="gx-w-100" placeholder="Task Title"/>
                                                </Form.Item>
                                            </div>
                                        </Col>

                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <Form.Item
                                                    name="status"
                                                    label="Status"
                                                    rules={[{
                                                        required: true,
                                                        message: "Status is required"
                                                    }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="Select an option"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase())}
                                                    >
                                                        <Option value={0}>Pending</Option>
                                                        <Option value={1}>Finished</Option>
                                                        <Option value={2}>Working</Option>
                                                        <Option value={3}>Reassign</Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col>

                                        <Col lg={8} md={8} sm={24} xs={24}>
                                            <div className="gx-form-row0">
                                                <Form.Item
                                                    name="attachment"
                                                    label="Attachment"
                                                >
                                                    <input type="file" onChange={changeImage}/>
                                                    {
                                                        isEdit === 1 && (
                                                            // <img src={Config.image_url + '/upload/task/'+ data.attachment} alt="Image" style={{ width: "120px", height: "100px", marginTop: "10px"}}/>
                                                            <Image src={Config.image_url + 'upload/task/'+ data.attachment} style={{ width: "120px", height: "100px", marginTop: "10px"}}/>
                                                        )
                                                    }

                                                </Form.Item>
                                            </div>
                                        </Col>

                                        <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center', marginTop:"30px"}}>
                                            <Button type="primary" htmlType="submit">
                                                <IntlMessages id={"Submit"} />
                                            </Button>
                                            {/*<Button type="info" onClick={() => history.goBack()} htmlType="back"><IntlMessages id={"Cancel"} /></Button>*/}
                                            {/*<Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Cancel"} /></Button>*/}
                                            <Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Back"} /></Button>
                                        </Col>


                                        {/*<Divider orientation="left" plain style={{ margin:"20px 0"}}>*/}
                                        {/*    To Do*/}
                                        {/*</Divider>*/}

                                        {/*<Col lg={6} md={6} sm={6} xs={6}>*/}
                                        {/*    <div className="gx-form-row0">*/}
                                        {/*        <Form.Item*/}
                                        {/*            name="add_todo"*/}
                                        {/*            label="ToDo"*/}
                                        {/*        >*/}
                                        {/*            <Input placeholder="ToDo" addonAfter={<PlusOutlined onClick={handleAdd}/>}/>*/}
                                        {/*        </Form.Item>*/}
                                        {/*    </div>*/}
                                        {/*</Col>*/}

                                    </Row>
                                </Card>

                                {/*<Col xl={12} lg={12} md={12} sm={24} xs={24}>*/}
                                {/*    <Card title="Task Histories">*/}
                                {/*        <p style={{ fontWeight: "bold"}}>Started At: {taskHistories.started_at}</p>*/}
                                {/*        <p style={{ fontWeight: "bold"}}>Finished At: {taskHistories.finished_at}</p>*/}
                                {/*        <Divider orientation={"left"} plain style={{ fontWeight: "bold"}}>All Attachments</Divider>*/}
                                {/*        {*/}
                                {/*            slider.length > 0 &&*/}

                                {/*            <Image.PreviewGroup>*/}
                                {/*                {*/}
                                {/*                    slider.map((item, index) =>*/}
                                {/*                        <Image key={++index}*/}
                                {/*                               src={item}*/}
                                {/*                               style={{*/}
                                {/*                                   width: "200px",*/}
                                {/*                                   height: "200px",*/}
                                {/*                                   padding: "10px"*/}
                                {/*                               }}*/}
                                {/*                               preview={{src: item}}*/}
                                {/*                        />*/}
                                {/*                    )*/}
                                {/*                }*/}

                                {/*                /!*<Image width={200} src="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg" />*!/*/}
                                {/*                /!*<Image*!/*/}
                                {/*                /!*    width={200}*!/*/}
                                {/*                /!*    src="https://gw.alipayobjects.com/zos/antfincdn/aPkFc8Sj7n/method-draw-image.svg"*!/*/}
                                                {/*/>*/}
                                {/*            </Image.PreviewGroup>*/}
                                {/*        }*/}

                                {/*    </Card>*/}
                                {/*</Col>*/}
                            </Col>


                            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                <Card className="custom_todo" style={{ height:"350px"}} title="Task Template" extra={
                                    <button type="button" className="btn btn-text-adjust btn-circle-md" title="Add New Group" onClick={handleNewToDo}>
                                        <i className="fas fa-plus"/>
                                    </button>
                                }>

                                    <Col lg={24} md={24} sm={24} xs={24}>
                                        <div className="gx-form-row0">
                                            <Form.Item
                                                name="group_id"
                                                label="Task Group"
                                                rules={[{
                                                    required: true,
                                                    message: "Status is required"
                                                }]}
                                            >
                                                <Select
                                                    mode="multiple"
                                                    // allowClear
                                                    // onDeselect={handleClear}
                                                    showSearch
                                                    placeholder="Select an option"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase())}
                                                    disabled
                                                >
                                                    {
                                                        taskGroup.map((item, index) =>
                                                            <Option value={item.id}>{item.name}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </Col>

                                    {/*<p>To-Do List</p>*/}

                                    {
                                        todos.length > 0 ?

                                            todos.map((item, index) =>
                                                <>
                                                    <Divider orientation={"left"} plain style={{ fontSize: "16px", color: "#1E2B5D", margin:"10px 0"}}>{item.group_name}</Divider>
                                                    {
                                                        item.assign_todos.map((todo, i) =>

                                                                todo.status === 1 ?
                                                                    <p style={{width:"100%"}}>
                                                                        <Checkbox
                                                                            // checked={item.status === 1 ? true : false }
                                                                            // onChange={(e) => handleToDo(e, todo.id)}
                                                                            checked={todo.status === 1 ? true : false }
                                                                            onChange={(e) => handleToDoStatus(e, todo.id)}
                                                                            className="custom_checkbox"
                                                                        >
                                                                            <span style={{color:"green"}}>{todo.title}</span>
                                                                        </Checkbox>
                                                                    </p>
                                                                    :
                                                                    <p style={{width:"100%"}}>
                                                                        <Checkbox
                                                                            // checked={item.status === 1 ? true : false }
                                                                            // onChange={(e) => handleToDo(e, todo.id)}
                                                                            checked={todo.status === 0 ? false : true }
                                                                            onChange={(e) => handleToDoStatus(e, todo.id)}
                                                                            className="custom_checkbox"
                                                                        >
                                                                            <span style={{color:"red"}}>{todo.title}</span>
                                                                        </Checkbox>
                                                                    </p>

                                                        )
                                                    }
                                                </>
                                            )
                                            :
                                            ""
                                    }

                                    {/*{*/}
                                    {/*    todos.length > 0 ?*/}
                                    {/*        <List>*/}
                                    {/*            {*/}
                                    {/*                todos.map((item, index) =>*/}
                                    {/*                    <>*/}
                                    {/*                        <List.Item key={index}>*/}
                                    {/*                            <p>*/}
                                    {/*                                {*/}
                                    {/*                                    item.status === 1 ?*/}
                                    {/*                                        <>*/}
                                    {/*                                            <Checkbox checked={item.status === 1 ? true : false } onChange={(e) => handleToDoStatus(e, item.id)}>*/}
                                    {/*                                                <span style={{color:"green"}}>{item.title}</span>*/}
                                    {/*                                            </Checkbox>*/}
                                    {/*                                        </>*/}
                                    {/*                                        :*/}
                                    {/*                                        <>*/}
                                    {/*                                            <Checkbox checked={item.status === 0 ? false : true } onChange={(e) => handleToDoStatus(e, item.id)}>*/}
                                    {/*                                                <span style={{color:"red"}}>{item.title}</span>*/}
                                    {/*                                            </Checkbox>*/}
                                    {/*                                        </>*/}
                                    {/*                                }*/}



                                    {/*                            </p>*/}

                                    {/*                            <div>*/}
                                    {/*                                {*/}
                                    {/*                                    item.status === 1 ?*/}
                                    {/*                                        <>*/}
                                    {/*                                            <CheckCircleOutlined style={{ color:"green", fontSize:"18px", margin:"0 7px 0 0"}}/>*/}
                                    {/*                                        </>*/}
                                    {/*                                        :*/}
                                    {/*                                        <>*/}
                                    {/*                                            <FieldTimeOutlined style={{ color:"red", fontSize:"18px", margin:"0 7px 0 0"}}/>*/}
                                    {/*                                        </>*/}
                                    {/*                                }*/}
                                    {/*                            </div>*/}
                                    {/*                        </List.Item>*/}
                                    {/*                    </>*/}
                                    {/*                )*/}
                                    {/*            }*/}
                                    {/*        </List>*/}
                                    {/*        :*/}
                                    {/*        <p style={{textAlign:"center"}}>No to-do added yet.</p>*/}
                                    {/*}*/}

                                </Card>
                                {
                                    newTodoStatus === 1 &&
                                    <Card className="custom_todo" style={{ height:"350px"}}>

                                        <Col lg={24} md={24} sm={24} xs={24}>
                                            <Divider orientation={"left"} plain
                                                     style={{fontSize: "16px", color: "#1E2B5D", margin: "10px 0"}}>New
                                                Task Group</Divider>
                                            <div className="gx-form-row0">
                                                <Form.Item
                                                    name="new_group_id"
                                                    label="New Task Group"
                                                    rules={[{
                                                        required: true,
                                                        message: "New Task Group is required"
                                                    }]}
                                                >
                                                    <Select
                                                        mode="multiple"
                                                        // allowClear
                                                        onDeselect={handleClear}
                                                        showSearch
                                                        placeholder="Select an option"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase())}
                                                        onSelect={getTaskToDos}
                                                    >
                                                        {
                                                            taskGroup.map((item, index) =>
                                                                <Option value={item.id}>{item.name}</Option>
                                                            )
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col>


                                        {
                                            newTodos.length > 0 &&
                                            newTodos?.map((item, index) =>
                                                <>
                                                    {/*<p style={{ fontSize: "16px", color: "#1E2B5D", margin:"10px 0"}}>{item.group_name}</p>*/}
                                                    <Divider orientation={"left"} plain style={{
                                                        fontSize: "16px",
                                                        color: "#1E2B5D",
                                                        margin: "10px 0"
                                                    }}>{item.group_name}</Divider>
                                                    {
                                                        item.items.map((todo, i) =>
                                                            <p style={{width: "100%"}}>
                                                                {/*<Checkbox*/}
                                                                {/*checked={item.status === 1 ? true : false }*/}
                                                                {/*onChange={(e) => handleToDo(e, todo.id, item.id)}*/}
                                                                {/*className="custom_checkbox"*/}
                                                                {/*>*/}
                                                                <span>{todo.title}</span>
                                                                {/*</Checkbox>*/}
                                                            </p>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </Card>
                                }
                            </Col>

                        </Row>
                    </Form>
            }

        </Card>
    );
};

export default EditTask;
