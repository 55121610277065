import React from "react";
import {Route, Switch} from "react-router-dom";

import PropertyTypeList from "../../containers/yotta/property/property_type/PropertyTypeList";
import NewPropertyType from "../../containers/yotta/property/property_type/NewPropertyType";
import PropertyList from "../../containers/yotta/property/property_info/PropertyInfoList";
import NewPropertyInfo from "../../containers/yotta/property/property_info/NewPropertyInfo";
import EditPropertyInfo from "../../containers/yotta/property/property_info/EditPropertyInfo";
import RoomTypeList from "../../containers/yotta/property/room_type/RoomTypeList";
import NewRoomType from "../../containers/yotta/property/room_type/NewRoomType";
import RoomInfoList from "../../containers/yotta/property/room_info/RoomInfoList";
import NewRoomInfo from "../../containers/yotta/property/room_info/NewRoomInfo";
import EditRoomInfo from "../../containers/yotta/property/room_info/EditRoomInfo";
import AvailableRoomList from "../../containers/yotta/property/available_room/AvailableRoomList";


const Property = ({match}) => (
    <Switch>
        <Route path={`${match.url}/propertytypes`} component={PropertyTypeList}/>
        <Route path={`${match.url}/newpropertytype`} component={NewPropertyType}/>
        <Route path={`${match.url}/editpropertytype/:id`} component={NewPropertyType}/>
        <Route path={`${match.url}/properties`} component={PropertyList}/>
        <Route path={`${match.url}/newpropertyinfo`} component={NewPropertyInfo}/>
        <Route path={`${match.url}/editpropertyinfo/:id`} component={EditPropertyInfo}/>
        <Route path={`${match.url}/roomtypes`} component={RoomTypeList}/>
        <Route path={`${match.url}/newroomtype`} component={NewRoomType}/>
        <Route path={`${match.url}/editroomtype/:id`} component={NewRoomType}/>
        <Route path={`${match.url}/roominfos`} component={RoomInfoList}/>
        <Route path={`${match.url}/newroominfo`} component={NewRoomInfo}/>
        <Route path={`${match.url}/editroominfo/:id`} component={EditRoomInfo}/>
        <Route path={`${match.url}/availablerooms`} component={AvailableRoomList}/>
    </Switch>
);

export default Property;
