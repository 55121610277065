import React, {useState} from "react";
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import Config from "../../../../../util/config";

const CheckOut = () => {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${Config.baseurl}booking/retrievePayment`,
            },
        });

        // console.log(error)
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

	return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" />
            <button disabled={isLoading || !stripe || !elements} id="submit" style={{background: "#221740",
                textShadow: "0 -1px 0 rgb(0 0 0 / 12%)",
                boxShadow: "0 2px 0 rgb(0 0 0 / 5%)",
                color: "#ffffff",
                backgroundColor: "#221740",
                borderColor: "#221740",
                padding: "10px", borderRadius:"5px", display:"block", margin:"10px auto 0"}}
            >
                <span id="button-text">
                  {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
                </span>
            </button>
            {message && <div id="payment-message">{message}</div>}
        </form>
	);
};

export default CheckOut;
