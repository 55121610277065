import React, {useEffect, useState} from "react";
import {Form, Card, Col, Row, Button, Select, Input, DatePicker, Divider, Checkbox, Radio, Image, Tabs} from 'antd';
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import {useHistory} from "react-router-dom";
import IntlMessages from "../../../../util/IntlMessages";

const {RangePicker} = DatePicker;
const {TextArea} = Input;
const {Option} = Select;

const MemberActivity = (props) => {

    let data = props.data
    let staffs = props.staffs

    return (
        <>
            <h2>All Activities of Members</h2>
            <Tabs>
                {
                    data.map((staff, index) =>
                        <Tabs.TabPane tab={staff.staff_name} key={++ index}>
                            <Row>
                                <Col lg={12} md={12} sm={24} xs={24}>

                                    <Card>
                                        <Divider orientation={"left"} plain>Starting Information Of Task</Divider>
                                        <p>Started At: <span style={{ fontWeight:"bold", color: "#1E2B5D"}}>{staff.started_at}</span></p>
                                        <p>Start Comment: <span style={{ fontWeight:"bold", color: "#1E2B5D"}}>{staff.start_comment}</span></p>
                                        <p>Start Attachments</p>

                                        {
                                            staff.start_attachment !== null &&
                                            <Image.PreviewGroup>
                                                {
                                                    staff.start_attachment.map((item, i) =>
                                                        <Image key={++i}
                                                               src={item}
                                                               style={{
                                                                   width: "200px",
                                                                   height: "200px",
                                                                   padding: "10px"
                                                               }}
                                                               preview={{src: item}}
                                                        />
                                                    )
                                                }
                                            </Image.PreviewGroup>
                                        }
                                    </Card>
                                    <Card>
                                        <Divider orientation={"left"} plain>Ending Information Of Task</Divider>
                                        <p>Finished At: <span style={{ fontWeight:"bold", color: "#1E2B5D"}}>{staff.finished_at}</span></p>
                                        <p>Finish Comment: <span style={{ fontWeight:"bold", color: "#1E2B5D"}}>{staff.finish_comment}</span></p>
                                        <p>Finish Attachments:</p>
                                        {
                                            staff.finished_attachment !== null &&
                                            <Image.PreviewGroup>
                                                {
                                                    staff.finished_attachment.map((item, i) =>
                                                        <Image key={++i}
                                                               src={item}
                                                               style={{
                                                                   width: "200px",
                                                                   height: "200px",
                                                                   padding: "10px"
                                                               }}
                                                               preview={{src: item}}
                                                        />
                                                    )
                                                }
                                            </Image.PreviewGroup>
                                        }
                                    </Card>
                                </Col>
                                {/*<Col lg={2} md={2} sm={24} xs={24}></Col>*/}
                                <Col lg={12} md={12} sm={24} xs={24}>
                                    <Card>
                                    <h3>Completed ToDos</h3>
                                    {
                                        staff.todos.length > 0 ?

                                            staff.todos.map((item, index) =>
                                                <>
                                                    <Divider orientation={"left"} plain style={{ fontSize: "16px", color: "#1E2B5D", margin:"10px 0"}}>{item.group_name}</Divider>
                                                    {
                                                        item.assign_todos.map((todo, i) =>

                                                            todo.status === 1 ?
                                                                <p style={{width:"100%"}}>
                                                                    {/*<Checkbox*/}
                                                                    {/*    // checked={item.status === 1 ? true : false }*/}
                                                                    {/*    // onChange={(e) => handleToDo(e, todo.id)}*/}
                                                                    {/*    checked={todo.status === 1 ? true : false }*/}
                                                                    {/*    onChange={(e) => handleToDoStatus(e, todo.id)}*/}
                                                                    {/*    className="custom_checkbox"*/}
                                                                    {/*    disabled={true}*/}
                                                                    {/*>*/}
                                                                    {/*    <span style={{color:"green"}}>{todo.title}</span>*/}
                                                                    <span style={{color:"green"}}><i className="icon icon-check-cricle" style={{ padding:"0 4px 4px 0"}}/>{todo.title}</span>
                                                                    {/*</Checkbox>*/}
                                                                </p>
                                                                :
                                                                <p style={{width:"100%"}}>
                                                                    {/*<Checkbox*/}
                                                                    {/*    // checked={item.status === 1 ? true : false }*/}
                                                                    {/*    // onChange={(e) => handleToDo(e, todo.id)}*/}
                                                                    {/*    checked={todo.status === 0 ? false : true }*/}
                                                                    {/*    onChange={(e) => handleToDoStatus(e, todo.id)}*/}
                                                                    {/*    className="custom_checkbox"*/}
                                                                    {/*    disabled={true}*/}
                                                                    {/*>*/}
                                                                    <span style={{color:"red"}}><i className="icon icon-circle-o" style={{ padding:"0 4px 4px 0"}}/>{todo.title}</span>
                                                                    {/*</Checkbox>*/}
                                                                </p>

                                                        )
                                                    }
                                                </>
                                            )
                                            :
                                            "No Data Found"
                                    }
                                    </Card>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                    )
                }

            </Tabs>
        </>
    );
};

export default MemberActivity;