import React from "react";
import {Route, Switch} from "react-router-dom";

import StaffList from "../../containers/yotta/staff/StaffList";
import AddStaff from "../../containers/yotta/staff/AddStaff";

const Staff = ({match}) => (
    <Switch>
        <Route path={`${match.url}/staffs`} component={StaffList}/>
        <Route path={`${match.url}/newstaff`} component={AddStaff}/>
        <Route path={`${match.url}/staffedit/:id`} component={AddStaff}/>
    </Switch>
);

export default Staff;
