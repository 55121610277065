import React, {useEffect, useState} from "react";
import {Card, Row, Col} from 'antd';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";
import axios from "axios";
import Config from "../../../../../util/config";
import {CisUI} from "../../../../../util/CISUI";
import IntlMessages from "../../../../../util/IntlMessages";
import CircularProgress from "../../../../../components/CircularProgress";

const stripePromise = loadStripe(Config.stripe_pk);

const Payment = (props) => {
    const userID = props.match.params.id;
    const [loading, setLoading] = useState(false);

    const [clientSecret, setClientSecret] = useState("");

    const getToken = () => {
        console.log("hi")
        setLoading(true)
        axios.post(Config.apiserver + 'booking/newbooking/'+userID, {}, CisUI().HeaderRequest)
            .then(res => {
                console.log(res)

                if(res.data.status === 1) {
                    setClientSecret( res.data.client_secret)
                }
                else {
                    CisUI().Notification('error', res.data.msg);
                }
                setLoading(false)
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    useEffect(() => {
        getToken()
    }, [])

    const options = {
        clientSecret
    };
	return (
        loading ? <CircularProgress /> :
            <Row justify={"center"}>
                <Col xl={12} lg={16} md={18} sm={24} xs={24}>
                    <Card title={<IntlMessages id={"Payment"}/>}>
                        {clientSecret && (
                            <Elements stripe={stripePromise} options={options}>
                                <CheckoutForm/>
                            </Elements>
                        )
                        }
                    </Card>
                </Col>
            </Row>
	);
};

export default Payment;
