import React  from 'react';
import {Form, Row, Col, Input, Button, Select, DatePicker} from 'antd';
import SelectData from './selectdata';
import {CisUI} from "./CISUI";
import StaticSelectData from "./StaticSelectData";
import IntlMessages from "./IntlMessages";

const { RangePicker } = DatePicker;
const { Option } = Select;


const onRangeChange = (value, dateString) => {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);
}

// const dateFormat = 'YYYY/MM/DD';

const AdvancedSearch = (inputs,endpoint="/",hasDataRange=0,pickertype="") => {
    // const [expand, setExpand] = useState(false);
    // const [form] = Form.useForm();
    let hasSearch = 0
    const getFields = () => {
        const count = inputs.length;
        const children = [];
        //let hasSearch = 0;
        //const allinputs = inputs;
            inputs.map((list, index) => {
                if (list.isFilterable == 1) {
                    hasSearch = 1;
                    let field = "";
                    if (list.type == 'text' || list.type == 'phone' || list.type == 'email') {
                        field = <Form.Item
                            name={`${list.name}`}
                            label={<IntlMessages id={`${list.label}`} />}
                            rules={[
                                {
                                    required: false,
                                    message: list.placeholder
                                },
                            ]}
                        >
                            <Input placeholder={list.label}/>
                        </Form.Item>
                    } else if(list.type == 'date'){
                        field = <Form.Item
                            name={`${list.name}`}
                            label={<IntlMessages id={`${list.label}`} />}
                            rules={[
                                {
                                    required: false,
                                    message: list.placeholder
                                },
                            ]}
                        >
                            <DatePicker  className="gx-mb-3 gx-w-100" placeholder={list.placeholder}/>
                        </Form.Item>
                    } else if (list.type == 'select') {

                        if(list.name === 'company_id' || list.name === 'com_id') {
                            const companyID = CisUI().getUserInfo('com_id');
                            if(companyID > 0) {

                            }
                            else {
                                const dataOptions = SelectData(list.data);
                                const dataOptions1 = StaticSelectData(list.name);
                                // console.log("CompanyList : "+dataOptions);

                                field = <Form.Item
                                    name={`${list.name}`}
                                    label={<IntlMessages id={`${list.label}`} />}
                                    rules={[
                                        {
                                            required: false,
                                            message: list.placeholder
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear={true}
                                        placeholder={list.placeholder}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        loading={dataOptions.length > 0 ? false : true}
                                    >
                                        {
                                            list.isStatic === 1 && (
                                                dataOptions1.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            )
                                        }
                                        {
                                            dataOptions.map((items) =>
                                                <Option value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            }
                        }
                        else if(list.name === 'local_id') {
                            const userArea = CisUI().getUserInfo('area');
                            if(userArea >= 26) {

                            }
                            else {
                                const dataOptions = SelectData(list.data);
                                const dataOptions1 = StaticSelectData(list.name);
                                // console.log("CompanyList : "+dataOptions);

                                field = <Form.Item
                                    name={`${list.name}`}
                                    label={<IntlMessages id={`${list.label}`} />}
                                    rules={[
                                        {
                                            required: false,
                                            message: list.placeholder
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear={true}
                                        placeholder={list.placeholder}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        loading={dataOptions.length > 0 ? false : true}
                                    >
                                        {
                                            list.isStatic === 1 && (
                                                dataOptions1.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            )
                                        }
                                        {
                                            dataOptions.map((items) =>
                                                <Option value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            }
                        }
                        else if(list.name === 'district_id') {
                            const userArea = CisUI().getUserInfo('comarea');
                            if(userArea >= 5) {

                            }
                            else {
                                const dataOptions = SelectData(list.data);
                                const dataOptions1 = StaticSelectData(list.name);
                                field = <Form.Item
                                    name={`${list.name}`}
                                    label={<IntlMessages id={`${list.label}`} />}
                                    rules={[
                                        {
                                            required: false,
                                            message: list.placeholder
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear={true}
                                        placeholder={list.placeholder}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        loading={dataOptions.length > 0 ? false : true}
                                    >
                                        {
                                            list.isStatic === 1 && (
                                                dataOptions1.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            )
                                        }
                                        {
                                            dataOptions.map((items) =>
                                                <Option value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            }
                        }
                        else {
                            let dataOptions1 = [];
                            let dataOptions = [];

                            if(list.isStatic === 1) {
                                dataOptions1 = StaticSelectData(list.name);
                            }
                            else {
                                 dataOptions = SelectData(list.data);
                            }
                            //  console.log("ListData : "+dataOptions1);
                            // console.log(list.data);

                            field = <Form.Item
                                name={`${list.name}`}
                                label={<IntlMessages id={`${list.label}`} />}
                                rules={[
                                    {
                                        required: false,
                                        message: list.placeholder
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear={true}
                                    placeholder={list.placeholder}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    loading={dataOptions.length > 0 ? false : true}
                                >
                                    {
                                        list.isStatic === 1 && (
                                            dataOptions1.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        )
                                    }
                                    {
                                        dataOptions.map((items) =>
                                            <Option value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        }
                    }
                    if(field != '') {
                        children.push(
                            <Col lg={6} md={6} sm={12} xs={24} key={index}
                                 style={{display: index < count ? 'block' : 'none'}}>
                                <div className="gx-form-row0">
                                    {field}
                                </div>
                            </Col>,
                        );
                    }
                }
            });

            if(hasDataRange === 1) {
                hasSearch = 1;
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} >
                        <div className="gx-form-row0">

                            <Form.Item
                                name="date"
                                label={<IntlMessages id={"Date"} />}
                                rules={[
                                    {
                                        required: false,
                                        message: "Start Date"
                                    },
                                ]}
                            >

                                <RangePicker onChnage={onRangeChange} format={CisUI().dateFormat} />

                                {/*<DatePicker format={CisUI().dateFormat} placeholder="Start Date"/>*/}
                            </Form.Item>

                        </div>
                    </Col>,
                );
            }

        if(pickertype === "week") {
            hasSearch = 1;
            children.push(
                <Col lg={6} md={6} sm={12} xs={24} >
                    <div className="gx-form-row0">

                        <Form.Item
                            name={pickertype}
                            label={<IntlMessages id={"Week"} />}
                            rules={[
                                {
                                    required: false,
                                    message: {pickertype}
                                },
                            ]}
                        >

                            <DatePicker picker={pickertype} onChnage={onRangeChange} />
                        </Form.Item>

                    </div>
                </Col>,
            );
        }

        if(pickertype === "month") {
            hasSearch = 1;
            children.push(
                <Col lg={6} md={6} sm={12} xs={24} >
                    <div className="gx-form-row0">

                        <Form.Item
                            name={pickertype}
                            label={<IntlMessages id={"Month"} />}
                            rules={[
                                {
                                    required: false,
                                    message: {pickertype}
                                },
                            ]}
                        >

                            <DatePicker picker={pickertype} onChnage={onRangeChange} />
                        </Form.Item>

                    </div>
                </Col>,
            );
        }

            // if(hasSearch === 1) {
            //     children.push(
            //         <Col lg={6} md={6} sm={12} xs={24} style={{textAlign: 'left'}}>
            //             <br/>
            //             <Button type="primary" className="searchbutton" htmlType="submit">
            //                 <i class="fas fa-search" /> <IntlMessages id={"Search"} />
            //             </Button>
            //         </Col>
            //     )
            // }

        return children;
    };


    return (
        <Row gutter={24}>
            {/*<div className="col-md-11">*/}
            {/*    <div className="row">*/}
                    {getFields()}
                {/*</div>*/}
             {/*</div>*/}

            <Col lg={6} md={6} sm={12} xs={24} style={{textAlign:"left", marginTop:"6px"}}>
                {
                    hasSearch === 1 ?

                        <Button type="primary" className="searchbutton" htmlType="submit">
                            <i class="fas fa-search" /> <IntlMessages id={"Search"} />
                        </Button>
                         : ""
                }
            </Col>

        </Row>
    );
};

export default AdvancedSearch;
