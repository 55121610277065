import React from "react";
import { Button, Input, message, Modal, Upload } from "antd";
import Moment from "moment";

import IntlMessages from "util/IntlMessages";
import axios from "axios";
import { CisUI } from "../../../../../util/CISUI";
import CircularProgress from "../../../../../components/CircularProgress";
import config from "../../../../../util/config";

const { TextArea } = Input;

const props = {
    name: "file",
    action: "//jsonplaceholder.typicode.com/posts/",
    headers: {
        authorization: "authorization-text",
    },
    onChange(info) {
        if (info.file.status !== "uploading") {
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
};

const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
};
class ComposeMail extends React.Component {
    constructor() {
        super();
        this.state = {
            from: "info@easy2manage.uk.co",
            to: "",
            cc: "",
            bcc: "",
            subject: "",
            body: "",
            loader: false,
        };
    }

    render() {
        const { onMailSend, onClose, user } = this.props;
        const { from, to, subject, body } = this.state;

        return (
            <Modal
                onCancel={onClose}
                open={this.props.open}
                title={<IntlMessages id="mail.title" />}
                closable={false}
                onOk={() => {
                    this.setState({ ...this.state, loader: true });
                    if (validateEmail(to) && validateEmail(from)) {
                        axios
                            .post(
                                `https://e2msandbox.datalibrary.io/api/message/sendmail`,
                                { from, to, subject, body },
                                {
                                    headers: {
                                        authorization:
                                            localStorage.getItem("token"),
                                    },
                                }
                            )
                            .then((res) => {
                                CisUI().Notification("success", res.data.msg);
                                this.setState({
                                    from: "info@easy2manage.uk.co",
                                    to: "",
                                    cc: "",
                                    bcc: "",
                                    subject: "",
                                    body: "",
                                    loader: false,
                                });
                                onClose();
                            })
                            .catch((err) => {
                                CisUI().Notification("error", err.data.msg);
                                this.setState({ ...this.state, loader: false });
                            });
                    } else {
                        CisUI().Notification(
                            "error",
                            "please enter valid email"
                        );
                        this.setState({ ...this.state, loader: false });
                    }
                    //  if (to === '')
                    //    return;
                    //  onClose();
                    //  onMailSend(
                    //    {
                    //      'id': '15453a06c08fb021776',
                    //      'from': {
                    //        'name': user.name,
                    //        'avatar': user.avatar,
                    //        'email': user.email
                    //      },
                    //      'to': [
                    //        {
                    //          'name': to,
                    //          'email': to
                    //        }
                    //      ],
                    //      'subject': subject,
                    //      'message': message,
                    //      'time': Moment().format('DD MMM'),
                    //      'read': false,
                    //      'starred': false,
                    //      'important': false,
                    //      'hasAttachments': false,
                    //      'folder': 1,
                    //      'selected': false,
                    //      'labels': [],
                    //    })
                }}
                style={{ zIndex: 2600 }}
            >
                {this.state.loader ? (
                    <div
                        style={{
                            display: "grid",
                            placeItems: "center",
                            minHeight: "30vh",
                        }}
                    >
                        <img
                            src={config.loader}
                            style={{ width: "50px", height: "50px" }}
                            alt="loader"
                        />
                    </div>
                ) : (
                    <>
                        <div className="gx-form-group">
                            <Input
                                placeholder="From*"
                                onChange={(event) =>
                                    this.setState({ from: event.target.value })
                                }
                                defaultValue={from}
                                margin="normal"
                            />
                        </div>
                        <div className="gx-form-group">
                            <Input
                                placeholder="To*"
                                onChange={(event) =>
                                    this.setState({ to: event.target.value })
                                }
                                defaultValue={to}
                                margin="normal"
                            />
                        </div>
                        <div className="gx-form-group">
                            <Input
                                placeholder="Subject"
                                onChange={(event) =>
                                    this.setState({
                                        subject: event.target.value,
                                    })
                                }
                                value={subject}
                                margin="normal"
                            />
                        </div>
                        <div className="gx-form-group">
                            <TextArea
                                placeholder="Message"
                                onChange={(event) =>
                                    this.setState({ body: event.target.value })
                                }
                                value={body}
                                autosize={{ minRows: 2, maxRows: 6 }}
                                margin="normal"
                            />
                        </div>

                        {/* <div className="gx-form-group">
          <Upload {...props}>
            <Button type="primary">
              <i className="icon icon-attachment"/> Attach File
            </Button>
          </Upload>
        </div> */}
                    </>
                )}
            </Modal>
        );
    }
}

export default ComposeMail;
