import React from 'react';
import {Badge, Button, Card, Col, Row, Tag} from "antd";

const RoomDetails = (props) => {
	let roomDetails = props.details;
	let high_amenities = props.high_amenities;
	let amenities = props.amenities;
	return(
		<Card title={"Room No: "+roomDetails.room_details.room_name}
		      extra={"Size: "+roomDetails.room_details.size+" sq.ft"}>
			{/*<Row>*/}
			{/*    <Col lg={12} md={12} sm={24} xs={24}>*/}
			{/*        <h4>Room Name: <span className="room_detail_num">{roomDetails.room_details.room_name}</span></h4>*/}
			{/*    </Col>*/}
			{/*    <Col lg={12} md={12} sm={24} xs={24}>*/}
			{/*        <h4>Room Size: <span className="room_detail_num">{roomDetails.room_details.size} sq.ft</span></h4>*/}
			{/*    </Col>*/}
			{/*</Row>*/}
			
			<Row>
				<Col lg={12} md={12} sm={24} xs={24}>
					{/*<Badge count={"Balcony " + roomDetails.room_details.balcony}/>*/}
					<Badge.Ribbon text={roomDetails.room_details.balcony} color="geekblue">
						<Card size="small">
							Balcony
						</Card>
					</Badge.Ribbon>
					{/*<p className="room_detail">Balcony: <span className="room_detail_num">{roomDetails.room_details.balcony}</span></p>*/}
				</Col>
				<Col lg={12} md={12} sm={24} xs={24}>
					<Badge.Ribbon text={roomDetails.room_details.bathroom} color="geekblue">
						<Card size="small">
							Bathroom
						</Card>
					</Badge.Ribbon>
					{/*<p className="room_detail">Bathroom: <span className="room_detail_num">{roomDetails.room_details.bathroom}</span></p>*/}
				</Col>
				<Col lg={12} md={12} sm={24} xs={24}>
					<Badge.Ribbon text={roomDetails.room_details.bed_room} color="geekblue">
						<Card size="small">
							Bed Room
						</Card>
					</Badge.Ribbon>
					{/*<p className="room_detail">Bed Room: <span className="room_detail_num">{roomDetails.room_details.bed_room}</span></p>*/}
				</Col>
				<Col lg={12} md={12} sm={24} xs={24}>
					<Badge.Ribbon text={roomDetails.room_details.other_room} color="geekblue">
						<Card size="small">
							Other Room
						</Card>
					</Badge.Ribbon>
					{/*<p className="room_detail">Other Room: <span className="room_detail_num">{roomDetails.room_details.other_room}</span></p>*/}
				</Col>
				<Col lg={12} md={12} sm={24} xs={24}>
					<Badge.Ribbon text={roomDetails.room_details.sufa_bed} color="geekblue">
						<Card size="small">
							Sufa Bed
						</Card>
					</Badge.Ribbon>
					{/*<p className="room_detail">Sufa Bad: <span className="room_detail_num">{roomDetails.room_details.sufa_bed}</span></p>*/}
				</Col>
				<Col lg={12} md={12} sm={24} xs={24}>
					<Badge.Ribbon text={roomDetails.room_details.kitchen} color="geekblue">
						<Card size="small">
							Kitchen
						</Card>
					</Badge.Ribbon>
					{/*<p className="room_detail">Kitchen: <span className="room_detail_num">{roomDetails.room_details.kitchen}</span></p>*/}
				</Col>
			</Row>
			<Row>
				<Col lg={8} md={8} sm={24} xs={24}>
					<p style={{marginTop:"10px"}}>Customer Price</p>
				</Col>
				<Col lg={16} md={16} sm={24} xs={24}>
					<Button type="info" shape="round" size="sm">£{roomDetails.room_details.customer_price}</Button>
				</Col>
				
				<Col lg={8} md={8} sm={24} xs={24} style={{ marginTop: "10px"}}>
					<p>High Amenities</p>
				</Col>
				<Col lg={16} md={16} sm={24} xs={24} style={{ marginTop: "10px"}}>
					<p>
						{
							high_amenities.map((item, index) =>
								<Tag key={++index} className="amenity_tag">{item.name}</Tag>
							)
						}
					</p>
				</Col>
				
				<Col lg={8} md={8} sm={24} xs={24} style={{ marginTop: "10px"}}>
					<p>Amenities</p>
				</Col>
				<Col lg={16} md={16} sm={24} xs={24} style={{ marginTop: "10px"}}>
					<p>
						{
							amenities.map((item, index) =>
								<Tag key={++index} className="amenity_tag">{item.name}</Tag>
							)
						}
					</p>
				</Col>
			</Row>
		</Card>
	);
}

export default RoomDetails;