import React, {useEffect, useState} from "react";
import {Form, Card, Col, Row, Button, Checkbox, Select, Divider} from 'antd';
import jsonfile from './others.json'
import {CisUI} from '../../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../../util/config";
import axios from "../../../../../util/Api";
import {useHistory} from "react-router-dom";
import IntlMessages from "../../../../../util/IntlMessages";
import CKEditor from "react-ckeditor-component";
import CircularProgress from "../../../../../components/CircularProgress";

const { Option } = Select;

const EditOtherInfo = (props) => {
    const [ck, setCK] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [form] = Form.useForm();

    const handleChange = (evt) => {
        // console.log(evt.editor);
        var newContent = evt.editor.getData();
        // const newContent = evt.editor._.data;
        setCK(newContent)
    }

    const editid = props.editId;
    const editdata = props.editData;

    useEffect(() => {
        setCK(editdata.details);
    }, [])

    let Title = jsonfile.edittitle;
    let endpoint = jsonfile.urls.edit+"/"+editid;
    let redirectto = "../../property/roominfos";


    const history = useHistory();

    const onFinish = (values) => {
        // console.log(values);
        let data = {
            ...values,
            // details: Buffer.from(ck, 'utf8').toString('base64')
            details: ck
        }
        console.log(data);
        setLoading(true)
        axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg);
                    // history.push(redirectto);
                }
                else {
                    CisUI().Notification('error', res.data.msg);
                }
                setLoading(false)
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    return (
        <>
            {loading? <CircularProgress />:''}

            <Card title={<IntlMessages id={Title}/>}>

            <Form
                // form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                <Row gutter={24}>

                    <Col lg={24} md={24} sm={24} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name="details"
                                label="Details"
                            >
                                <CKEditor
                                    activeClass="p10"
                                    content={ck}
                                    events={{
                                        "change": handleChange
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </Col>

                </Row>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            <IntlMessages id={"Submit"} />
                        </Button>
                        <Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Back"} /></Button>
                    </Col>
                </Row>

            </Form>
        </Card>
        </>
    );
};

export default EditOtherInfo;
