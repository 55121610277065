import React, {useState, useEffect} from "react";
import {Row, Col, notification, Table, Avatar} from 'antd';
import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget/index";
import LineIndicator from "./LineIndicator";
import {Area, AreaChart, Legend, LineChart, Line, CartesianGrid,  XAxis, YAxis, ResponsiveContainer, Tooltip, Pie, PieChart} from "recharts";
import axios from "../../../util/Api";
import Config from "../../../util/config";
import {CisUI} from "../../../util/CISUI";
import {Link} from "react-router-dom";

import CanvasJSReact from './../../canvasjs/canvasjs.react';
import moment from 'moment';


import IntlMessages from "../../../util/IntlMessages";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const AdminDashboard = () => {

    const [state,setState] = useState({
        property_wise_rooms: [],
        owner_wise_property: [],
        monthly_booking: [],
        day_wise_income: [],
        latest_five_bookings: [],
        latest_five_booking_req: [],
        new_customer: [],
        total_message: 0,
        total_complained: 0,
    });

    const [activeIndex, setActiveIndex] = useState(0);

    const onPieEnter = (_, index) => {
        setActiveIndex(index)
    };

    useEffect(() => {
        getDashboardData();
    }, []);


    const getDashboardData = () => {
        // document.getElementById("loader").style.display = "block";
        // axios.get(Config.apiserver + "dashboard",CisUI().HeaderRequest)
        axios.get(Config.apiserver + "testingdata",CisUI().HeaderRequest)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    setState({
                        property_wise_rooms: res.data.property_wise_rooms,
                        owner_wise_property: res.data.owner_wise_property,
                        monthly_booking: res.data.monthly_booking,
                        day_wise_income: res.data.day_wise_income,
                        latest_five_bookings: res.data.latest_five_bookings,
                        latest_five_booking_req: res.data.latest_five_booking_req,
                        new_customer: res.data.new_customer,
                        total_message: res.data.total_message,
                        total_complained: res.data.total_complained
                    })
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                // document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                // console.log(errors.response.data.errors);
                console.log(errors);
                // document.getElementById("loader").style.display = "none";
            });
    }

    // useEffect(() => {
    //     getDashboardData()
    // }, []);

    // let data = [];
    // state.monthly_booking.map((item, index) => {
    //     data.push({
    //         name: item.date,
    //         total_room: item.total_room,
	//         amt: ++index
    //     })
    // })

    // let data1 = [];
    // state.day_wise_income.map((item,index) => {
    //     data1.push({
    //         name: item.date,
    //         balance: item.total
    //     })
    // })


    const dataSource1 = [];

    state.latest_five_bookings.map((item, index) => {
        dataSource1.push({
            key: index,
            booking_id: item.book_unqid,
            customer_name: item.customer_name,
            discount: parseFloat(item.discount_percentage).toFixed(2),
            total: CisUI().getCurrencyFormated1(parseFloat(item.grand_total).toFixed(2))
        })
    })

    const columns1 = [
        {
            title: 'Booking ID',
            dataIndex: 'booking_id',
            key: 'booking_id',
        },
        {
            title: 'Customer Name',
            dataIndex: 'customer_name',
            key: 'customer_name',
        },
        {
            title: 'Discount(%)',
            dataIndex: 'discount',
            key: 'discount',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
    ];

    const dataSource2 = [];

    state.latest_five_booking_req.map((item, index) => {
        dataSource2.push({
            key: index,
            customer_name: <Link to={`../booking/bookingreqdetails/${item.id}`}>{item.customer_name}</Link>,
            room_name: <Link to={`../booking/bookingreqdetails/${item.id}`}>{item.room_name}</Link>,
            guest: <Link to={`../booking/bookingreqdetails/${item.id}`}>{item.guest}</Link>,
            check_in: item.check_in,
            check_out: item.check_out,
            discount: parseFloat(item.discount_percentage).toFixed(2),
            total: CisUI().getCurrencyFormated1(parseFloat(item.grand_total).toFixed(2))
        })
    })

    const columns2 = [
        {
            title: 'Customer Name',
            dataIndex: 'customer_name',
            key: 'customer_name',
        },
        {
            title: 'Room Name',
            dataIndex: 'room_name',
            key: 'room_name',
        },
        {
            title: 'Total Guest',
            dataIndex: 'guest',
            key: 'guest',
        },
        {
            title: 'Check In',
            dataIndex: 'check_in',
            key: 'check_in',
        },
        {
            title: 'Check Out',
            dataIndex: 'check_out',
            key: 'check_out',
        },
        {
            title: 'Discount(%)',
            dataIndex: 'discount',
            key: 'discount',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
    ];

    // const pieData1 = [];
    //
    // state.owner_wise_property.map((item, index) => {
    //     pieData1.push({
    //         name: item.full_name,
    //         value: item.total_property
    //     })
    // })

    // let colors = ["#1abc9c", "#34495e", "#7f8c8d","#778ca3", "#079992", "#b71540", "#3c6382", "#e15f41", "#485460", "#ff5252", "#1e3799"]
    // let colors = ["#1C2140", "#232F5D", "#4C5D92","#7488C1", "#A6B8E0", "#BAD1EE"]
    // let colors = ["#D2E3F6", "#ACBFE2", "#879FD2", "#7488C1", "#6076AE", "#4C5D92", "#22577F", "#0F3F66", "#0B3651", "#1C2140"]
    let colors = ["#1C2140", "#0B3651", "#0F3F66",  "#22577F", "#4C5D92", "#6076AE", "#7488C1", "#879FD2", "#ACBFE2", "#D2E3F6"]

    let property_rooms = [];
    state.property_wise_rooms.map((item, index) => {
        property_rooms.push({
            label: item.name, y: item.total_rooms, color: colors[index]
        })
    })
    // console.log(property_rooms)

    const options = {
        animationEnabled: true,
        legend: {
            horizontalAlign: "right",
            verticalAlign: "center",
            reversed: true
        },
        data: [{
            type: "pyramid",
            showInLegend: false,
            indexLabelFontSize: 13,
            legendText: "{label}",
            indexLabel: "{label} - {y}",
            toolTipContent: "<b>{label}</b>: {y}",
            dataPoints: property_rooms
        }]
    }

    const containerProps = {
        height: "250px"
    }

    return (
        <Auxiliary>
            {/*{CisUI().showLoading}*/}
            <Row>
                <Col span={24}>
                    <div className="gx-card">
                        <div className="gx-card-body">
                            <Row>
                                <Col xl={4} lg={12} md={12} sm={12} xs={24}>

                                    <div className="gx-wel-ema gx-pt-xl-2">
                                        <h1 className="gx-mb-3"><IntlMessages id={"Welcome"} /> SuperAdmin!</h1>
                                        <h5>Today: {moment().format("YYYY-MM-DD")}</h5>
                                        {/*<h4>Time is: { moment().format("HH:mm:ss") }</h4>*/}
                                        {/*<p>Total Messages: <span style={{ color:"#003366" }}>{state.total_message}</span></p>*/}
                                        {/*<p>Total Complained: <span style={{ color:"#003366" }}>{state.total_complained}</span></p>*/}

                                        <ul className="gx-line-indicator">
                                            <li>
                                                <LineIndicator width={state.total_message+"%"} title="Messages" color="cyan" value={state.total_message}/>
                                            </li>
                                            <li>
                                                <LineIndicator width={state.total_complained+"%"} title="Complained" color="geekblue" value={state.total_complained}/>
                                            </li>
                                            {/*<li>*/}
                                            {/*    <LineIndicator width="20%" title="Others" color="orange" value="20%"/>*/}
                                            {/*</li>*/}
                                        </ul>
                                    </div>

                                </Col>

                                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                                    <Widget styleName="gx-card-full">
                                        <div className="ant-row-flex gx-px-4 gx-pt-4">
                                            <h2 className="h4 gx-mb-3">Latest Booking Requests</h2>
                                        </div>
                                        <Table dataSource={dataSource2} columns={columns2} pagination={false} style={{ width: "95%",margin: "0px 0 0 20px", padding: "0px 0px 25px 0"}}/>
                                    </Widget>
                                </Col>

                                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                    <Widget styleName="gx-card-full">

                                        <div className="ant-row-flex gx-px-4 gx-pt-4">
                                            <h2 className="h4 gx-mb-3">Monthly Day wise Income</h2>
                                        </div>

                                    {/*    <ResponsiveContainer width="100%" height={180}>*/}
                                    {/*        <AreaChart data={data1}*/}
                                    {/*                   margin={{top: 0, right: 0, left: 0, bottom: 0}}>*/}
                                    {/*            <CartesianGrid strokeDasharray="3 3" />*/}
                                    {/*            <Tooltip/>*/}
                                    {/*            <XAxis dataKey="name"/>*/}
                                    {/*            <defs>*/}
                                    {/*                <linearGradient id="color15" x1="0" y1="0" x2="0" y2="1">*/}
                                    {/*                    <stop offset="5%" stopColor="#38AAE5" stopOpacity={0.8}/>*/}
                                    {/*                    <stop offset="95%" stopColor="#F5FCFD" stopOpacity={0.8}/>*/}
                                    {/*                </linearGradient>*/}
                                    {/*            </defs>*/}
                                    {/*            <Area dataKey='balance' strokeWidth={2} stackId="2" stroke='#10316B' fill="url(#color15)"*/}
                                    {/*                  fillOpacity={1}/>*/}
                                    {/*        </AreaChart>*/}
                                    {/*    </ResponsiveContainer>*/}
                                    {/*</Widget>*/}

                                        <ResponsiveContainer width="100%" height={200}>
                                            <AreaChart data={state.day_wise_income}
                                                       margin={{top: 10, right: 30, left: 20, bottom: 10}}
                                            >
                                                <XAxis dataKey="name" />
                                                <YAxis label={{ value: "Amount", angle: -90, position: "insideLeft"}}/>
                                                <CartesianGrid strokeDasharray="3 3"/>
                                                <Tooltip active/>
                                                <Area
	                                                isAnimationActive={true}
	                                                type="monotone"
	                                                dataKey="Total"
	                                                // name="Total"
	                                                // label={"total"}
	                                                dot={{ stroke: '#003366', strokeWidth: 1 }}
	                                                // animationBegin={0}
	                                                // animationDuration={1500}
	                                                // animationEasing="ease-in"
	                                                fillOpacity={0.7}
	                                                stroke='#003366'
	                                                fill='#003366'
                                                />
                                            </AreaChart>
                                        </ResponsiveContainer>
                                    </Widget>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={10} lg={10} md={24} sm={24} xs={24}>
                                    <Widget styleName="gx-card-full">

                                        <div className="ant-row-flex gx-px-4 gx-pt-4">
                                            <h2 className="h4 gx-mb-3">Day Wise Total Booked Rooms</h2>
                                        </div>
                                        {/*<ResponsiveContainer width="100%" height={180}>*/}
                                        {/*    <BarChart*/}
                                        {/*        width={500}*/}
                                        {/*        height={300}*/}
                                        {/*        data={data}*/}
                                        {/*        margin={{*/}
                                        {/*            top: 5,*/}
                                        {/*            right: 30,*/}
                                        {/*            left: 20,*/}
                                        {/*            bottom: 5,*/}
                                        {/*        }}*/}
                                        {/*    >*/}
                                        {/*        <CartesianGrid strokeDasharray="3 3" />*/}
                                        {/*        <XAxis dataKey="name" />*/}
                                        {/*        <YAxis />*/}
                                        {/*        <Tooltip />*/}
                                        {/*        <Legend />*/}
                                        {/*        <Bar name="Total Rooms" dataKey="total_room" fill="#FE9E15" />*/}
                                        {/*        /!*<Bar dataKey="uv" fill="#82ca9d" />*!/*/}
                                        {/*    </BarChart>*/}
                                        {/*</ResponsiveContainer>*/}

                                        <ResponsiveContainer width="100%" height={200}>
                                            <LineChart data={state.monthly_booking}
                                                       margin={{top: 10, right: 30, left: 10, bottom: 0}}>
                                                <XAxis dataKey="name"/>
                                                <YAxis label={{value:"Total Rooms", angle: -90, position:"insideLeft"}}/>
                                                <CartesianGrid strokeDasharray="3 3"/>
                                                <Tooltip/>
                                                <Legend/>
                                                <Line
	                                                isAnimationActive={true}
	                                                // name="Total Booked Rooms"
	                                                type="monotone"
	                                                dataKey="Total Room"
	                                                stroke="#003366"
	                                                fill='#003366'
                                                />
                                                {/*<Line type="monotone" dataKey="uv" stroke="#FE9E15" strokeDasharray="3 4 5 2"/>*/}
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </Widget>
                                </Col>
                                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                                    <Widget styleName="gx-card-full">
                                        <div className="ant-row-flex gx-px-4 gx-pt-4">
                                            <h2 className="h4 gx-mb-3">Latest Five Bookings</h2>
                                        </div>
                                        <Table dataSource={dataSource1} columns={columns1} pagination={false} style={{ width: "90%",margin: "0px 0 0 20px", padding: "0px 0px 25px 0"}}/>
                                    </Widget>
                                </Col>
	                            <Col xl={6} lg={6} md={24} sm={24} xs={24}>
		                            <Widget styleName="gx-card-full">
			                            <div className="ant-row-flex gx-px-4 gx-pt-4">
				                            <h2 className="h4 gx-mb-3">Owner's Total Room's</h2>
			                            </div>

			                            <ResponsiveContainer width="100%" height={200}>
				                            <PieChart>
					                            <Pie
						                            dataKey="total_property"
						                            data={state.owner_wise_property}
						                            outerRadius={60}
						                            // fill="#003366"
                                                    fill="#D2E3F6"
                                                // D2E3F6
					                            />

					                            <Pie
						                            dataKey="total_property"
						                            data={state.owner_wise_property}
						                            innerRadius={70}
						                            outerRadius={90}
						                            // fill="#FE9E15"
						                            // fill="#1C2140"
                                                    fill="#003366"
						                            label
					                            />
					                            <Tooltip/>
				                            </PieChart>
			                            </ResponsiveContainer>
		                            </Widget>
	                            </Col>
                            </Row>

                            <Row>
                                <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                                    <Widget styleName="gx-card-full">

                                        <div className="ant-row-flex gx-px-4 gx-pt-4">
                                            <h2 className="h4 gx-mb-3">New Customer's</h2>
                                        </div>
                                        <div className="gx-customers gx-py-2">
                                            <ul className="gx-list-inline gx-customers-list gx-px-4">
                                                <Row>
                                                {
                                                    state.new_customer.map((user, index) =>
                                                        <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <li className="gx-mb-2" key={index}>
                                                            <Avatar src={user.image != '' ? Config.image_url+user.image : Config.image_url+'/upload/avatar.jpg'} style={{ border:"1px solid black", borderRadius:"50%" }}/>
                                                            <br/>
                                                            <Link to={`../crm/customeredit/${user.id}`}>{user.full_name}</Link>
                                                        </li>
                                                        </Col>
                                                    )
                                                }
                                                </Row>
                                            </ul>
                                        </div>
                                    </Widget>
                                </Col>
                                {/*<Col xl={12} lg={12} md={24} sm={24} xs={24}>*/}
                                {/*    <Widget styleName="gx-card-full">*/}
                                {/*        <div className="ant-row-flex gx-px-4 gx-pt-4">*/}
                                {/*            <h2 className="h4 gx-mb-3">Latest Five Booking Requests</h2>*/}
                                {/*        </div>*/}
                                {/*        <Table dataSource={dataSource2} columns={columns2} pagination={false} style={{ width: "95%",margin: "0px 0 0 20px", padding: "0px 0px 25px 0"}}/>*/}
                                {/*    </Widget>*/}
                                {/*</Col>*/}
                                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                    <Widget styleName="gx-card-full">
                                        <div className="ant-row-flex gx-px-4 gx-pt-4">
                                            <h2 className="h4 gx-mb-3">Properties Total Room</h2>
                                        </div>
                                        <CanvasJSChart options = {options} containerProps={containerProps}/>
                                    </Widget>
                                </Col>

                            </Row>

                        </div>
                    </div>
                </Col>

            </Row>
        </Auxiliary>
    );
};

export default AdminDashboard;
