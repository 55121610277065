import React, {useEffect, useRef, useState} from "react";
import {Form, Card, Tabs, Popover} from 'antd';
import jsonfile from './task.json'
import {CisUI} from '../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../util/config";
import axios from "../../../util/Api";
import IntlMessages from "../../../util/IntlMessages";
import {CheckCircleOutlined, FieldTimeOutlined} from "@ant-design/icons";
import {Calendar, momentLocalizer} from "react-big-calendar";
import moment from "moment";
import {Link} from "react-router-dom";
import CircularProgress from "../../../components/CircularProgress";


const { TabPane } = Tabs;
moment.locale('en-us');
const localizer = momentLocalizer(moment);


const IconStyle = {
    cursor: "pointer"
};

const TooltipContent = ({onClose, event}) => {
    return (
        <React.Fragment>
            <div>{event.title}</div>
        </React.Fragment>
    );
}

function Event(event) {
    const ref = useRef(null);
    const details = (detail) => {
        console.log(detail)
        let content = (
            <div style={{ width:"800px" }}>
                <div>
                    <p style={{margin: "10px 0 7px 0", color: "#1e2b5d", fontSize: "15px"}}>{detail.event.title}</p>
                    <p>Assigned To: <span style={{color: "#1e2b5d"}}>{detail.event.staff_name}</span></p>
                    <p>Reference ID: <span style={{color: "#1e2b5d"}}>{detail.event.reference_id}</span></p>
                    <p>Date: <span style={{color: "#1e2b5d"}}>{moment(detail.event.start).format("MMM DD, YYYY")} - {moment(detail.event.end).format("MMM DD, YYYY")}</span></p>

                    <p style={{margin: "10px 0 7px 0", color: "#1e2b5d", fontSize: "15px"}}>Property Information</p>
                    <p>Property Name: <span style={{color: "#1e2b5d"}}>{detail.event.property_name}</span></p>
                    <p>Room Name: <span style={{color: "#1e2b5d"}}>{detail.event.room_name}</span></p>

                    <p style={{margin: "10px 0 7px 0", color: "#1e2b5d", fontSize: "15px"}}>Assigned To Do List</p>
                    {
                        detail.event.not_completed > 0 &&
                        <p style={{margin: "10px 0 7px 0", color: "red", fontWeight:"bold"}}>
                            <i className="icon icon-error" style={{marginRight: "6px"}}/>
                            <span style={{ fontSize: "17px",}}>{detail.event.not_completed} items are not completed</span>
                        </p>
                    }

                    {
                        detail.event.desc.map((item, index) =>
                            <>
                                {
                                    item.asign_todo.length > 0 &&
                                    <>
                                    <p style={{color: "#1e2b5d", fontSize: "13px"}}>{item.group_name}</p>
                                    {
                                        item.asign_todo.map((data, i) =>

                                        data.status === 1 ?
                                        <p style={{color:"green", fontSize:"11px"}}><CheckCircleOutlined style={{color:"green", margin:"0 7px 0 0"}}/> {data.title}</p>
                                        :
                                        <p style={{color:"red", fontSize:"11px"}}> <FieldTimeOutlined style={{color:"red", margin:"0 7px 0 0"}}/> {data.title}</p>
                                        )
                                    }
                                    </>
                                }
                            </>
                        )
                    }

                </div>


            </div>


        );
        return [content]
    }

    return (
        <div ref={ref}>
            <Popover content={details(event)}>
                <p>{event.title}</p>
            </Popover>
        </div>
    );
}


const TaskCalendar = (props) => {
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [details, setDetails] = useState([]);
    const [taskDetail, setTaskDetail] = useState({
        reference_id: '',
        title: '',
        staff_name: '',
        start_date: '',
        end_date: ''
    });
    const [form] = Form.useForm();
    const userID = props.match.params.id;



    const getData = () => {

        setLoader(true)
        // document.getElementById("loader").style.display = "block";
        axios.get(Config.apiserver+`task/taskcalendarforweb`, CisUI().HeaderRequest)
            .then(res => {
                console.log(res.data)
                if(res.data.status === 1) {
                    setData(res.data.data)
                }
                else {
                    CisUI().Notification('warning', 'Failed')
                }
                setLoader(false)
                // document.getElementById("loader").style.display = "none";
            })
            .catch(error => {
                setLoader(false)
                console.log(error.response)
            })
    }


    useEffect(() => {
        getData()
    }, []);

    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    return (

            loader ? <CircularProgress /> :
                <Card title={<IntlMessages id={"Task Calendar View"}/>} extra={CisUI().addAction(redirectto)}>
                    <Calendar
                        components={{ event: Event}}
                        localizer={localizer}
                        {...props}
                        events={data}
                        step={60}
                        popup
                        // eventPropGetter={(event, start, end, isSelected) => ({
                        //     event,
                        //     start,
                        //     end,
                        //     isSelected,
                        //     style: { backgroundColor: "green" }
                        // })}
                    />
                </Card>



    );
};

export default TaskCalendar;
