import React from "react";
import {Form, Card, Select} from 'antd';
import jsonfile from './booking_request.json'
import {CisUI} from '../../../../util/CISUI'
import {useHistory} from "react-router-dom";
import IntlMessages from "../../../../util/IntlMessages";

const {Option} = Select;

const BookingReqDeclined = (props) => {
	const [form] = Form.useForm();
	const history = useHistory();


	let Title = jsonfile.declinedtitle;
	let endpoint = jsonfile.urls.add;
	let redirectto = "../"+jsonfile.urls.list;

	// console.log(multiRoom)

	// const onFinish = (values) => {
	// 	console.log(data)
	// 	// document.getElementById("loader").style.display = "block";
	// 	axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
	// 		.then(res => {
	// 			// console.log(res)
	// 			if(res.data.status === 1) {
	// 				CisUI().Notification('success', res.data.msg);
	// 				history.push(redirectto);
	// 			}
	// 			else {
	// 				CisUI().Notification('error', res.data.msg);
	// 			}
	// 			// document.getElementById("loader").style.display = "none";
	// 		})
	// 		.catch(errors => {
	// 			console.log(errors);
	// 		});
	// };

	return (
		<Card title={<IntlMessages id={Title}/>} extra={CisUI().addAction(redirectto)}>

		</Card>
	);
};

export default BookingReqDeclined;
