import React, {useState} from "react";
import {Form, Card, notification, Row, Col, Select, Button} from 'antd';
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "axios";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";
import IntlMessages from "../../../../util/IntlMessages";

const ImportICAL = (props) => {
	
	const [loading, setLoading] = useState([]);
	
	let booking_source = SelectData('settings/sources');
	let rooms = RemoteJson('property/roominfos');
	
	let Title = "Import ICAL";
	
	const [form] = Form.useForm();
	const history = useHistory();
	
	const [file, setFile] = useState([]);
	let redirect = '/channelmanager/calendar';
	
	const changeImage = (e) => {
		let file = e.target.files[0];
		console.log(file)
		if(file.type === 'text/calendar') {
			let reader = new FileReader();
			
			reader.onload = (e) => {
				setFile({
					file: e.target.result,
				});
			};
			reader.readAsDataURL(file);
		}
		else {
			setFile([])
			CisUI().Notification('error', 'Please upload .ics file.')
		}
	};
	
	const onFinish = (values) => {
		let data = {
			...values,
			file:file['file']
		}
		
		console.log(data)
		document.getElementById("loader").style.display = "block";
		axios.post(Config.apiserver+'fileupload', data)
			.then(res =>{
				// console.log(res)
				if(res.data.status === 1) {
					CisUI().Notification('success', res.data.msg)
					history.push(redirect);
				}
				else {
					CisUI().Notification('error', res.data.msg)
				}
				document.getElementById("loader").style.display = "none";
			})
			.catch(error => console.log(error.response))
	}
	
	return (
		<Card title={Title} extra={CisUI().addAction(redirect)}>
			{CisUI().showLoading}
			<Form
				form={form}
				{...CisUI().formItemLayout}
				name="input"
				className="ant-advanced-search-form"
				onFinish={onFinish}
				initialValues={{remember: true}}
			>
				{/*{InputFields(jsonfile.input,userData,isEdit,props)}*/}
				{/*{InputFields(jsonfile.input,userData,isEdit,props)}*/}
				<Row>
					<Col lg={8} md={8} sm={12} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="source"
								label="Booking Source"
								rules={[{
									required: true,
									message: "Booking Source is required"
								}]}
							>
								<Select
									showSearch
									placeholder="Select an option"
									optionFilterProp="children"
									filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase())}
									// onChange={handleChange}
								>
									{
										booking_source.map((item, index) =>
											<option value={item.id}>{item.name}</option>
										)
									}
								</Select>
							</Form.Item>
						</div>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={24}>
						<div className="gx-form-row0">
							<Form.Item
								name="room_id"
								label="Room"
								rules={[{
									required: true,
									message: "Room is required"
								}]}
							>
								<Select
									showSearch
									placeholder="Select an option"
									optionFilterProp="children"
									filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase())}
									// onChange={handleChange}
								>
									{
										rooms.map((item, index) =>
											<option value={item.id}>{item.name}</option>
										)
									}
								</Select>
							</Form.Item>
						</div>
					</Col>
					
					<Col lg={8} md={8} sm={12} xs={14}>
						<div className="gx-form-row0">
							<Form.Item
								name="file"
								label="File (only .ics file)"
							>
								<input type="file"  onChange={changeImage} multiple/>
							</Form.Item>
						</div>
					</Col>
					
					<Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
						<br/>
						<Button type="primary" className="searchbutton2" htmlType="submit">
							<IntlMessages id={"Upload Data"} />
						</Button>
					</Col>
					
				</Row>
			</Form>
		</Card>
	);
};

export default ImportICAL;