import React from "react";
import {Route, Switch} from "react-router-dom";

import CustomerTypeList from "../../containers/yotta/crm/customer_type/CustomerTypeList";
import NewCustomerType from "../../containers/yotta/crm/customer_type/NewCustomerType";
import CustomerList from "../../containers/yotta/crm/customers/CustomerList";
import AddCustomer from "../../containers/yotta/crm/customers/AddCustomer";


const CRM = ({match}) => (
    <Switch>
        <Route path={`${match.url}/customertype`} component={CustomerTypeList}/>
        <Route path={`${match.url}/newcustomertype`} component={NewCustomerType}/>
        <Route path={`${match.url}/customertypeedit/:id`} component={NewCustomerType}/>
        <Route path={`${match.url}/customerlist`} component={CustomerList}/>
        <Route path={`${match.url}/addcustomer`} component={AddCustomer}/>
        <Route path={`${match.url}/customeredit/:id`} component={AddCustomer}/>

    </Switch>
);

export default CRM;
