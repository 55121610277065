import React, {useState} from "react";
import {Form, Card, Col, Row, Button} from 'antd';
import jsonfile from './property_info.json'
import {CisUI} from '../../../../util/CISUI'
import InputFields from '../../../../util/InputField'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import IntlMessages from "../../../../util/IntlMessages";
import InputFieldV2 from "../../../../util/InputFieldV2";
import CKEditor from "react-ckeditor-component";

const NewPropertyInfo = (props) => {
    const [ck, setCK] = useState("");
    const [image, setImage] = useState([]);
    const [src, setSource] = useState([]);
    const [error, setError] = useState();
    // const [state, setState] = useState({
    //     image: [],
    //     src: [],
    //     error: ""
    // });
    // const [editImage, setEditImage] = useState([]);

    let marked = window.marked;

    const changeImage = (e) => {
        let file = e.target.files;

        let img = [];
        let source = [];
        if(file.length > 5){
            setError("Not more than 5 images");
        }
        else {
            for (let i = 0; i < file.length; i++) {
                source.push(URL.createObjectURL(e.target.files[i]))
                // console.log(item)
                let reader = new FileReader();
                reader.onload = (e) => {
                    img.push(e.target.result);
                };
                reader.readAsDataURL(file[i]);
            }

            setSource(source)
            setImage(img);
        }
    }

    const removeImage = (e) => {
        console.log(e)
        setSource(
            src.filter((item, index) => item !== e)
        );
    }

    const deleteImg = (id) => {
        // e.preventDefault();
        console.log(id)
        // axios.post(Config.apiserver + endpoint + id, CisUI().HeaderRequest)
        //     .then(res => {})
    }

    const handleChange = (evt) => {
        // console.log(evt.editor);
        var newContent = evt.editor.getData();
        setCK(newContent)
    }



    const userID = props.match.params.id;
    let userData = [];
    let editImages = [];
    let details = "";
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;
    let rawdata = "";
    if(isEdit === 1 ) {
        let getData = RemoteJson(jsonfile.urls.view+"/"+userID);
        userData = getData.data;
        details = userData?.details;
        rawdata = '<div dangerouslySetInnerHTML={{ __html: marked.parse('+details+') }} />';
        // setEditImage(getData.images);
        editImages = getData.images;

        // console.log(editImages);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }


    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {
        // console.log(values);
        let data = {
            ...values,
            images: image,
            details:ck
        }

        // console.log(data);
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg);
                    history.push(redirectto);
                }
                else {
                    CisUI().Notification('error', res.data.msg);
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    return (
        <Card title={<IntlMessages id={Title}/>} extra={
                isEdit === 1
                    ? CisUI().addAction(redirectto)
                    : CisUI().addAction(redirectto)
            }>
            {CisUI().showLoading}
            <Form
                // form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                {/*{InputFields(jsonfile.input,userData,isEdit,props)}*/}
                {InputFieldV2(jsonfile.input,userData,isEdit,props, '', redirectto)}

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name="details"
                                label="Details"

                            >
                                <CKEditor
                                    activeClass="p10"
                                    content={ck}
                                    events={{
                                        "change": handleChange,
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </Col>

                    <Col lg={24} md={24} sm={24} xs={24}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name="image"
                                label="Image (maximum 5 images)"
                            >
                                <input type="file" onChange={changeImage} multiple/>
                                {/*<button className="btn btn-primary btn-sm">x</button>*/}
                                {
                                    error !== '' && <p className="text-danger font-weight-bolder">{error}</p>
                                }
                                <div className="row" style={{ marginTop: "10px" }}>
                                    {
                                        editImages?.map((item, index) =>
                                            <div className="col-md-2" key={index}>
                                                <img src={Config.property_info_image + item.image} alt="Image" style={{ width: "150px", height: "120px" }}/>
                                                <button type="button" className="btn btn-secondary btn-sm remove-btn" onClick={() =>deleteImg(item.id)}>x</button>
                                            </div>
                                        )
                                    }
                                </div>

                                <div className="row" style={{ marginTop:"10px" }}>
                                    {
                                        src !== '' && src.map((item, index) =>
                                            <div className="col-md-2" key={index}>
                                                <img src={item} alt="Hello" style={{ width: "150px", height: "120px"}}/>
                                                <button className="btn btn-secondary btn-sm remove-btn" onClick={() => removeImage(item)}>x</button>
                                            </div>
                                        )
                                    }
                                </div>

                            </Form.Item>
                        </div>
                    </Col>

                </Row>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                        <Button type="primary" htmlType="submit">
                            <IntlMessages id={"Submit"} />
                        </Button>
                        {/*<Button type="info" onClick={() => history.goBack()} htmlType="back"><IntlMessages id={"Cancel"} /></Button>*/}
                        {/*<Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Cancel"} /></Button>*/}
                        <Button type="info" onClick={() => history.push(redirectto)}><IntlMessages id={"Back"} /></Button>
                    </Col>
                </Row>

            </Form>
        </Card>
    );
};

export default NewPropertyInfo;
