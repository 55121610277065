import React from "react";
import { Route, Switch } from "react-router-dom";
import OwnerTypeList from "../../containers/yotta/owner/owner_type/OwnerTypeList";
import NewOwnerType from "../../containers/yotta/owner/owner_type/NewOwnerType";
import OwnerList from "../../containers/yotta/owner/owner_info/OwnerList";
import NewOwner from "../../containers/yotta/owner/owner_info/NewOwner";

const Owner = ({match}) => (
	<Switch>
		<Route path={`${match.url}/ownertype`} component={OwnerTypeList} />
		<Route path={`${match.url}/newownertype`} component={NewOwnerType}/>
		<Route path={`${match.url}/ownertypeedit/:id`} component={NewOwnerType}/>
		<Route path={`${match.url}/ownerlists`} component={OwnerList} />
		<Route path={`${match.url}/newownerinfo`} component={NewOwner}/>
		<Route path={`${match.url}/editownerinfo/:id`} component={NewOwner}/>
	</Switch>

);

export default Owner;