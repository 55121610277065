/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import IntlMessages from "../../../../util/IntlMessages";
import CustomScrollbars from "../../../../util/CustomScrollbars";
import { Button, Checkbox, Drawer, Dropdown, message } from "antd";
import Auxiliary from "../../../../util/Auxiliary";
import ComposeMail from "./Compose";
// import options from "./data/options";
import CircularProgress from "../../../../components/CircularProgress";
import MailList from "./MailList";
import MailDetail from "./MailDetail";
import folders from "./data/folders";
import axiosinstance from "../../../../util/Api";
import {CisUI} from "../../../../util/CISUI";

const Mailv2 = () => {
    const [state, setState] = React.useState({
        searchMail: "",
        noContentFoundMessage: "No Mail found in selected folder",
        alertMessage: "",
        showMessage: false,
        drawerState: false,
        optionName: "None",
        anchorEl: null,
        allMail: [],
        loader: true,
        currentMail: null,
        user: {
            name: "",
            email: "",
            avatar: "",
        },
        selectedMails: 0,
        selectedFolder: 0,
        composeMail: false,
        folderMails: [],
        next: null,
        prev: null,
        paginationStatus: null,
        checkedMails: [],
        total: 0,
    });
    console.log("🚀 ~ file: Mailv2.js:42 ~ Mailv2 ~ state", state);
    const MailSideBar = () => {
        return (
            <div className="gx-module-side">
                <div className="gx-module-side-header">
                    <div className="gx-module-logo">
                        <i className="icon icon-chat gx-mr-4" />
                        <IntlMessages id="sidebar.mail.mailbox" />
                    </div>
                </div>

                <div className="gx-module-side-content">
                    <CustomScrollbars className="gx-module-side-scroll">
                        <div className="gx-module-add-task">
                            <Button
                                type="primary"
                                className="gx-btn-block"
                                onClick={() => {
                                    setState({
                                        ...state,
                                        composeMail: true,
                                    });
                                    // onFolderHandle(1);
                                }}
                            >
                                <i className="icon icon-edit gx-mr-2" />
                                <IntlMessages id="sidebar.mail.compose" />
                            </Button>
                        </div>

                        <ul className="gx-module-nav">{getNavFolders()}</ul>
                    </CustomScrollbars>
                </div>
            </div>
        );
    };

    const handleComposeMail = () => {
        setState({ ...state, composeMail: false });
    };

    const onMailSend = () => {
        // this.setState({ composeMail: false });
    };

    // const onOptionMenuItemSelect = (eventKey) => {};

    // const getAllMail = () => {
    //     const mailData = state.folderMails?.map((item) => {
    //         return {
    //             ...item,
    //             selected: true,
    //         };
    //     });

    //     setState({
    //         //
    //         ...state,
    //         selectedMails: mailData.length,
    //         // allMail: mailData,
    //         optionName: "All",
    //         folderMails: mailData,
    //     });
    // };

    // const getUnselectedAllMail = () => {
    //     const mailData = state.folderMails?.map((item) => {
    //         return {
    //             ...item,
    //             selected: false,
    //         };
    //     });
    //     setState({
    //         ...state,
    //         selectedMails: 0,
    //         checkedMails: [],
    //         // allMail: mails,
    //         optionName: "None",
    //         folderMails: mailData,
    //     });
    // };

    // const onAllMailSelect = () => {
    //     if (!state.selectedMails) {
    //         getAllMail();
    //     } else {
    //         getUnselectedAllMail();
    //     }
    // };

    //    const  onMailChecked =(data)=>{
    //         data.selected = !data.selected;
    //         let selectedMail = 0;
    //         const mails = this.state.folderMails.map((mail) => {
    //             if (mail.selected) {
    //                 selectedMail++;
    //             }
    //             if (mail.id === data.id) {
    //                 if (mail.selected) {
    //                     selectedMail++;
    //                 }
    //                 return data;
    //             } else {
    //                 return mail;
    //             }
    //         });
    //         this.setState({
    //             selectedMails: selectedMail,
    //             folderMails: mails,
    //         });
    //     }

    const nextHandler = () => {
        setState({
            ...state,
            loader: true,
        });
        axiosinstance.get(state.next).then((res) => {
            setState({
                ...state,
                folderMails: res.data.data.data,
                next: res.data.data.next_page_url,
                prev: res.data.data.prev_page_url,
                total: res.data.data.total,
                loader: false,
            });
        });
    };

    const prevHandler = () => {
        setState({
            ...state,
            loader: true,
        });
        axiosinstance.get(state.prev).then((res) => {
            setState({
                ...state,
                folderMails: res.data.data.data,
                next: res.data.data.next_page_url,
                prev: res.data.data.prev_page_url,
                total: res.data.data.total,
                loader: false,
            });
        });
    };

    const handleRequestClose = () => {
        setState({
            ...state,
            composeMail: false,
            showMessage: false,
        });
    };

    const onStartSelect = () => {};

    const onMailChecked = (data) => {
        data.selected = !data.selected;
        let selectedMail = 0;
        const mails = state.folderMails.map((mail) => {
            if (mail.selected) {
                selectedMail++;
            }
            if (mail.id === data.id) {
                if (mail.selected) {
                    selectedMail++;
                }
                return data;
            } else {
                return mail;
            }
        });
        // console.log("🚀 ~ file: Mailv2.js:212 ~ mails ~ mails", selectedMail);
        setState({
            ...state,
            selectedMails: selectedMail,
            folderMails: mails,
            checkedMails: mails.filter((mail) => mail.selected),
        });
    };

    const onImportantSelect = () => {};

    const getTrashUrl = () => {
        switch (state.selectedFolder) {
            case 4:
                return "/message/deletetrashitem";
            default:
                return "/message/deletemail";
        }
    };

    const onDeleteMail = () => {
        let checkedMails = [];

        const url = getTrashUrl();

        state.checkedMails.forEach((mail) => {
            checkedMails.push(mail.id);
        });
        axiosinstance
            .delete(`${url}?is_send=${state.selectedFolder === 0 ? 1 : 2}`, {
                data: {
                    ids: checkedMails,
                },
            })
            .then((res) => {
                if (res.data.status === 1) {
                    const mails = state.folderMails.filter(
                        (mail) => !checkedMails.includes(mail.id)
                    );
                    setState({
                        ...state,
                        alertMessage: "Mail Deleted Successfully",
                        showMessage: true,
                        folderMails: mails,
                        selectedMails: 0,
                        checkedMails: [],
                    });
                }
                // console.log("checkedMails", checkedMails);
                // const mails = state.folderMails.filter(
                //     (mail) => !checkedMails.includes(mail.id)
                // );
                // console.log("🚀 ~ file: Mailv2.js:259 ~ .then ~ mails", mails);
                // setState({
                //     ...state,
                //     folderMails: mails,
                //     selectedMails: 0,
                //     checkedMails: [],
                // });

                // console.log("res", res);
            });
    };

    const getMailActions = () => {
        return (
            <div className="gx-flex-row gx-align-items-center">
                {/* <Dropdown
                    menu={folderMenu()}
                    placement="bottomRight"
                    trigger={["click"]}
                >
                    <i className="icon icon-folder gx-icon-btn" />
                </Dropdown>

                <Dropdown
                    menu={labelMenu()}
                    placement="bottomRight"
                    trigger={["click"]}
                >
                    <i className="icon icon-tag gx-icon-btn" />
                </Dropdown> */}

                <span onClick={onDeleteMail}>
                    <i className="icon icon-trash gx-icon-btn" />
                </span>
            </div>
        );
    };

    const displayMail = (currentMail, folderMails, noContentFoundMessage) => {
        return (
            <div className="gx-module-box-column">
                {currentMail === null ? (
                    folderMails.length === 0 ? (
                        <div className="gx-no-content-found gx-text-light gx-d-flex gx-align-items-center gx-justify-content-center">
                            {noContentFoundMessage}
                        </div>
                    ) : (
                        <MailList
                            mails={folderMails}
                            onStartSelect={onStartSelect}
                            onMailSelect={onMailSelect}
                            onMailChecked={onMailChecked}
                            selectedFolder={state.selectedFolder}
                        />
                    )
                ) : (
                    <MailDetail
                        mail={currentMail}
                        onStartSelect={onStartSelect}
                        onImportantSelect={onImportantSelect}
                    />
                )}
            </div>
        );
    };

    const onToggleDrawer = () => {
        // setState({ ...state, drawerState: !state.drawerState });
    };

    const getDetailsUrl = (id) => {
        switch (state.selectedFolder) {
            case 0:
                return `message/mail/${id}`;
            case 1:
                return `message/showmail/${id}`;
            case 2:
                return `message/draftmaill/${id}`;
            case 3:
                return `message/trashmaill/${id}`;
            case 4:
                return `message/showmail/${id}`;
            default:
                return `message/mail/${id}`;
        }
    };

    const onMailSelect = (folder) => {
        const url = getDetailsUrl(folder?.id);
        setState({
            ...state,
            loader: true,
        });

        axiosinstance.get(url).then((res) => {
            setState({
                ...state,
                currentMail: res.data.data,
                loader: false,
            });
        });
    };

    const getIndexMail = () => {
        axiosinstance.get("message/mails", CisUI().HeaderRequest).then((res) => {
            if (res.data.status === 1) {
                setState({
                    ...state,
                    folderMails: res.data.data.data,
                    loader: false,
                    next: res.data.data.next_page_url,
                    prev: res.data.data.prev_page_url,
                    total: res.data.data.total,
                });
            }
        });
    };

    const getMailUrl = (folderID) => {
        switch (folderID) {
            case 0:
                return "message/mails";
            case 1:
                return "message/sendmaillist";
            case 2:
                return "message/draftmaillist";
            case 3:
                return "message/trashlist";
            case 4:
                return "message/trashlist";
            default:
                return "message/mails";
        }
    };

    const onFolderHandle = (folder) => {
        console.log(
            "🚀 ~ file: Mailv2.js:402 ~ onFolderHandle ~ folder",
            folder
        );
        const url = getMailUrl(folder.id);
        axiosinstance
            .get(url, CisUI().HeaderRequest)
            .then((res) => {
                if (res.data.status === 1) {
                    setState({
                        ...state,
                        currentMail: null,
                        selectedFolder: folder.id,
                        folderMails: res.data.data.data,
                        selectedMails: 0,
                        loader: false,
                        next: res.data.data.next_page_url,
                        prev: res.data.data.prev_page_url,
                    });
                }
            })
            .catch((err) => {
                message.error("Server Error!!!", err);
            });
    };

    const getNavFolders = () => {
        return folders.map((folder, index) => (
            <li key={index} onClick={() => onFolderHandle(folder)}>
                <span
                    className={`${
                        state.selectedFolder === folder.id
                            ? "active gx-link"
                            : "gx-link"
                    }`}
                >
                    <i className={`icon icon-${folder.icon}`} />
                    <span>
                        {folder.title === "Inbox"
                            ? folder.title + ` (${state.total})`
                            : folder.title}
                    </span>
                </span>
            </li>
        ));
    };

    useEffect(() => {
        getIndexMail();
    }, []);

    return (
        <>
            <div className="gx-main-content">
                <div className="gx-app-module">
                    <div className="gx-d-block gx-d-lg-none">
                        <Drawer
                            placement="left"
                            closable={false}
                            open={state.drawerState}
                            onClose={onToggleDrawer}
                        >
                            {MailSideBar()}
                        </Drawer>
                    </div>
                    <div className="gx-module-sidenav gx-d-none gx-d-lg-flex">
                        {MailSideBar()}
                    </div>

                    <div className="gx-module-box">
                        <div className="gx-module-box-content">
                            <div
                                className="gx-module-box-topbar"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "0px 38px",
                                    gap: "10px",
                                }}
                            >
                                {state.currentMail === null ? (
                                    <div className="gx-flex-row gx-align-items-center">
                                        {state.folderMails.length > 0 ? (
                                            <Auxiliary>
                                                {/* <Checkbox
                                                    color="primary"
                                                    // className="gx-icon-btn"
                                                    checked={
                                                        state.selectedMails > 0
                                                    }
                                                    onChange={onAllMailSelect}
                                                    value="SelectMail"
                                                    style={{ gap: "15px" }}
                                                >
                                                    Select All
                                                </Checkbox> */}
                                                {state.selectedMails > 0 &&
                                                    getMailActions()}

                                                {/* <Dropdown
                                                    menu={
                                                        <Menu
                                                            id="option-menu"
                                                            onClick={
                                                                onOptionMenuItemSelect
                                                            }
                                                        >
                                                            {options?.map(
                                                                (option) => (
                                                                    <Menu.Item
                                                                        key={
                                                                            option.title
                                                                        }
                                                                    >
                                                                        {
                                                                            option.title
                                                                        }
                                                                    </Menu.Item>
                                                                )
                                                            )}
                                                        </Menu>
                                                    }
                                                    placement="bottomRight"
                                                    trigger={["click"]}
                                                >
                                                    <div>
                                                        <span className="gx-px-2">
                                                            {state.optionName}
                                                        </span>
                                                        <i className="icon icon-charvlet-down" />
                                                    </div>
                                                </Dropdown> */}
                                                {/* <p>Select All</p> */}
                                            </Auxiliary>
                                        ) : null}
                                    </div>
                                ) : (
                                    <i
                                        className="icon icon-arrow-left gx-icon-btn"
                                        onClick={() => {
                                            setState({
                                                ...state,
                                                currentMail: null,
                                            });
                                        }}
                                    />
                                )}

                                <div
                                    style={{
                                        display: "flex",
                                        columnGap: "15px",
                                    }}
                                >
                                    <span
                                        style={{
                                            cursor: "pointer",
                                            color:
                                                state.prev === null
                                                    ? "#E5E5E5"
                                                    : "gray",
                                        }}
                                        onClick={
                                            state.prev !== null && prevHandler
                                        }
                                    >
                                        &laquo; Previous
                                    </span>
                                    <span
                                        style={{
                                            cursor: "pointer",
                                            color:
                                                state.next === null
                                                    ? "#E5E5E5"
                                                    : "gray",
                                        }}
                                        onClick={
                                            state.next !== null && nextHandler
                                        }
                                    >
                                        Next &raquo;
                                    </span>
                                </div>
                            </div>

                            {state.loader ? (
                                <div className="gx-loader-view">
                                    <CircularProgress />
                                </div>
                            ) : (
                                displayMail(
                                    state.currentMail,
                                    state.folderMails,
                                    state.noContentFoundMessage
                                )
                            )}

                            <ComposeMail
                                open={state.composeMail}
                                user={state.user}
                                onClose={handleComposeMail}
                                onMailSend={onMailSend}
                            />
                        </div>
                    </div>
                </div>
                {state.showMessage &&
                    message.info(
                        <span id="message-id">{state.alertMessage}</span>,
                        3,
                        handleRequestClose
                    )}
            </div>
        </>
    );
};

export default Mailv2;
