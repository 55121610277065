import React from "react";
import {Route, Switch} from "react-router-dom";

import PolicyList from "../../containers/yotta/settings/policy/PolicyList";
import NewPolicy from "../../containers/yotta/settings/policy/NewPolicy";
import AmenityList from "../../containers/yotta/settings/amenity/AmenityList";
import NewAmenity from "../../containers/yotta/settings/amenity/NewAmenity";
import AddNewSource from "../../containers/yotta/settings/booking_source/AddNewSource";
import SourceList from "../../containers/yotta/settings/booking_source/SourceList";

const CRM = ({match}) => (
	<Switch>
		<Route path={`${match.url}/policies`} component={PolicyList}/>
		<Route path={`${match.url}/newpolicy`} component={NewPolicy}/>
		<Route path={`${match.url}/editpolicy/:id`} component={NewPolicy}/>
		<Route path={`${match.url}/amenities`} component={AmenityList}/>
		<Route path={`${match.url}/newamenity`} component={NewAmenity}/>
		<Route path={`${match.url}/editamenity/:id`} component={NewAmenity}/>
		<Route path={`${match.url}/sources`} component={SourceList}/>
		<Route path={`${match.url}/newsource`} component={AddNewSource}/>
		<Route path={`${match.url}/editsource/:id`} component={AddNewSource}/>
	</Switch>
);

export default CRM;
